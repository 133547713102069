import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export default function FieldCellDetailWithoutPrice({ onSale }) {
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center', gap: '1rem', mt: '1rem' }}>
      <Typography
        variant="modalId"
        sx={{
          height: '21px',
          color: onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.2)',
        }}>
        {onSale ? 'On sale' : 'Not on sale'}
      </Typography>
      <Box
        sx={{
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          background: onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.1)',
          boxShadow: onSale ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)' : 'none',
        }}
      />
    </Grid>
  );
}
