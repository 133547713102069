import { useState } from 'react';
import { Box, Grid, Typography, LinearProgress } from '@mui/material';
import EvolutionClock from '@components/EvolutionClock';
import DetailsButton from '@components/Buttons/DetailsButton';
import { useBrowserActiveTab, useTicker } from '@hooks';

const DashboardCellCard = ({ cell, detailsButtonHandler }) => {
  const [readyForEvolution, setReadyForEvolution] = useState(false);
  const browserTabActive = useBrowserActiveTab(setReadyForEvolution);
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();

  return (
    <Box
      key={browserTabActive}
      sx={{
        background: '#17181C',
        border: `1px solid ${readyForEvolution ? '#09EBF6' : '#202228'}`,
        boxShadow: readyForEvolution ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)' : 'none',
        borderRadius: '14px',
        p: '19px',
      }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ background: '#000', borderRadius: '14px', overflow: 'hidden' }}>
        <Box sx={{ width: '219px', height: '129px' }}>
          <img
            src={`${process.env.REACT_APP_RESOURCE}/metacell/${Number(cell?.stage) + 1}.png`}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            onError={(e) => {
              e.target.src = `${process.env.REACT_APP_RESOURCE}/metacell/1.png`;
            }}
            alt="cell"
          />
        </Box>
        <Grid container justifyContent="center" sx={{ width: 'auto', flexGrow: 1 }}>
          <EvolutionClock
            id={cell.id}
            timestamp={cell.evolutionTimestamp}
            setReadyForEvolution={setReadyForEvolution}
          />
        </Grid>
      </Grid>
      <Grid container mt="20px" justifyContent="space-between" sx={{ overflow: 'hidden' }}>
        <Typography
          ref={nameRef}
          variant="cardName"
          sx={{
            height: '19px',
            maxWidth: '70%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'linear-gradient(to right, transparent 190px, #17181C)',
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box
            sx={{
              '&:hover': isNameOverflown && {
                transform: `translateX(-${nameOffset}px)`,
                transition: `transform ${nameTransitionTime}s`,
              },
            }}>
            {cell.name}
          </Box>
        </Typography>
        <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${cell.id}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" gap="28px" mt="15px">
        <Box sx={{ flexGrow: 1, minWidth: '209px' }}>
          <Grid container gap="4px" sx={{ width: 'auto' }}>
            <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
              Level:
            </Typography>
            <Typography variant="cardAttribute">{Number(cell.stage)}</Typography>
          </Grid>
          <LinearProgress
            variant="determinate"
            value={cell.stage}
            sx={{
              borderRadius: '7px',
              backgroundColor: '#242424',
              mt: '4px',
              '.MuiLinearProgress-bar': {
                background:
                  'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
              },
            }}
          />
        </Box>
        <DetailsButton
          detailsButtonHandler={() => {
            detailsButtonHandler(cell);
          }}
        />
      </Grid>
    </Box>
  );
};

export default DashboardCellCard;
