import { styled } from '@mui/material/styles';

export const LightButton = styled('button')`
  position: relative;
  padding: 20px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  text-decoration: none;
  transition: 0.5s;
  color: #00ccff;
  font-weight: 700;
  font-size: 15px;
  overflow: hidden;
  border: 2px solid #00ccff;
  cursor: pointer;
  animation: appearAnimation 1s ease-in-out 0s 1 normal none;

  &:hover {
    color: #e4dccf;
    border: 2px solid #00ccff;
    background: #00ccff;
    box-shadow: 0 0 10px #00ccff, 0 0 30px #00ccff, 0 0 60px #00ccff, 0 0 100px #00ccff;
  }

  @keyframes appearAnimation {
    0.0% {
      opacity: 0;
    }
    ,
    80.0% {
      opacity: 0;
    }
    ,
    90.0% {
      opacity: 1;
    }
  }
`;
