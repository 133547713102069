export const specsAvailableValues = {
  scientist: [
    {
      title: 'Astro Physics',
      background: '/icons/ScientistBg/astro-physics.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/astro-icon.svg',
      value: '0',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/22_ASTROPHYSICS.mp4`,
    },
    {
      title: 'Atomic Chemistry',
      background: '/icons/ScientistBg/atomic.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/atomic-icon.svg',
      value: '1',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/1_ATOMIC CHEMISTRY.mp4`,
    },
    {
      title: 'Space Time',
      background: '/icons/ScientistBg/space-time.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/space-icon.svg',
      value: '2',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/19_SPACE TIME.mp4`,
    },
    {
      title: 'Hive Mind',
      background: '/icons/ScientistBg/hive-mind.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/hive-mind-icon.svg',
      value: '3',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/20_HIVEMIND.mp4`,
    },
    {
      title: 'Dark Matter',
      background: '/icons/ScientistBg/astro-physics.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/dark-matter.svg',
      value: '4',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/21_DARK MATTER.mp4`,
    },
    {
      title: 'Baudrillard Theory',
      background: '/icons/ScientistBg/astro-physics.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/theory-icon.svg',
      value: '5',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/17_MATRIX.mp4`,
    },
  ],
  personality: [
    {
      title: 'Paranoid',
      background: '/icons/ScientistBg/paranoid.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/astro-icon.svg',
      value: '0',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/PPMP4.mp4`,
    },
    {
      title: 'Schizoid',
      background: '/icons/ScientistBg/schizoid.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/atomic-icon.svg',
      value: '1',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/28_SCHIZOID.mp4`,
    },
    {
      title: 'Avoidant',
      background: '/icons/ScientistBg/avoidant.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/space-icon.svg',
      value: '2',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/30_AVOIDANT.mp4`,
    },
    {
      title: 'OCD',
      background: '/icons/ScientistBg/ocd.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/biological-icon.svg',
      value: '3',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/27_OBSESSIVE.mp4`,
    },
    {
      title: 'Narcissistic',
      background: '/icons/ScientistBg/narcissistic.svg',
      isBlur: false,
      icon: '/icons/ScientistMint/hive-mind-icon.svg',
      value: '4',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/23_NARCISSISTIC.mp4`,
    },
  ],
  vices: [
    {
      title: 'Drunk',
      isBlur: false,
      icon: '/icons/ScientistMint/drunk-icon.svg',
      value: '0',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/14_DRUNK.mp4`,
    },
    {
      title: 'Cybernetic Augmentation Addiction',
      isBlur: false,
      icon: '/icons/ScientistMint/cyber-icon.svg',
      value: '1',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/24_CYBER_AUGMENTATION.mp4`,
    },
    {
      title: 'Interplanetary Space Fumes',
      isBlur: false,
      icon: '/icons/ScientistMint/space-icon.svg',
      value: '2',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/29_SPACE_FUMES.mp4`,
    },
    {
      title: 'Toad Licker',
      isBlur: false,
      icon: '/icons/ScientistMint/elaidic-icon.svg',
      value: '3',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/8_TOAD_LICKER.mp4`,
    },
    {
      title: 'Spinal Fluid Insertion',
      isBlur: false,
      icon: '/icons/ScientistMint/astro-icon.svg',
      value: '4',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/16_SPINAL FLUID INSERTION.mp4`,
    },
    {
      title: 'Electrical Surge Treatment',
      isBlur: false,
      icon: '/icons/ScientistMint/biological-icon.svg',
      value: '5',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/15_ELECTRICAL SURGE TREATMENT.mp4`,
    },
    {
      title: 'Masochism',
      isBlur: false,
      icon: '/icons/ScientistMint/masochism-icon.svg',
      value: '6',
      videoUrl: `${process.env.REACT_APP_RESOURCE}/videos/31_MASOCHIST.mp4`,
    },
  ],
  psychological: [
    {
      title: 'Obedience To Authority',
      max: 5,
      name: 'obedienceToAuthor',
      slideItems: ['Teddy Bear', 'Captain America', 'Rogue', 'Anarchist', 'Antichrist'],
      videoUrl: [
        `${process.env.REACT_APP_RESOURCE}/videos/7_TEDDY.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/6_SALUTE.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/5_ROGUE.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/2_ANARCHY.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/3_ANTICHRIST.mp4`,
      ],
    },
    {
      title: 'Obedience To Conventions',
      max: 3,
      name: 'obedienceToConventions',
      slideItems: ['Simp', 'Degen', 'Gigachad'],
      videoUrl: [
        `${process.env.REACT_APP_RESOURCE}/videos/4_MASURBATE.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/10_DEGEN.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/11_GIGACHAD.mp4`,
      ],
    },
    {
      title: 'Tendency Towards Violence',
      max: 6,
      name: 'violenceTendency',
      slideItems: ['Pussy', '1000 kicks', 'G Shit', 'Pssssycho', 'Berserk', 'DNFWM'],
      videoUrl: [
        `${process.env.REACT_APP_RESOURCE}/videos/26_PUSSY.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/9_10,000_KICKS.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/25_5_G_SHIT.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/18_PSYCHO.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/12_BERSERK.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/13_DNFWM.mp4`,
      ],
    },
  ],
  magnitude: [
    {
      max: 7,
      name: 'fracture',
      slideItems: ['Diznee', 'Chill', 'Hmmm', 'Episodic', 'Chronic', 'Holy Shhhht', 'Break Em'],
      videoUrl: [
        `${process.env.REACT_APP_RESOURCE}/videos/Diznee.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/Chill.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/Hmm.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/Episodic.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/Chronic.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/HolyShttt.mp4`,
        `${process.env.REACT_APP_RESOURCE}/videos/Break_em.mp4`,
      ],
    },
  ],
};

export const socialContacts = [
  { icon: 'twitter-icon.svg', link: 'https://twitter.com/MADmetaverse' },
  { icon: 'discord-icon.svg', link: 'https://discord.com/invite/Ek85M2zBmq' },
  { icon: 'linked-in-icon.svg', link: 'https://www.linkedin.com/Madmetaverse?_l=en_US' },
  // { icon: 'telegram-icon.svg', link: 'https://telegram.org/Madmetaverse' },
  // { icon: 'instagram-icon.svg', link: 'https://www.instagram.com/Madmetaverse/' },
  { icon: 'youtube-icon.svg', link: 'https://www.youtube.com/Madmetaverse' },
];
/*
1. Premint: https://www.premint.xyz/mad/
2. Discord: https://discord.com/invite/Ek85M2zBmq
3. Twitter: https://twitter.com/MADmetaverse
4. Facebook: https://www.facebook.com/madmetaverse/
5. Linkedin: https://www.linkedin.com/Madmetaverse?_l=en_US
6. Instagram: https://www.instagram.com/Madmetaverse/
7. Youtube: https://www.youtube.com/Madmetaverse
8. Telegram: https://telegram.org/Madmetaverse
*/
