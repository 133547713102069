import React from 'react';
import { Box } from '@mui/material';

const CustomProgressBar = ({ value, height }) => {
  return (
    <div
      style={{
        width: '100%',
        height: `${height}px`,
        background: '#242424',
        borderRadius: '7px',
        marginTop: '3px',
      }}>
      <Box
        sx={{
          width: `${value}%`,
          height: '100%',
          borderRadius: 'inherit',
          background:
            'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
        }}
      />
    </div>
  );
};
export default CustomProgressBar;
