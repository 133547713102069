/* eslint-disable no-console */
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useEnhancerUserQuery } from '../hooks/contracts/enhancers/userEnhancerUserQuery';
import { useMetacellUsersQuery } from '../hooks/contracts/metacell/useMetacellQuery';
import { useContracts } from '../providers';
import { formatEnhancerData, formatScientistData } from '../utils';

const GlobalStore = createContext();

const loadingStatus = {
  usersModules: true,
  usersBoxes: true,
  usersMetaCells: true,
  usersNanoCells: true,
  usersEnhancers: true,
  usersScientists: true,
  marketplaceEnhancers: true,
};

export const Store = ({ children }) => {
  const { data: usersMetacells, refetch: refetchMetacells } = useMetacellUsersQuery(false);
  const { data: usersEnhancers, refetch: refetchEnhancers } = useEnhancerUserQuery(false);
  const [usersScientists, setUsersScientists] = useState([]);
  const [marketplaceEnhancers, setMarketplaceEnhancers] = useState([]);
  const [loading, setLoading] = useState(() => loadingStatus);
  const { library, account, chainId } = useWeb3React();
  const contracts = useContracts();
  const { PolygonMarketplace, Laboratory, MDMA, Scientist, handleAggregateCall } = contracts;

  const [tokenPrice, setTokenPrice] = useState(0);
  const [boostPerBlockPrice, setBoostPerBlockPrice] = useState(BigNumber.from(0));
  const [usersBalance, setUsersBalance] = useState(BigNumber.from(0));
  const [usersBalanceOfNativeToken, setUsersBalanceOfNativeToken] = useState('0');
  const [user, setUser] = useState({});
  const [evolutionMetacell, setEvolutionMetacell] = useState(null);

  useEffect(() => {
    const updateUsersBalanceOfNativeToken = async () => {
      try {
        const balanceOfNative = await library.getBalance(account);
        setUsersBalanceOfNativeToken(balanceOfNative);
      } catch (error) {
        console.log('Error from updateUsersBalanceOfNativeToken', error);
      }
    };
    if (account) {
      updateUsersBalanceOfNativeToken();
    }
  }, [account, library]);

  const updateUsersBalance = async () => {
    try {
      const balance = await MDMA.balanceOf(account);
      setUsersBalance(balance);
    } catch (error) {
      console.log('Error from updateUsersBalance', error);
    }
  };
  useEffect(() => {
    if (MDMA) {
      updateUsersBalance();

      MDMA.on('Transfer', updateUsersBalance);
      return () => {
        MDMA.removeAllListeners();
      };
    }
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MDMA, account]);

  const updateUsersScientists = async () => {
    try {
      setLoading((loading) => ({ ...loading, usersScientists: true }));

      const usersScientistsIndexes = await Scientist.getUserMetascientistsIndexes(account);

      const tupleScientist = usersScientistsIndexes.map((tokenId) => {
        const callData = Scientist.interface.encodeFunctionData('getScientist', [tokenId]);
        return {
          target: Scientist.address,
          callData,
        };
      });
      const tubleScientistMintable = usersScientistsIndexes.map((tokenId) => {
        const callData = Laboratory.interface.encodeFunctionData('isCanCreateMetaCell', [tokenId]);
        return {
          target: Laboratory.address,
          callData,
        };
      });
      const [returnScientistData = [], returnScientistMintableData = []] = await Promise.all([
        handleAggregateCall(tupleScientist),
        handleAggregateCall(tubleScientistMintable),
      ]);
      const parseScientistData = returnScientistData.map((result) => {
        return Scientist.interface.decodeFunctionResult('getScientist', result)[0];
      });
      const parseScientistMintAbleData = returnScientistMintableData.map((result) => {
        return Laboratory.interface.decodeFunctionResult('isCanCreateMetaCell', result)[0];
      });
      const scientists = parseScientistData.map((scientist, index) =>
        formatScientistData({
          ...scientist,
          mintable: parseScientistMintAbleData[index] || false,
          tokenId: usersScientistsIndexes[index],
          owner: account,
        })
      );
      setUsersScientists(scientists);
      setLoading((loading) => ({ ...loading, usersScientists: false }));
    } catch (error) {
      setLoading((loading) => ({ ...loading, usersScientists: false }));
      // eslint-disable-next-line no-console
      console.log('Error from updateUsersScientists', error);
    }
  };

  const updateBoostPerBlockPrice = async () => {
    try {
      const boostPerBlockPrice = await Laboratory.getBoostPerBlockPrice();

      setBoostPerBlockPrice(boostPerBlockPrice);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const updateMarketplaceEnhancers = async () => {
    try {
      const derivedMarketplaceEnhancers = await PolygonMarketplace.getAllEnhancers();
      const tubleEnhancersAmount = derivedMarketplaceEnhancers.map((enhancer) => {
        const callData = PolygonMarketplace.interface.encodeFunctionData('getEnhancersAmount', [
          enhancer.id,
        ]);
        return {
          target: PolygonMarketplace.address,
          callData,
        };
      });
      const returnEnhancerAmounts = await handleAggregateCall(tubleEnhancersAmount);
      const derivedUsersEnhancersAmount = returnEnhancerAmounts.map((result) => {
        return Laboratory.interface.decodeFunctionResult('getEnhancersAmount', result)[0];
      });
      const getEnhancersAmount = (id) => {
        const isEnhancer = (enhancer) => Number(enhancer.id) === Number(id);
        const index = derivedMarketplaceEnhancers.findIndex(isEnhancer);
        if (index < 0) return 0;
        return derivedUsersEnhancersAmount[index];
      };
      const formatedMarketplaceEnhacners = await Promise.all(
        derivedMarketplaceEnhancers.map((enhancer) =>
          formatEnhancerData(enhancer, getEnhancersAmount)
        )
      );
      setMarketplaceEnhancers(
        formatedMarketplaceEnhacners.filter((enhancer) => Number(enhancer.amount) > 0)
      );
      setLoading((loading) => ({ ...loading, marketplaceEnhancers: false }));
    } catch (error) {
      setLoading((loading) => ({ ...loading, marketplaceEnhancers: false }));
      console.log('Error from updateMarketplaceEnhancers', error);
    }
  };

  const updateTokenPrice = async (marketplace) => {
    try {
      const tokenPrice = await marketplace.getBiometaTokenPrice();
      setTokenPrice(tokenPrice);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleAfterMintMetacellFromScientist = async (scientistId) => {
    try {
      await refetchMetacells();
      setUsersScientists((preScientist) => {
        const updateScientist = [...preScientist];
        const index = updateScientist.findIndex((o) => o?.id === scientistId);
        updateScientist[index] = {
          ...updateScientist[index],
          mintable: false,
        };

        return updateScientist;
      });
    } catch (e) {
      toast.error('Error: Loading Metacell Failed!');
    }
  };

  return (
    <GlobalStore.Provider
      value={{
        usersMetacells,
        usersScientists,
        setUsersScientists,
        usersEnhancers,
        marketplaceEnhancers,
        tokenPrice,
        boostPerBlockPrice,
        usersBalance,
        usersBalanceOfNativeToken,
        user,
        setUser,
        evolutionMetacell,
        setEvolutionMetacell,
        loading,
        _updateState: {
          handleAfterMintMetacellFromScientist,
        },
        methodContract: {
          _updateUsersEnhancers: refetchEnhancers,
          updateBoostPerBlockPrice,
          _updateUsersScientists: updateUsersScientists,
          _updateMarketplaceEnhancers: updateMarketplaceEnhancers,
          updateTokenPrice,
        },
      }}>
      {children}
    </GlobalStore.Provider>
  );
};

export const useAppStore = () => {
  return useContext(GlobalStore);
};
