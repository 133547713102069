import { FormControl, FormHelperText, Grid, Input, InputLabel, Typography } from '@mui/material';
import React, { useId, useMemo } from 'react';
import { debounce, get, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  underline: {
    '&::before': {
      borderBottom: '1px solid rgba(9, 235, 246,0.4)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(9, 235, 246,0.7)',
      color: 'rgba(255,255,255,0.7)',
    },
    '&::after': {
      borderBottom: '2px solid #09EBF6',
    },
  },
}));

export function FieldInputItem({
  handleChange,
  handleBlur,
  values,
  errors,
  name,
  title,
  fullWidth,
  defaultRows = 1,
  ...rest
}) {
  const fieldId = useMemo(() => nanoid(), []);
  const classes = useStyles();

  return (
    <Grid md={fullWidth ? 12 : 6} item>
      <FormControl sx={{ fontFamily: 'Montserrat, monospace', width: '100%' }}>
        <Input
          name={name}
          sx={{
            fontFamily: 'Montserrat, monospace',
          }}
          classes={{
            underline: classes.underline,
          }}
          onChange={debounce(handleChange, 100)}
          multiline
          rows={defaultRows}
          onBlur={handleBlur}
          defaultValue={get(values, name)}
          id={fieldId}
          aria-describedby={`${fieldId}-describe`}
          error={!isEmpty(get(values, name)) && get(errors, name)}
        />
        <Typography
          variant="feedbackForms"
          sx={{
            color: 'rgba(255,255,255,0.4)',
            justifyContent: 'start',
            textAlign: 'left',
            mt: '0.5rem',
            fontFamily: 'Montserrat, monospace',
          }}>
          {title}
        </Typography>
        {get(errors, name) && (
          <FormHelperText
            sx={{ color: '#f00', fontFamily: 'Montserrat, monospace' }}
            id={`${fieldId}-describe`}>
            {get(errors, name)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}
