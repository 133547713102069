import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { environment } from '@environment/';
import request from '@utils/request';
import { MARKET_PLACE_ITEM_LIMIT, MARKET_PLACE_ITEM_TYPE } from '@utils/constants';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import {
  getList,
  getListSuccess,
  getListFailure,
  sendTransactionInfoSuccess,
  sendTransactionInfoFailed,
  setPaginate,
} from '.';

export const getListAction = createAction('marketPlace/getList');
export const updateCurrentListAction = createAction('marketPlace/updateCurrentList');
export const sendTransactionInfoAction = createAction('marketPlace/sendTransactionInfo');

function* getListSaga(action) {
  try {
    const params = _omit(action.payload, ['type', 'isOnSale', 'isUser', 'userAddress']);
    const type = _get(action, 'payload.type');
    const populate = 'seller';
    if (type !== 'enhancers') {
      if (_get(action, 'payload.isUser')) {
        const data = yield call(
          request,
          `${environment.BE_SERVER_URL}/api/${type}/user/${_get(action, 'payload.userAddress')}`,
          {
            ...params,
            limit: MARKET_PLACE_ITEM_LIMIT,
            populate,
            onSale: _get(action, 'payload.isOnSale'),
          },
          { method: 'GET' }
        );
        yield put(getListSuccess(data));
      } else {
        const data = yield call(
          request,
          `${environment.BE_SERVER_URL}/api/${type}/marketplace`,
          { ...params, limit: MARKET_PLACE_ITEM_LIMIT, populate },
          { method: 'GET' }
        );
        yield put(getListSuccess(data));
      }
    } else {
      throw new Error('Not need to get');
    }
  } catch (e) {
    yield put(getListFailure(e));
  }
}

function* sendTransactions({ payload }) {
  try {
    const params = _omit(payload, ['isUser', 'userAddress']);

    yield call(
      request,
      `${environment.BE_SERVER_URL}/api/transactions/transaction`,
      { ...params },
      { method: 'Post' }
    );
    const { paginate } = yield select((state) => state.marketPlace);

    if (paginate === 1) {
      yield put(updateCurrentListAction());
    } else {
      yield put(setPaginate(1));
    }

    yield put(sendTransactionInfoSuccess());
  } catch (e) {
    console.log(e);
    yield put(sendTransactionInfoFailed());
  }
}

export function* marketPlaceWatcher() {
  yield takeLatest(getListAction, getListSaga);
  yield takeLatest(updateCurrentListAction, getListSaga);
  yield takeEvery(sendTransactionInfoAction, sendTransactions);
}
