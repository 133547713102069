import { Button } from '@mui/material';

const DetailsButton = ({ detailsButtonHandler, disabled, sx }) => {
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      sx={{
        border: '1px solid rgba(255, 255, 255, 0.1)',
        borderRadius: '26px',
        color: '#B9B9B9',
        width: '100px',
        maxWidth: '100%',
        height: '36px',
        textTransform: 'none',
        '&:hover': {
          color: '#fff',
          borderColor: 'rgba(185, 185, 185, 0.5)',
        },
        '&:active': {
          color: '#fff',
          borderColor: '#fff',
        },
        '&:disabled': {
          color: 'rgba(255, 255, 255, 0.2)',
          borderColor: 'rgba(255, 255, 255, 0.1)',
        },
        ...sx,
      }}
      onClick={detailsButtonHandler}>
      Details
    </Button>
  );
};

export default DetailsButton;
