import { Box, Grid, Typography, LinearProgress } from '@mui/material';
import DetailsButton from '@components/Buttons/DetailsButton';
import { useTicker } from '@hooks';

const DashboardNanocellCard = ({ cell, detailsButtonHandler }) => {
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();

  return (
    <Box
      sx={{
        background: '#17181C',
        border: '1px solid  #202228',
        borderRadius: '14px',
        p: '19px',
      }}>
      <Grid container justifyContent="center" gap="15px">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            background: '#000',
            borderRadius: '14px',
            overflow: 'hidden',
            width: '199px',
            height: '129px',
          }}>
          <Box sx={{ width: '115px', height: '115px' }}>
            <img
              src={cell.image || 'images/blue.png'}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              alt="blue"
            />
          </Box>
        </Grid>
        <Box
          sx={{
            background: '#000',
            borderRadius: '14px',
            overflow: 'hidden',
            width: '129px',
            height: '129px',
          }}
        />
      </Grid>
      <Grid container mt="20px" justifyContent="space-between" sx={{ overflow: 'hidden' }}>
        <Typography
          ref={nameRef}
          variant="cardName"
          sx={{
            height: '19px',
            maxWidth: '70%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'linear-gradient(to right, transparent 190px, #17181C)',
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box
            sx={{
              '&:hover': isNameOverflown && {
                transform: `translateX(-${nameOffset}px)`,
                transition: `transform ${nameTransitionTime}s`,
              },
            }}>
            {cell.name}
          </Box>
        </Typography>
        <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${cell.id}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" gap="28px" mt="15px">
        <Box sx={{ flexGrow: 1, minWidth: '209px' }}>
          <Grid container gap="4px" sx={{ width: 'auto' }}>
            <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
              Level:
            </Typography>
            <Typography variant="cardAttribute">65</Typography>
          </Grid>
          <LinearProgress
            variant="determinate"
            value={65}
            sx={{
              borderRadius: '7px',
              backgroundColor: '#242424',
              mt: '4px',
              '.MuiLinearProgress-bar': {
                background:
                  'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
              },
            }}
          />
        </Box>
        <DetailsButton
          detailsButtonHandler={() => {
            detailsButtonHandler(cell);
          }}
        />
      </Grid>
    </Box>
  );
};

export default DashboardNanocellCard;
