export default (num) => {
  let sign = '';
  num = String(num);
  if (num.charAt(0) === '-') {
    num = num.substring(1);
    sign = '-';
  }
  const arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;

  const dot = '.';
  const n = arr[0].replace(/^0+/, '');
  const exp = Number(arr[1]);
  let w = n.replace(dot, '');
  const pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp;
  const L = pos - w.length;
  const s = `${BigInt(w)}`;
  if (exp >= 0) {
    if (L >= 0) {
      w = s + '0'.repeat(L);
    } else {
      w = r();
    }
  } else if (pos <= 0) {
    w = `0${dot}${'0'.repeat(Math.abs(pos))}${s}`;
  } else {
    w = r();
  }
  const t = w.split(dot);
  if ((Number(t[0]) === 0 && Number(t[1]) === 0) || (Number(w) === 0 && Number(s) === 0)) w = '0';
  return sign + w;
  function r() {
    return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
  }
};
