import { styled } from '@mui/material/styles';

export const CellModuleDetailsModalWrapper = styled('div')`
  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modal__wrapper {
    max-width: 918px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 14px;
    padding: 26px;
    top: 50%;
    left: 50%;
    /* position: relative; */
  }
  .loading__wrapper {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 100%;
    height: 100%;
    transform: translate(-26px, -26px);
    .loading__content {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .close_icon {
    cursor: pointer;
    align-self: flex-end;
  }
  .cover__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    box-shadow: inset 0px 0px 50px 15px rgba(0, 0, 0, 0.3);
  }
  .id_text {
    font-weight: 400;
    font-size: 18px;
    opacity: 0.4;
    height: 21px;
  }
  .new_module_text {
    font-weight: 400;
    font-size: 24px;
    height: 21px;
    margin-bottom: 10px;
    color: #09ebf6;
  }
  .module_id {
    font-weight: 400;
    font-size: 18px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    height: 21px;
    padding: 2px 4px;
  }
  .name__wrapper {
    height: 42px;
    margin-top: 3px;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:hover:after {
      width: 0;
      height: 0;
    }
  }
  .name {
    font-weight: 500;
    font-size: 36px;
  }
  .abilities {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
    justify-content: left;
    width: 100%;
    display: inline-block;
    .abilities_rarity {
      text-transform: capitalize;
      color: #7ebaff;
    }
  }
  .sale_status {
    margin-top: 19px;
  }
  .describe {
    color: #b9b9b9;
    white-space: normal;
    text-align: left;
    margin-top: 18px;
    width: 100%;
    max-height: 60px;
    font-size: 14px;
    font-weight: 500;
    align-items: stretch;
    justify-content: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const WrapperImage = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-radius: 14px;
`;

export const ListDetailItems = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 136px;
  overflow: auto;
  gap: 10px;
  margin-top: auto;
  & > div {
    width: 48%;
    @media (max-width: 450px) {
      width: 98%;
    }
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff1a;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b980;
    border-radius: 2px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
`;

export const HrGap = styled('div')`
  height: 30px;
  width: 100%;
  margin-bottom: 14px;
  border-bottom: 1px solid #ffffff1a;
`;
