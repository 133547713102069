import { FormControl, FormHelperText, Grid, Input, InputLabel, Typography } from '@mui/material';
import React, { useId, useMemo } from 'react';
import { debounce, get, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  underline: {
    '&::before': {
      border: '1px solid rgba(9, 235, 246,0.4)',
      height: '100px',
    },
    '&:hover:not(.Mui-disabled):before': {
      border: '1px solid rgba(9, 235, 246,0.7)',
      color: 'rgba(255,255,255,0.7)',
    },
    '&::after': {
      border: '2px solid #09EBF6',
    },
    '& textarea': {
      height: '46px !important',
    },
  },
}));

export function FieldInputReason({
  handleChange,
  handleBlur,
  values,
  errors,
  name,
  title,
  placeholder,
  fullWidth,
  defaultRows = 1,
  ...rest
}) {
  const fieldId = useMemo(() => nanoid(), []);
  const classes = useStyles();

  return (
    <Grid item sx={{ mt: '0.5rem', width: fullWidth ? '100%' : '50%' }}>
      <FormControl sx={{ fontFamily: 'Montserrat, monospace', width: '100%' }}>
        <Input
          name={name}
          sx={{
            fontFamily: 'Montserrat, monospace',
            height: '100px',
            padding: '10px',
          }}
          classes={{
            underline: classes.underline,
          }}
          placeholder={placeholder}
          onChange={debounce(handleChange, 100)}
          multiline
          rows={defaultRows}
          onBlur={handleBlur}
          defaultValue={get(values, name)}
          id={fieldId}
          aria-describedby={`${fieldId}-describe`}
          error={!isEmpty(get(values, name)) && get(errors, name)}
        />
        {get(errors, name) && (
          <FormHelperText
            sx={{ color: '#f00', fontFamily: 'Montserrat, monospace', height: '60px' }}
            id={`${fieldId}-describe`}>
            {get(errors, name)}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}
