/* eslint-disable react/jsx-one-expression-per-line */
import { CircularProgress, Grid, Pagination, Typography, useMediaQuery } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import _isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  BuyConfirmModal,
  CellModuleDetailsModal,
  DashboardModuleCard,
  EditPriceConfirmModal,
  EnhancerCard,
  LoadingModal,
  MetacellCard,
  ModuleBoxCard,
  NanocellCard,
  RemoveConfirmModal,
  ScientistCard,
  ScientistModal,
  SellConfirmModal,
} from '@components';
import AltTabBoardV2 from '@components/AltTabBoardV2';
import CustomSelect from '@components/CustomSelect';
import MarketPlaceFilters from '@components/MarketPlace/FilterOptions';
import CellDetailsModal from '@components/Modals/CellDetailsModal';
import { useExPrice } from '@hooks';
import { getList, resetState, setPaginate } from '@providers/MarketPlaceProvider';
import { useAppStore } from '@store/index';
import {
  FIELDS_TABS,
  MARKET_PLACE_ITEM_LIMIT,
  MARKET_PLACE_ITEM_TYPE,
  MARKET_PLACE_ITEM_TYPE_MY_FIELD,
  PRICE_SORT_METACELL_OPTIONS,
  PRICE_SORT_OPTIONS,
  PRICE_SORT_SCIENTIST_OPTIONS,
} from '@utils/constants';
import transformCell from '@utils/transformCell';
import { withModalContext } from './HocHelpers/withModalContext';
import { MarketPlaceContainer, MarketPlaceItemTypeButton } from './styles';

const TheField = () => {
  const { account } = useWeb3React();
  const ETHPrice = useExPrice();
  const navigate = useNavigate();
  const params = useParams();
  const isTablet = useMediaQuery('(max-width:931px)');
  const [activeUserTab, setActiveUserTab] = useState(MARKET_PLACE_ITEM_TYPE_MY_FIELD[0]);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [activeTab, setActiveTab] = useState(params.tab || FIELDS_TABS[0].key);
  const [sortPrice, setSortPrice] = useState(PRICE_SORT_OPTIONS[0].value);
  const [showBuyConfirmModal, setShowBuyConfirmModal] = useState(false);
  const [showSellConfirmModal, setShowSellConfirmModal] = useState(false);
  const [showRemoveConfirmModal, setShowRemoveConfirmModal] = useState(false);
  const [showEditPriceConfirmModal, setShowEditPriceConfirmModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const [currentToken, setCurrentToken] = useState();
  const [tokenBuy, setTokenBuy] = useState(false);
  const [mining, setMining] = useState(false);
  // const [price, setPrice] = useState(JSON.stringify({ $gte: 0, $lte: 100 }));
  const { list = [], isLoading, total, paginate: page, isSendingTransaction } = useSelector(
    (state) => state.marketPlace
  );
  const dispatch = useDispatch(false);
  const {
    usersEnhancers,
    tokenPrice,
    marketplaceEnhancers,
    methodContract: { _updateMarketplaceEnhancers },
    loading: { marketplaceEnhancers: enhancerLoading },
  } = useAppStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const showUsers = activeTab === FIELDS_TABS[1].key;
  const searchParamsParse = Object.fromEntries([...searchParams]);
  const {
    type = MARKET_PLACE_ITEM_TYPE[0].value,
    sort = PRICE_SORT_OPTIONS[0].value,
    price = JSON.stringify({ $gte: 0, $lte: 100 }),
    isOnSale = activeUserTab === MARKET_PLACE_ITEM_TYPE_MY_FIELD[1],
  } = searchParamsParse;

  const detailsButtonHandler = (token) => {
    setCurrentToken(token);
    setIsOpenDetail((o) => !o);
  };

  const buyButtonHandler = () => {
    setShowBuyConfirmModal((showBuyConfirmModal) => !showBuyConfirmModal);
    setTokenBuy(false);
    setIsOpenDetail(false);
  };

  const sellButtonHandler = () => {
    setIsOpenDetail(false);
    setShowSellConfirmModal((showSellConfirmModal) => !showSellConfirmModal);
  };

  const removeButtonHandler = () => {
    setIsOpenDetail(false);
    setShowRemoveConfirmModal((showRemoveConfirmModal) => !showRemoveConfirmModal);
  };

  const editPriceButtonHandler = () => {
    setIsOpenDetail(false);
    setShowEditPriceConfirmModal((showEditPriceConfirmModal) => !showEditPriceConfirmModal);
  };

  const handleChangePageNumber = (e, value) => {
    dispatch(setPaginate(value));
  };

  const refreshData = () => {
    const isUser = showUsers;
    const userAddress = account;
    setIsOpenDetail(false);
    dispatch(getList({ page, type, sort, isUser, userAddress, isOnSale }));
  };

  useEffect(() => {
    if (type === MARKET_PLACE_ITEM_TYPE[2].value) {
      _updateMarketplaceEnhancers();
    }
    dispatch(resetState());
    setSearchParams({ ...searchParamsParse });
    dispatch(setPaginate(1));
  }, [type, isOnSale]);

  useEffect(() => {
    document.title = 'Mad Metaverse - The Field';
  }, []);

  useEffect(() => {
    if (type !== 'mad-boxes') {
      dispatch(
        getList({ page, type, price, sort, isUser: showUsers, userAddress: account, isOnSale })
      );
    } else {
      dispatch(resetState());
    }
    return () => {
      dispatch(resetState());
    };
  }, [page, activeTab, type, sort, price, isOnSale, account, dispatch, showUsers]);

  useEffect(() => {
    if (!isLoading) return;
    setShowBuyConfirmModal(false);
    setShowSellConfirmModal(false);
    setShowRemoveConfirmModal(false);
    setShowEditPriceConfirmModal(false);
    setShowLoadingModal(false);
    setIsOpenDetail(false);
  }, [isLoading]);

  useEffect(() => {
    searchParams.set('type', MARKET_PLACE_ITEM_TYPE[0].value);
    dispatch(setPaginate(1));
    setActiveUserTab(MARKET_PLACE_ITEM_TYPE_MY_FIELD[0]);
    navigate(`/the-field/${activeTab}?${searchParams.toString()}`, { replace: false });
    dispatch(resetState());
  }, [activeTab]);

  const renderCard = (token) => {
    const formattedToken = transformCell(token, type);
    if (type === MARKET_PLACE_ITEM_TYPE[0].value) {
      return (
        <ScientistCard
          key={token.id}
          detailsButtonHandler={detailsButtonHandler}
          sellButtonHandler={sellButtonHandler}
          removeButtonHandler={removeButtonHandler}
          editPriceButtonHandler={editPriceButtonHandler}
          buyButtonHandler={buyButtonHandler}
          setCurrentToken={setCurrentToken}
          scientist={formattedToken}
          ETHPrice={ETHPrice}
          showUsers={showUsers}
        />
      );
    }
    if (type === MARKET_PLACE_ITEM_TYPE[1].value) {
      return (
        <MetacellCard
          detailsButtonHandler={detailsButtonHandler}
          sellButtonHandler={sellButtonHandler}
          removeButtonHandler={removeButtonHandler}
          editPriceButtonHandler={editPriceButtonHandler}
          buyButtonHandler={buyButtonHandler}
          setCurrentToken={setCurrentToken}
          cell={formattedToken}
          showUsers={showUsers}
        />
      );
    }
    if (type === MARKET_PLACE_ITEM_TYPE[2].value) {
      return (
        <EnhancerCard
          buyButtonHandler={buyButtonHandler}
          token={formattedToken}
          setCurrentToken={setCurrentToken}
          tokenPrice={tokenPrice}
          showUsers={showUsers}
        />
      );
    }
    if (type === MARKET_PLACE_ITEM_TYPE[3].value) {
      return (
        <NanocellCard
          detailsButtonHandler={detailsButtonHandler}
          sellButtonHandler={sellButtonHandler}
          removeButtonHandler={removeButtonHandler}
          editPriceButtonHandler={editPriceButtonHandler}
          buyButtonHandler={buyButtonHandler}
          setCurrentToken={setCurrentToken}
          cell={formattedToken}
          showUsers={showUsers}
        />
      );
    }
    if (type === MARKET_PLACE_ITEM_TYPE[4].value) {
      return (
        <DashboardModuleCard
          module={formattedToken}
          detailsButtonHandler={detailsButtonHandler}
          activeTab={activeTab}
          editPriceButtonHandler={editPriceButtonHandler}
          sellButtonHandler={sellButtonHandler}
          removeButtonHandler={removeButtonHandler}
          buyButtonHandler={buyButtonHandler}
          setCurrentToken={setCurrentToken}
        />
      );
    }
    return null;
  };

  const handleSortMyField = (type, list = []) => {
    switch (sort) {
      case PRICE_SORT_OPTIONS[0].value:
        return list.sort((itemA, itemB) => itemA?.price - itemB?.price);
      case PRICE_SORT_OPTIONS[1].value:
        return list.sort((itemA, itemB) => itemB?.price - itemA?.price);
      case PRICE_SORT_OPTIONS[2].value:
        return list.sort((itemA, itemB) => itemA?.stage - itemB?.stage);
      case PRICE_SORT_OPTIONS[3].value:
        return list.sort((itemA, itemB) => itemB?.stage - itemA?.stage);
      case PRICE_SORT_OPTIONS[4].value:
        return list.sort((itemA, itemB) => itemA?.exp - itemB?.exp);
      case PRICE_SORT_OPTIONS[5].value:
        return list.sort((itemA, itemB) => itemB?.exp - itemA?.exp);
      default:
        return list;
    }
  };

  const renderList = useMemo(() => {
    let listToRender = list;
    if (type === 'enhancers') {
      listToRender = handleSortMyField(
        type,
        !showUsers ? marketplaceEnhancers : usersEnhancers
      ).slice((page - 1) * MARKET_PLACE_ITEM_LIMIT, page * MARKET_PLACE_ITEM_LIMIT);
    }
    const totalPage =
      type === 'enhancers'
        ? Math.ceil(listToRender.length / MARKET_PLACE_ITEM_LIMIT)
        : Math.ceil(total / MARKET_PLACE_ITEM_LIMIT);
    // eslint-disable-next-line no-constant-condition
    if (isLoading || isSendingTransaction || (enhancerLoading && type === 'enhancers')) {
      return (
        <Grid item className="market-place__shop-loading" lg={12}>
          <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
        </Grid>
      );
    }
    if (type !== 'mad-boxes' && _isEmpty(listToRender)) {
      return (
        <Typography variant="emptyMessage" sx={{ opacity: 0.4, mt: '8%', flexGrow: 1 }}>
          {`There are no ${type} here yet`}
        </Typography>
      );
    }
    return (
      <>
        {type === 'mad-boxes' ? (
          <Grid item md={6} lg={4} xl={3} className="market-place__shop-item">
            <ModuleBoxCard
              box={{ id: 1, onSale: activeUserTab === 'On sale', price: '15' }}
              activeTab={activeTab}
              editPriceButtonHandler={editPriceButtonHandler}
              setCurrentToken={setCurrentToken}
              buyButtonHandler={buyButtonHandler}
            />
          </Grid>
        ) : (
          <>
            {listToRender.map((token) => {
              return (
                <Grid item md={6} lg={4} xl={3} key={token.id} className="market-place__shop-item">
                  {renderCard(token)}
                </Grid>
              );
            })}
            <Grid className="market-place__shop-pagination" item xs={12} md={12} lg={12}>
              <Pagination
                variant="outlined"
                count={totalPage > 1 ? totalPage : 1}
                page={page}
                onChange={handleChangePageNumber}
                sx={{
                  '.Mui-selected': {
                    backgroundColor: 'rgba(9, 235, 246, 0.8) !important',
                  },
                  '.Mui-selected:hover': {
                    backgroundColor: 'rgba(9, 235, 246, 0.7) !important',
                  },
                }}
              />
            </Grid>
          </>
        )}
      </>
    );
  }, [
    isLoading,
    isSendingTransaction,
    list,
    marketplaceEnhancers,
    page,
    showUsers,
    total,
    type,
    usersEnhancers,
  ]);

  const renderSortList = useMemo(() => {
    if (type === 'scientists') {
      setSortPrice(PRICE_SORT_SCIENTIST_OPTIONS[0].value);
      return PRICE_SORT_SCIENTIST_OPTIONS;
    }
    if (type === 'meta-cells') {
      setSortPrice(PRICE_SORT_METACELL_OPTIONS[0].value);
      return PRICE_SORT_METACELL_OPTIONS;
    }
    setSortPrice(PRICE_SORT_OPTIONS[0].value);
    return PRICE_SORT_OPTIONS;
  }, [type]);

  return (
    <>
      <MarketPlaceContainer>
        {/* <MarketPlaceHeader isShowBanner={activeTab === FIELDS_TABS[0].key} /> */}
        <Typography className="the-field__title" variant="pageTitle" component="h1">
          The Field
        </Typography>
        <div className="market-place__container">
          <AltTabBoardV2 activeTab={activeTab} setActiveTab={setActiveTab} tabs={FIELDS_TABS} />
          <div className="market-place__sort-select">
            <div>
              {activeTab === FIELDS_TABS[1].key &&
                MARKET_PLACE_ITEM_TYPE_MY_FIELD.map((fieldType) => {
                  return (
                    <MarketPlaceItemTypeButton
                      key={`typeBTN${fieldType}`}
                      onClick={() => {
                        setActiveUserTab(fieldType);
                        setSearchParams({
                          ...searchParamsParse,
                          isOnSale: fieldType === MARKET_PLACE_ITEM_TYPE_MY_FIELD[1],
                        });
                      }}
                      className={`${activeUserTab === fieldType && 'active'}`}>
                      {fieldType}
                    </MarketPlaceItemTypeButton>
                  );
                })}
            </div>

            <CustomSelect
              options={renderSortList}
              value={sortPrice}
              handleOnChange={(e) => {
                const { value } = e.target;
                setSortPrice(value);
                setSearchParams({ sort: value, type });
              }}
            />
          </div>
          <Grid className="market-place__shop" container>
            <Grid item gap="40px" className="market-place__shop-filter">
              <MarketPlaceFilters
                isOnSale={activeUserTab === MARKET_PLACE_ITEM_TYPE_MY_FIELD[1]}
                isShowMarketFilter={params.tab !== FIELDS_TABS[1].key}
                activeTab={activeTab}
                sortPrice={sortPrice}
              />
            </Grid>
            <Grid
              sx={{
                width: '100%',
                position: 'relative',
                paddingTop: '40px',
              }}>
              {total > 0 && (
                <Grid
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: '-30px',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '120px',
                    height: '40px',
                    '@media only screen and (max-width: 768px)': { marginTop: '20px' },
                  }}>
                  {total} items
                </Grid>
              )}
              <Grid container className="market-place__shop-items" spacing={[0, 3]}>
                {renderList}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MarketPlaceContainer>
      {type !== MARKET_PLACE_ITEM_TYPE[0].value ? (
        <CellDetailsModal
          open={isOpenDetail}
          detailsButtonHandler={detailsButtonHandler}
          setCurrentToken={setCurrentToken}
          buyButtonHandler={buyButtonHandler}
          sellButtonHandler={sellButtonHandler}
          removeButtonHandler={removeButtonHandler}
          editPriceButtonHandler={editPriceButtonHandler}
          cell={transformCell(currentToken, type)}
          handleClose={() => {
            setIsOpenDetail(false);
          }}
          showUsers={showUsers}
          ETHPrice={ETHPrice}
          field
        />
      ) : (
        <ScientistModal
          open={isOpenDetail}
          detailsButtonHandler={detailsButtonHandler}
          scientist={transformCell(currentToken, type)}
          handleClose={() => {
            setIsOpenDetail(false);
          }}
          editPriceButtonHandler={editPriceButtonHandler}
          buyButtonHandler={buyButtonHandler}
          sellButtonHandler={sellButtonHandler}
          removeButtonHandler={removeButtonHandler}
          mintButtonHandler={() => {
            toast('Please mint Metacell in the My Collection page', { icon: '⚠️' });
          }}
        />
      )}
      <CellModuleDetailsModal
        open={isOpenDetail && type === 'modules'}
        detailsButtonHandler={detailsButtonHandler}
        module={transformCell(currentToken)}
        activeTab={activeTab}
        sellButtonHandler={sellButtonHandler}
        removeButtonHandler={removeButtonHandler}
        buyButtonHandler={buyButtonHandler}
        editPriceButtonHandler={editPriceButtonHandler}
        setCurrentToken={setCurrentToken}
      />
      <BuyConfirmModal
        open={showBuyConfirmModal}
        buyButtonHandler={buyButtonHandler}
        setShowLoadingModal={setShowLoadingModal}
        setShowDetails={setIsOpenDetail}
        setLoadingModalMessage={setLoadingModalMessage}
        setMining={setMining}
        token={transformCell(currentToken, type)}
        showEnhancers={type === 'enhancers'}
        tokenBuy={tokenBuy}
        ETHPrice={ETHPrice}
        showUsers={showUsers}
        refreshData={refreshData}
      />
      <SellConfirmModal
        open={showSellConfirmModal}
        sellButtonHandler={sellButtonHandler}
        setShowLoadingModal={setShowLoadingModal}
        setShowDetails={setIsOpenDetail}
        setLoadingModalMessage={setLoadingModalMessage}
        setMining={setMining}
        token={transformCell(currentToken, type)}
        ETHPrice={ETHPrice}
        showUsers={showUsers}
        refreshData={refreshData}
      />
      <RemoveConfirmModal
        open={showRemoveConfirmModal}
        removeButtonHandler={removeButtonHandler}
        setShowLoadingModal={setShowLoadingModal}
        setShowDetails={setIsOpenDetail}
        setLoadingModalMessage={setLoadingModalMessage}
        setMining={setMining}
        token={transformCell(currentToken, type)}
        ETHPrice={ETHPrice}
        showUsers={showUsers}
        refreshData={refreshData}
      />
      <EditPriceConfirmModal
        open={showEditPriceConfirmModal}
        editPriceButtonHandler={editPriceButtonHandler}
        setShowLoadingModal={setShowLoadingModal}
        setShowDetails={setIsOpenDetail}
        setLoadingModalMessage={setLoadingModalMessage}
        setMining={setMining}
        token={transformCell(currentToken, type)}
        ETHPrice={ETHPrice}
        showUsers={showUsers}
        refreshData={refreshData}
      />
      <LoadingModal open={showLoadingModal} text={loadingModalMessage} mining={mining} />
    </>
  );
};

export default TheField;
