import { createContext, useContext } from 'react';
import { getContracts } from '../utils';

const ContractsContext = createContext();

export const ContractsProvider = ({ children }) => {
  const contracts = getContracts();

  return <ContractsContext.Provider value={contracts}>{children}</ContractsContext.Provider>;
};

export const useContracts = () => {
  return useContext(ContractsContext);
};
