import { Checkbox, Grid, Radio, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PointStyled = styled('div')(({ active }) => ({
  width: '2rem',
  height: '2rem',
  background: active ? '#09ebf6' : '#d9d9d9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  color: active ? '#fff' : '#000',
  cursor: 'pointer',
  '&:hover': {
    background: '#09ebf6',
    color: '#fff',
  },
  '&:focus': {
    background: '#09ebf6',
    color: '#fff',
  },
}));

export const TypoStyled = styled(Typography)`
  padding-left: 16px;
  margin-top: 20px;
  color: #d9d9d9;
  white-space: normal;
  width: 100%;
  word-break: break-all;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-transform: uppercase;
  justify-content: start;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat, monospace;
  -webkit-scrollbar: {
    display: none;
  }
`;

export const CheckBoxStyled = styled(Checkbox)`
  svg {
    fill: white;
  }
`;

export const RadioStyled = styled(Radio)`
  svg {
    fill: white;
  }
`;

export const GridStyled = styled(Grid)`
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff1a;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b9b9b980;
    border-radius: 2px;
  }
`;

export const ButtonBanner = styled('span')`
  font-weight: bold;
  cursor: pointer;
  margin: 0 5px;
  text-decoration: underline;
  display: contents;
`;
