/* eslint-disable eqeqeq */
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import chainLinkABI from '@abis/ChainLink.json';
import { environment } from '@environment';

const useExPrice = () => {
  const [ExPrice, setExPrice] = useState(0);
  const [unmounted, setUnmounted] = useState(false);
  const { library, account } = useWeb3React();

  useEffect(() => {
    const getExPrice = async () => {
      if (!account) return;
      try {
        const provider = library.getSigner(account);
        const aggregatorV3InterfaceABI = chainLinkABI;

        const priceFeed = new ethers.Contract(
          environment.CHAINLINK_ETH_ADDRESS,
          aggregatorV3InterfaceABI,
          provider
        );
        const response = await priceFeed.latestRoundData();
        if (!unmounted) {
          setExPrice(response.answer);
        }
      } catch (error) {
        console.log('GetExPrice ~ error', error);
      }
    };

    getExPrice();

    const interval = setInterval(getExPrice, 60000);

    return () => {
      setUnmounted(true);
      clearInterval(interval);
    };
  }, [account, library, unmounted]);

  return ExPrice;
};

export default useExPrice;
