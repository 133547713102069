/* eslint-disable prettier/prettier */
import { Button, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MarketPlaceFilter = styled('div')`
  .market-place-item-filter__container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 40px;
    align-items: flex-start;
    height: 100%;
    @media (max-width: 767px) {
      margin: 20px 0 30px;
    }
    .MuiButton-root {
      &:nth-of-type(2n + 1) {
        margin: 0 20px 15px 0;
      }
      &:last-of-type {
        margin: 0 20px 0 0;
      }
      @media (max-width: 767px) {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
  .slider-input__container {
    margin-top: 20px;
  }
  .market-place-item-filter__price {
    margin-bottom: 40px;
    position: relative;
  }
  .market-place-item-filter__accordion {
    margin-bottom: 20px;
    &:first-of-type {
      margin-top: 20px;
    }
    .MuiAccordion-root {
      background: none;
      box-shadow: none;
      .MuiAccordionSummary-root {
        min-height: unset;
        height: 20px;
        line-height: 20px;
        padding: 0;
        border-bottom: none;
        .MuiAccordionSummary-content {
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
        .MuiAccordionSummary-expandIconWrapper {
          transform: unset;
        }
      }
      .MuiAccordionDetails-root {
        padding: 0;
        .slider-input__container {
          margin-top: 15px;
        }
      }
    }
  }
`;

export const MarketPlaceItemTypeButton = styled(Button)`
  padding: 12px 20px;
  height: 40px;
  border-radius: 20px;
  background: none;
  color: #fff;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: none;
  &.active {
    border: solid 1px #fff;
  }
`;

export const RadioButtonGroup = styled(RadioGroup)`
  .MuiFormControlLabel-root {
    &:first-of-type {
      margin-top: 23px;
    }
    &:last-of-type {
      margin-bottom: 40px;
    }
    .MuiRadio-root {
      color: rgba(255, 255, 255, 0.4);
      &.Mui-checked {
        color: #09ebf6;
      }
      &:hover {
        color: #09ebf6;
      }
    }
  }
`;
