import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { environment } from '@environment/index';

export const supportedChainIds = [
  Number(environment.ETH_NETWORK_ID),
  Number(environment.CURRENT_NETWORK_ID),
  Number(environment.ETH_MAINNET_NETWORK_ID),
].filter((o) => o);

export const resetWalletConnector = (connector) => {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
};

export const injected = new InjectedConnector({
  supportedChainIds,
});

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: environment.CURRENT_NETWORK_RPC_URL,
    [environment.CURRENT_NETWORK_ID]: environment.CURRENT_NETWORK_RPC_URL,
  },
  supportedChainIds,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: environment.CURRENT_NETWORK_RPC_URL,
  appName: 'mad metaverse',
  supportedChainIds,
});

export const ConnectorNames = {
  Injected: 'Injected',
  Bitkeep: 'Bitkeep',
  Network: 'Network',
  WalletConnect: 'WalletConnect',
  WalletLink: 'WalletLink',
  Ledger: 'Ledger',
  Trezor: 'Trezor',
  Lattice: 'Lattice',
  Frame: 'Frame',
  Authereum: 'Authereum',
  Fortmatic: 'Fortmatic',
  Magic: 'Magic',
  Portis: 'Portis',
  Torus: 'Torus',
};

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.Bitkeep]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
};

export const connectorByConstructorName = {
  WalletConnect: {
    connector: walletconnect,
    connectorName: 'WalletConnect',
  },
  Injected: {
    connector: injected,
    connectorName: 'Injected',
  },
  WalletLink: {
    connector: walletlink,
    connectorName: 'WalletLink',
  },
};
