import { useState, useEffect } from 'react';

const useWrapDetect = (open, ref, width, tab) => {
  const [isWraped, setIsWraped] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      if (ref?.current?.offsetHeight > width) {
        setIsWraped(true);
      } else if (ref?.current?.offsetHeight <= width) {
        setIsWraped(false);
      }
    };

    if (ref.current) {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
    }

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [open, tab]);

  return isWraped;
};

export default useWrapDetect;
