import { Button } from '@mui/material';
import React from 'react';
import { useToggle } from '@hooks/common/useToggle';
import { LeanderBoardModal } from './modal/LeaderboardModal';

const buttonLeaderboardSx = {
  backgroundImage: 'url(/images/ranking/rank-button.png)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: 150,
  height: 50,
  position: 'fixed',
  bottom: 10,
  right: 8,
  zIndex: 50,
};

export const MetacellLeaderBoard = () => {
  const [openLeaderboard, toggleLeaderboard] = useToggle(false);

  return (
    <>
      <Button sx={buttonLeaderboardSx} onClick={toggleLeaderboard}>
        Leaderboard
      </Button>
      <LeanderBoardModal open={openLeaderboard} handleClose={toggleLeaderboard} />
    </>
  );
};
