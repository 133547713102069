import { WertProvider } from '@providers/WertProvider';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';

import { ContractsProvider } from './providers';
import Routes from './routes/Routes';
import { Store } from './store/index';
import { store as reduxStore } from './store/rootReducer';
import { getLibrary } from './utils';

import './index.css';

require('dotenv').config();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ContractsProvider>
        <WertProvider>
          <ReduxProvider store={reduxStore}>
            <QueryClientProvider client={queryClient}>
              <Store>
                <Routes />
              </Store>
            </QueryClientProvider>
          </ReduxProvider>
        </WertProvider>
      </ContractsProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
