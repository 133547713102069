import { Tabs, Tab, Grow } from '@mui/material';
import PropTypes from 'prop-types';
import { StyledTabs } from './styles';

const AltTabBoardV2 = ({ tabs, activeTab, setActiveTab }) => {
  const handleOnChange = (e, newValue) => {
    e.preventDefault();
    if (setActiveTab) {
      setActiveTab(newValue);
    }
  };

  return (
    <StyledTabs value={activeTab} onChange={handleOnChange}>
      {tabs.map((tab) => {
        return <Tab key={`${tab.label}.${tab.key}`} label={tab.label} value={tab.key} />;
      })}
    </StyledTabs>
  );
};

export default AltTabBoardV2;
