import {
  AltTabBoard,
  BuyButton,
  BuyConfirmModal,
  CellModuleDetailsModal,
  EmptyGridMessage,
  ExternalNFTCard,
  LoadingModal,
  MainTabBoard,
  MintConfirmModal,
  NanocellDetailsModal,
  ScientistModal,
  UnboxModuleBoxModal,
} from '@components';
import CellDetailsModal from '@components/Modals/CellDetailsModal';
import useExPrice from '@hooks/contracts/useExPrice';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { getUserCellModules } from '@providers/CellModuleProvider';
import { resetState } from '@providers/ModalsProvider';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import { useAppStore } from '@store';
import formatPrice from '@utils/formatPrice';
import getWalletName from '@utils/getWalletName';
import transformModule from '@utils/transformModule';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { get as _get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useContracts } from '../../providers';
import { BoxDashboardCard } from './BoxDashboardCard';
import { EnhancerDashboardCard } from './EnhancerDashboardCard';
import { useFetchDashboardSMC } from './hooks/useFetchDashboardSMC';
import { MetacellDashboardCard } from './Metacells/MetacellDashboardCard';
import { ModuleDashboard } from './ModuleDashboard';
import { NanocellDashboardCard } from './NanocellDashboardCard';
import { ScientistDashboardCard } from './ScientistDashboardCard';

const externalMock = [
  { name: 'CyberKong', image: 'images/CyberKong.png', id: '3497' },
  { name: 'CryptoPunk', image: 'images/CryptoPunk1.png', id: '6046' },
  { name: 'CryptoPunk', image: 'images/CryptoPunk2.png', id: '9621' },
  { name: 'GalactiApe', image: 'images/GalactiApe.png', id: '2129' },
  { name: 'BoredApe', image: 'images/BoredApe.png', id: '1015' },
];

const stakedMock = [
  { name: 'CoolCat', image: 'images/CoolCat.png', id: '6406' },
  { name: 'CyberKong', image: 'images/CyberKong1.png', id: '2785' },
];

const sideTabs = ['External Tokens', 'Stacked Tokens'];

const tabs = [
  'Scientists',
  'Metacells',
  // 'Enhancers', 'Nanocells', 'Modules', 'Mad Boxes'
];

const Dashboard = () => {
  const connectedWalletStatus = JSON.parse(
    localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
  );
  const walletText = getWalletName(connectedWalletStatus);
  const dispatch = useDispatch();
  const { usersBalance, tokenPrice, loading, latestCreatedModule } = useAppStore();
  const latestModule = useRef(null);

  const ETHPrice = useExPrice();
  const { account, chainId } = useWeb3React();
  const { PolygonMarketplace } = useContracts();
  const tablet = useMediaQuery('(max-width:1024px)');
  const desktop = useMediaQuery('(min-width:1024px)');
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activeSideTab, setActiveSideTab] = useState(sideTabs[0]);
  const [currentToken, setCurrentToken] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [showBuyConfirmModal, setShowBuyConfirmModal] = useState(false);
  const [showMintConfirmModal, setShowMintConfirmModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMessage, setLoadingModalMessage] = useState('');
  const [mining, setMining] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLoadingUnboxModal, setShowLoadingUnboxModal] = useState(false);
  const [showLatestCreatedModuleModal, setShowLatestCreatedModuleModal] = useState(false);

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, [chainId]);

  const balanceInETH =
    usersBalance && tokenPrice
      ? ethers.utils.formatUnits(usersBalance.mul(Number(tokenPrice) || 1), 36)
      : '0';

  const balanceInUSD =
    usersBalance && ETHPrice
      ? ethers.utils.formatUnits(ethers.utils.parseUnits(balanceInETH, 36).mul(ETHPrice), 44)
      : '0';

  const detailsButtonHandler = useCallback((token) => {
    setCurrentToken(token);
    setShowDetails((showDetails) => !showDetails);
  }, []);

  function handleShowLatestCreatedModuleModal() {
    setShowLatestCreatedModuleModal(true);
  }

  useFetchDashboardSMC(activeTab);

  useEffect(() => {
    if (
      Number(latestCreatedModule) &&
      Number(latestCreatedModule) !== latestModule &&
      showLatestCreatedModuleModal
    ) {
      setCurrentToken(transformModule({ tokenId: latestCreatedModule }));
      latestModule.current = Number(latestCreatedModule);
    }
  }, [latestCreatedModule, latestModule, showLatestCreatedModuleModal]);

  const handleClose = () => {
    if (showLatestCreatedModuleModal) {
      setShowLatestCreatedModuleModal(false);
    } else setShowDetails(false);
  };

  const openBoxButtonHandler = useCallback(
    async (box) => {
      const boxID = _get(box, 'id');
      setErrorMessage(`Please confirm transaction in your ${walletText}`);
      setShowConfirmModal(true);
      try {
        const tx = await PolygonMarketplace.openBox(boxID);
        setShowLoadingUnboxModal(true);
        setShowConfirmModal(false);
        await tx.wait();
        handleShowLatestCreatedModuleModal();
      } catch (error) {
        if (error.code !== 4001) {
          setErrorMessage(
            error?.data?.message
              ? error?.data?.message.match(/reason string '(.*?)'$/)[1]
              : error.message
          );
        } else {
          setErrorMessage(error.message);
        }
        setShowErrorModal(true);
        setShowLoadingUnboxModal(false);
      } finally {
        setShowConfirmModal(false);
        setShowLoadingUnboxModal(false);
      }
    },
    [PolygonMarketplace, walletText]
  );

  useEffect(() => {
    document.title = 'Mad Metaverse - My Collection';
  }, []);

  useEffect(() => {
    dispatch(getUserCellModules({ page: 1, address: account }));
    return () => {
      dispatch(resetState());
    };
  }, [account, dispatch]);

  const cards = useMemo(() => {
    if (activeTab === 'Metacells')
      return (
        <MetacellDashboardCard detailsButtonHandler={detailsButtonHandler} activeTab={activeTab} />
      );
    const enhancerProps = {
      detailsButtonHandler,
      setShowMintConfirmModal,
      setCurrentToken,
      activeTab,
    };
    if (activeTab === 'Enhancers') return <EnhancerDashboardCard {...enhancerProps} />;
    const nanocellProps = { detailsButtonHandler };
    if (activeTab === 'Nanocells') return <NanocellDashboardCard {...nanocellProps} />;
    const scientistProps = {
      detailsButtonHandler,
      setShowMintConfirmModal,
      setCurrentToken,
      activeTab,
    };
    if (activeTab === 'Scientists') return <ScientistDashboardCard {...scientistProps} />;
    if (activeTab === 'Modules') return <ModuleDashboard {...{ detailsButtonHandler }} />;
    if (activeTab === 'Mad Boxes') return <BoxDashboardCard {...{ openBoxButtonHandler }} />;
    return null;
  }, [activeTab, detailsButtonHandler, openBoxButtonHandler]);

  return (
    <>
      <Typography
        variant="pageTitle"
        component="h1"
        sx={{
          justifyContent: tablet ? 'center' : 'flex-start',
          m: '3% 4%',
          width: 'auto',
          height: '58px',
        }}>
        My NFT Collection
      </Typography>
      <Box
        sx={{
          display: 'grid',
          m: '0px 4%',
          grid: desktop ? 'auto auto / 1fr auto' : 'none',
          gap: '15px',
        }}>
        <Box
          sx={{
            gridRow: desktop ? '1 / 3' : 'none',
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '16px',
            backdropFilter: 'blur(4px)',
            p: '41px 20px',
            minWidth: 0,
            position: 'relative',
          }}>
          <MainTabBoard tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <Box
            key={activeTab}
            sx={{
              display: 'grid',
              mt: '30px',
              maxHeight: '800px',
              minHeight: '800px',
              overflow: 'auto',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
            }}>
            {cards ? <>{cards}</> : <EmptyGridMessage node={activeTab} />}
          </Box>
        </Box>
        <Grid
          container
          rowGap="15px"
          sx={{
            order: desktop ? '0' : '-1',
            justifySelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            p: '24px',
            background: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '16px',
            backdropFilter: 'blur(4px)',
            width: desktop ? '480px' : '100%',
          }}>
          <Box
            sx={{
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              background: '#AB6EFF',
              position: 'relative',
              mr: '20px',
            }}>
            <img width="100%" src="images/biometa_token.png" alt="biometa" />
          </Box>
          <Box sx={{ mr: 'auto' }}>
            <Typography
              variant="modalTitle"
              sx={{
                justifyContent: 'flex-start',
                height: '26px',
                mb: '3px',
              }}>
              {`${formatPrice(ethers.utils.formatEther(usersBalance), 4)} BIOMETA`}
            </Typography>
            <Typography variant="altPrice" sx={{ justifyContent: 'flex-start', height: '18px' }}>
              {`${formatPrice(balanceInETH, 4)} ETH | ${formatPrice(balanceInUSD, 2)} USD`}
            </Typography>
          </Box>
          <BuyButton
            buyButtonHandler={() => {
              // setShowBuyConfirmModal(true);
              toast('This Feature Is Coming Soon!', { icon: '⚠️' });
            }}
          />
        </Grid>
        <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(4px)',
            borderRadius: '16px',
          }}>
          <>
            <AltTabBoard
              tabs={sideTabs}
              activeTab={activeSideTab}
              setActiveTab={setActiveSideTab}
              sx={{
                p: '38px 20px 29.5px',
              }}
            />
            <Box
              sx={{
                width: '100%',
                borderBottom: '0.5px solid #00F3FF',
                boxShadow: '0px 0px 4px rgba(9, 235, 246, 0.25)',
              }}
            />
            <Box
              sx={{
                p: '30px 20px',
                maxHeight: '680px',
                minHeight: '680px',
                overflow: 'auto',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
              }}>
              <Box
                sx={{
                  display: 'grid',
                  justifyContent: 'center',
                  rowGap: '16px',
                  columnGap: '20px',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(210px, max-content))',
                  justifySelf: 'center',
                  textAlign: 'center',
                }}>
                {/* (activeSideTab === 'External Tokens' ? externalMock : stakedMock).map((token) => (
                  <ExternalNFTCard key={token.id} token={token} />
                )) */}
                Coming soon
              </Box>
            </Box>
          </>
        </Box>
      </Box>
      {showDetails && activeTab === 'Metacells' && (
        <CellDetailsModal
          open
          detailsButtonHandler={detailsButtonHandler}
          cell={currentToken}
          field
          hideButton
        />
      )}
      <NanocellDetailsModal
        open={showDetails && activeTab === 'Nanocells'}
        detailsButtonHandler={detailsButtonHandler}
        cell={currentToken}
      />
      <ScientistModal
        open={showDetails && activeTab === 'Scientists'}
        detailsButtonHandler={detailsButtonHandler}
        mintButtonHandler={() => {
          setShowDetails(false);
          setShowMintConfirmModal(true);
        }}
        scientist={currentToken}
      />
      <CellModuleDetailsModal
        open={(showDetails && activeTab === 'Modules') || showLatestCreatedModuleModal}
        handleClose={handleClose}
        module={currentToken}
        isNewModule={showLatestCreatedModuleModal}
        loading={
          showLatestCreatedModuleModal
            ? latestCreatedModule === null || Number(latestCreatedModule) !== latestModule.current
            : false
        }
      />
      <BuyConfirmModal
        open={showBuyConfirmModal}
        buyButtonHandler={() => {
          setShowBuyConfirmModal(false);
        }}
        setShowLoadingModal={setShowLoadingModal}
        setLoadingModalMessage={setLoadingModalMessage}
        setMining={setMining}
        tokenBuy
      />
      <MintConfirmModal
        open={showMintConfirmModal}
        setShowMintConfirmModal={setShowMintConfirmModal}
        scientist={currentToken}
        setShowLoadingModal={setShowLoadingModal}
        setLoadingModalMessage={setLoadingModalMessage}
        setMining={setMining}
      />
      <LoadingModal open={showLoadingModal} text={loadingModalMessage} mining={mining} />
      <UnboxModuleBoxModal
        open={showConfirmModal || showLoadingUnboxModal || showErrorModal}
        showConfirmModal={showConfirmModal}
        showLoadingModal={showLoadingUnboxModal}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        message={errorMessage}
        mining={mining}
      />
    </>
  );
};

export default Dashboard;

const userModuleMocked = [
  {
    id: '2506',
    tokenId: '2506',
    onSale: true,
    name: 'Auto Canon',
    abilities: ['Long Range', 'Offensive'],
    describe: 'Big sniper rifle that takes 2-3 module slots.',
    specs: {
      moduleHp: 50,
      platformHp: 120,
      damage: 36,
      ec: 15,
      range: 20,
      angle: 85,
    },
  },
  {
    id: '1506',
    tokenId: '1506',
    onSale: false,
    name: 'Auto Shield',
    abilities: ['Endurance', 'Offensive'],
    describe: 'Legendary shield.',
    specs: {
      moduleHp: 50,
      platformHp: 120,
      damage: 36,
      ec: 15,
      range: 20,
      angle: 85,
    },
  },
  {
    id: '1606',
    tokenId: '1606',
    onSale: false,
    name: 'Auto Shield',
    abilities: ['Endurance', 'Offensive'],
    describe: 'Legendary shield.',
    specs: {
      moduleHp: 50,
      platformHp: 120,
      damage: 36,
      ec: 15,
      range: 20,
      angle: 85,
    },
  },
  {
    id: '1706',
    tokenId: '1706',
    onSale: false,
    name: 'Auto Shield',
    abilities: ['Endurance', 'Offensive'],
    describe: 'Legendary shield.',
    specs: {
      moduleHp: 50,
      platformHp: 120,
      damage: 36,
      ec: 15,
      range: 20,
      angle: 85,
    },
  },
  {
    id: '1806',
    tokenId: '1806',
    onSale: false,
    name: 'Auto Shield',
    abilities: ['Endurance', 'Offensive'],
    describe: 'Legendary shield.',
    specs: {
      moduleHp: 50,
      platformHp: 120,
      damage: 36,
      ec: 15,
      range: 20,
      angle: 85,
    },
  },
];

const madBoxesMocked = [
  {
    id: '535',
  },
  {
    id: '235',
  },
  {
    id: '525',
  },
  {
    id: '555',
  },
];
