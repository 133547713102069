import { styled } from '@mui/material/styles';
import { Button, Slider, TextField } from '@mui/material';

export const SliderWithInputContainer = styled('div')`
  .slider-input__inputs {
    display: flex;
    .slider-input__inputs-connect {
      width: 39px;
      height: 20px;
      border-color: rgba(255, 255, 255, 0.2);
      margin: 0 4px;
    }
  }
`;
export const InputSlider = styled(TextField)`
  width: 110px;
  height: 40px;
  border-radius: 31px;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
  .MuiInputBase-root {
    width: 110px;
    height: 40px;
    border-radius: 31px;
    padding: 12px 2px 12px 14px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .MuiOutlinedInput-input {
    width: 110px;
    height: 40px;
    border-radius: 31px;
    padding: 0 0 0 0;
    font-size: 14px;
    line-height: 16px;
  }
  .MuiInputAdornment-root {
    padding-right: 10px;
    height: 100%;
    margin-left: 2px;
    .MuiTypography-root {
      color: #b9b9b9;
      font-size: 14px;
    }
  }
`;

export const SliderWithStyle = styled(Slider)`
  max-width: 254px;
  margin-top: 16px;
  margin-left: 10px;
  .MuiSlider-rail {
    background: #324149;
    border-radius: 7px;
    height: 4px;
  }
  .MuiSlider-track {
    background: rgba(255, 255, 255, 0.3);
    border: none;
    height: 4px;
  }
  .MuiSlider-thumb {
    background: #b9b9b9;
  }
  @media (max-width: 767px) {
    margin-left: 5px;
    max-width: calc(100% - 10px);
  }
  .slider-input__button {
    margin-top: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const ButtonWithStyle = styled(Button)`
  align-self: center;
  width: 154px;
  height: 36px;
  background: #00abe2;
  opacity: 0.9;
  border-radius: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  left: 25%;
  transform: translateX(-50%);
  &:hover {
    background: #09ebf6;
  }
`;

export const ButtonReset = styled(Button)`
  align-self: center;
  width: 126px;
  height: 36px;
  background: #ca8016;
  opacity: 0.9;
  border-radius: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  left: 25%;
  transform: translateX(-50%);
  &:hover {
    background: #ca9716;
  }
`;
