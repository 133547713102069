import { useDebounce } from '@hooks/common/useDebounce';
import { Grid, Modal, Typography } from '@mui/material';
import { useSelectScientist } from '@providers/ScientistProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ModalCountdownWrapper } from '../MetacellMint/styles';

dayjs.extend(utc);

export const ScientistNotifyModal = () => {
  const { proofs = [], mintingTime, deadline } = useSelectScientist();

  const isWaiting = useDebounce(mintingTime > Date.now(), 0);
  const inWhiteList = useDebounce(proofs.length > 0 && !isWaiting, 500);

  return (
    <Modal
      open={isWaiting || !inWhiteList}
      classes={{
        root: 'backdrop-modal-no-top',
      }}
      sx={{
        zIndex: '1300 !important',
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}>
      <ModalCountdownWrapper>
        <Grid
          container
          direction="column"
          alignItems="center"
          className="modal_login__wrapper"
          sx={{
            maxWidth: '544px',
            background: 'rgba(255, 255, 255, 0.1)',
            hideBackdrop: 'true',
            backdropFilter: 'blur(20px)',
            borderRadius: '14px',
            p: '26px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '&:focus-visible': {
              outline: 'none',
            },
          }}>
          <Typography
            variant="modalTitle"
            sx={{
              mt: '14px',
              mb: '25px',
              height: '29px',
              whiteSpace: 'normal',
              textAlign: 'center',
              color: '#09EBF6',
              fontSize: '1.5rem',
            }}>
            {isWaiting ? (
              <>
                You are whitelisted.
                <br />
                Mint starts at
                {dayjs(mintingTime).utc().format(' h:mm A ')}
                UTC
              </>
            ) : (
              <>
                You are not whitelisted.
                <br />
                Check Discord for info on how to join
              </>
            )}
          </Typography>
        </Grid>
      </ModalCountdownWrapper>
    </Modal>
  );
};
