/* eslint-disable react/jsx-one-expression-per-line */
import { EnhancerCard, EmptyGridMessage } from '@components';
import { Box, Grid, useMediaQuery } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import { LoadingCircle } from './LoadingCircle';
import { useEnhancerCollection } from './hooks/useEnhancerCollection';
import { useEnhancerUserQuery } from '../../hooks/contracts/enhancers/userEnhancerUserQuery';

export function EnhancerDashboardCard({ activeTab }) {
  const { data: enhancerData, isLoading } = useEnhancerUserQuery();

  const mobile = useMediaQuery('(max-width:459px)');
  return (
    <>
      {enhancerData?.length > 0 && (
        <Grid
          sx={{
            zIndex: 1000,
            margin: '25px 35px 0',
            width: 'auto',
            position: 'absolute',
            right: 0,
            top: '70px',
          }}>
          <Grid
            sx={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
              height: '40px',
              marginLeft: 'auto',
            }}>
            {enhancerData?.length} items
          </Grid>
        </Grid>
      )}
      {isLoading ? (
        [<LoadingCircle key="loading" />]
      ) : _isEmpty(enhancerData) ? (
        <EmptyGridMessage node="Metacells" />
      ) : (
        <Box
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'start',
            gap: '15px',
            gridTemplateColumns: `repeat(auto-fit, ${
              activeTab.includes('cells') && !mobile ? '383px' : '248px'
            })`,
          }}>
          {[...enhancerData].map((enhancer) => (
            <EnhancerCard key={enhancer.id} token={enhancer} showUsers thin />
          ))}
        </Box>
      )}
    </>
  );
}
