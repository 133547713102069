import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextFieldStyled = styled(TextField)`
  input {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgb(185, 185, 185);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgb(185, 185, 185);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgb(185, 185, 185);
    }
    &:disabled {
      -webkit-text-fill-color: rgb(185, 185, 185) !important;
    }
  }
`;
