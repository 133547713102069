import { Grid, Typography, Box, LinearProgress, useMediaQuery } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import CustomProgressBar from '@components/CustomProgressBar';

const LabCard = ({ token, index, detailsButtonHandler, dragClickHandler }) => {
  const mobile = useMediaQuery('(max-width:576px)');
  return (
    <Draggable draggableId={`${token.type}-${token.nanoId}`} index={index} isDragDisabled={mobile}>
      {(provided) => (
        <Grid
          onClick={dragClickHandler}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          container
          gap="13px"
          sx={{
            p: '14px',
            background: '#17181C',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor:
              token?.type === 'cell' && token?.evolutionTimestamp <= Date.now()
                ? '#09EBF6'
                : '#202228',
            borderRadius: '14px',
            boxShadow:
              token?.type === 'cell' && token?.evolutionTimestamp <= Date.now()
                ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)'
                : 'none',
            width: '100%',
            maxWidth: '334px',
            flexWrap: 'nowrap',
          }}>
          <Box sx={{ width: '74px', height: '74px' }}>
            <img
              src={
                token.type === 'cell'
                  ? `${process.env.REACT_APP_RESOURCE}/metacell/${Number(token.stage) + 1}.png`
                  : `${process.env.REACT_APP_RESOURCE}/enhancers/${token.enhancerType}.jpg`
              }
              style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '14px' }}
              onError={(e) => {
                e.target.src = `/images/${
                  token.type === 'cell' ? 'blue' : 'enhancerImageMock'
                }.png`;
              }}
              alt="cell"
            />
          </Box>
          {token.type !== 'external' ? (
            <Grid container direction="column" sx={{ width: 'auto', flexGrow: 1 }}>
              <Grid container justifyContent="space-between" sx={{ overflow: 'hidden' }}>
                <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
                  <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
                    Token ID
                  </Typography>
                  <Typography
                    variant="tokenId"
                    sx={{
                      background: 'rgba(255, 255, 255, 0.08)',
                      borderRadius: '4px',
                      height: '19px',
                      p: '0 4px',
                    }}>
                    {`# ${token.id}`}
                  </Typography>
                </Grid>
                {token.type === 'cell' ? (
                  <svg
                    style={{ cursor: 'pointer' }}
                    width="15"
                    height="20"
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={(event) => {
                      event.stopPropagation();
                      detailsButtonHandler(token);
                    }}>
                    <circle cx="1.5" cy="1.5" r="1.5" fill="#B9B9B9" />
                    <circle cx="1.5" cy="6.5" r="1.5" fill="#B9B9B9" />
                    <circle cx="1.5" cy="11.5" r="1.5" fill="#B9B9B9" />
                    <circle cx="7.5" cy="1.5" r="1.5" fill="#B9B9B9" />
                    <circle cx="7.5" cy="6.5" r="1.5" fill="#B9B9B9" />
                    <circle cx="7.5" cy="11.5" r="1.5" fill="#B9B9B9" />
                  </svg>
                ) : null}
              </Grid>
              <Typography
                variant="cardName"
                sx={{ height: '19px', mt: '3px', justifyContent: 'flex-start' }}>
                {token.type === 'cell' ? token.name : `${token.enhancerType} enhancer`}
              </Typography>
              {token.type === 'cell' ? (
                <Box sx={{ width: '100%', mt: '12px' }}>
                  <Grid container gap="4px" sx={{ width: 'auto' }}>
                    <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
                      Level:
                    </Typography>
                    <Typography variant="cardAttribute">{token?.stage}</Typography>
                  </Grid>
                  <CustomProgressBar value={token?.stage} height={4} />
                </Box>
              ) : (
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ mt: '8px', pt: '6px', borderTop: '1px solid rgba(255, 255, 255, 0.08)' }}>
                  <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '18px' }}>
                    Probability:
                  </Typography>
                  <Typography variant="cardAttribute" sx={{ height: '18px' }}>
                    {`${token.probability}%`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              sx={{ width: 'auto', flexGrow: 1, justifyContent: 'center' }}>
              <Grid container justifyContent="space-between" sx={{ overflow: 'hidden' }}>
                <Grid container alignItems="center" gap="15px" sx={{ width: 'auto' }}>
                  <Typography
                    variant="cardName"
                    sx={{
                      height: '19px',
                      justifyContent: 'flex-start',
                      color: '#09ebf6',
                    }}>
                    {token.name}
                  </Typography>
                  <Typography
                    variant="tokenId"
                    sx={{
                      background: 'rgba(255, 255, 255, 0.08)',
                      borderRadius: '4px',
                      height: '27px',
                      p: '0 5px',
                      fontSize: '20px',
                    }}>
                    {`# ${token.id}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Draggable>
  );
};

export default LabCard;
