const SCIENTIST_MAX_LENGTH_PRICE = 15;

const formatLongPrice = (price) => {
  const priceSplit = String(price).split('');
  let updatedPrice = priceSplit;
  if (priceSplit.length > SCIENTIST_MAX_LENGTH_PRICE) {
    const floatingPointIndex = priceSplit.indexOf('.');
    if (floatingPointIndex < SCIENTIST_MAX_LENGTH_PRICE) {
      const restFloatNumber = SCIENTIST_MAX_LENGTH_PRICE - floatingPointIndex;
      updatedPrice = Number(priceSplit.slice(0, SCIENTIST_MAX_LENGTH_PRICE - 1).join(''))
        .toFixed(restFloatNumber)
        .split('');
    } else {
      updatedPrice = String(Number(price).toFixed(0));
    }
  }
  return updatedPrice.join('');
};

export default formatLongPrice;
