import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import Banner from '@components/Banner';
import {
  HeaderWrapper,
  Logo,
  LogoWrapper,
  ChooseCoinWrapper,
  TextMenuStyled,
  LogoContain,
  IconSwitchWrapper,
  ColWrapper,
} from './styles';
import AccountCard from '../AccountCard';
import { Account, Admin } from '../Icons';
import ListMenu from './ListMenu';

const HeaderDesktop = (props) => {
  const { account } = useWeb3React();
  const navigate = useNavigate();

  const onClickLogo = () => {
    navigate('/');
  };

  return (
    <>
      <Banner />
      <HeaderWrapper>
        <ColWrapper className="left-menu">
          <LogoWrapper onClick={onClickLogo}>
            {/* <Logo src="/images/logo.svg" /> */}
            <LogoContain>
              <img src="/images/logo.png" alt="logo" />
            </LogoContain>
          </LogoWrapper>
        </ColWrapper>
        <ColWrapper className="right-menu">
          <ListMenu />
        </ColWrapper>
      </HeaderWrapper>
    </>
  );
};
export default HeaderDesktop;
