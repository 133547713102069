const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const environment = {
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  BLOCK_PER_DAY: process.env.REACT_APP_BLOCK_PER_DAY,
  GG_FORM_URL: process.env.REACT_APP_GG_FORM_URL,
  AVERAGE_BLOCK_TIME: process.env.REACT_APP_AVERAGE_BLOCK_TIME,
  BE_SERVER_URL: process.env.REACT_APP_BE_SERVER_URL,
  BIOMETA_ADDRESS: process.env.REACT_APP_BIOMETA_ADDRESS || ZERO_ADDRESS,
  BUCKET_NAME: process.env.REACT_APP_BUCKET_NAME,
  CHAINLINK_ETH_ADDRESS: process.env.REACT_APP_CHAINLINK_ETH_ADDRESS || ZERO_ADDRESS,
  CHAINLINK_MATIC_ADDRESS: process.env.REACT_APP_CHAINLINK_MATIC_ADDRESS || ZERO_ADDRESS,
  CURRENT_MARKETPLACE_ADDRESS: process.env.REACT_APP_MARKETPLACE || ZERO_ADDRESS,
  CURRENT_NETWORK_BLOCK_EXPLORER: process.env.REACT_APP_CURRENT_NETWORK_BLOCK_EXPLORER,
  CURRENT_NETWORK_DECIMALS: process.env.REACT_APP_CURRENT_NETWORK_DECIMALS,
  CURRENT_NETWORK_ID: process.env.REACT_APP_CURRENT_NETWORK_ID,
  CURRENT_NETWORK_NAME: process.env.REACT_APP_CURRENT_NETWORK_NAME,
  CURRENT_NETWORK_RPC_URL: process.env.REACT_APP_CURRENT_NETWORK_RPC_URL,
  MAIN_NETWORK_RPC_URL: process.env.REACT_APP_MAIN_NETWORK_RPC_URL,
  CURRENT_NETWORK_SYMBOL: process.env.REACT_APP_CURRENT_NETWORK_SYMBOL,
  ETH_MAINNET_MARKETPLACE_ADDRESS:
    process.env.REACT_APP_ETH_MAINNET_MARKETPLACE_ADDRESS || ZERO_ADDRESS,
  ETH_MAINNET_NETWORK_BLOCK_EXPLORER: process.env.REACT_APP_ETH_MAINNET_NETWORK_BLOCK_EXPLORER,
  ETH_MAINNET_NETWORK_DECIMALS: process.env.REACT_APP_ETH_MAINNET_NETWORK_DECIMALS,
  ETH_MAINNET_NETWORK_ID: process.env.REACT_APP_ETH_MAINNET_NETWORK_ID,
  ETH_MAINNET_NETWORK_NAME: process.env.REACT_APP_ETH_MAINNET_NETWORK_NAME,
  ETH_MAINNET_NETWORK_RPC_URL: process.env.REACT_APP_ETH_MAINNET_NETWORK_RPC_URL,
  ETH_MAINNET_NETWORK_SYMBOL: process.env.REACT_APP_ETH_MAINNET_NETWORK_SYMBOL,
  ETH_MARKETPLACE_ADDRESS: process.env.REACT_APP_MARKETPLACE || ZERO_ADDRESS,
  ETH_NETWORK_BLOCK_EXPLORER: process.env.REACT_APP_ETH_NETWORK_BLOCK_EXPLORER,
  ETH_NETWORK_DECIMALS: process.env.REACT_APP_ETH_NETWORK_DECIMALS,
  ETH_NETWORK_ID: process.env.REACT_APP_ETH_NETWORK_ID,
  ETH_NETWORK_NAME: process.env.REACT_APP_ETH_NETWORK_NAME,
  ETH_NETWORK_RPC_URL: process.env.REACT_APP_ETH_NETWORK_RPC_URL,
  ETH_NETWORK_SYMBOL: process.env.REACT_APP_ETH_NETWORK_SYMBOL,
  INFURA_PROJECT_CURRENT: process.env.REACT_APP_INFURA_CURRENT_PROJECT,
  INFURA_PROJECT_ETH: process.env.REACT_APP_INFURA_ETH_PROJECT,
  LABORATORY_ADDRESS: process.env.REACT_APP_LABORATORY_ADDRESS || ZERO_ADDRESS,
  METACELL_ADDRESS: process.env.REACT_APP_METACELL_ADDRESS || ZERO_ADDRESS,
  MODULE_ADDRESS: process.env.REACT_APP_MODULE_ADDRESS || ZERO_ADDRESS,
  MULTICALL_ADDRESS: process.env.REACT_APP_MULTICALL_ADDRESS || ZERO_ADDRESS,
  NANOCELL_ADDRESS: process.env.REACT_APP_NANOCELL_ADDRESS || ZERO_ADDRESS,
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
  PROXY_SCIENTIST_ADDRESS: process.env.REACT_APP_SCIENTIST_ADDRESS || ZERO_ADDRESS,
  RECAPCHA: process.env.REACT_APP_CLIENT_CAPCHA_KEY,
  REPOSITORY_ADDRESS: process.env.REACT_APP_REPOSITORY_ADDRESS || ZERO_ADDRESS,
  SCIENTIST_MARKETPLACE_ADDRESS: process.env.REACT_APP_MARKETPLACE_SCIENTIST || ZERO_ADDRESS,
  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  SPACES_KEY: process.env.REACT_APP_SPACES_KEY,
  SPACES_SECRET: process.env.REACT_APP_SPACES_SECRET,
  WHITELIST_URL: process.env.REACT_APP_WHITELIST_URL,
};
