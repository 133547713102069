import { styled } from '@mui/material/styles';

export const LabContainerStyled = styled('div')`
  z-index: 1;
  & > * {
    z-index: 1;
  }
  /* &::before {
    width: 7.5%;
    height: 136%;
    content: '';
    position: absolute;
    top: -14%;
    right: 32%;
    background: linear-gradient(#09ebf6, #ff81ff);
    filter: blur(170px);
    transform: rotate(132deg) translateY(5%);
    z-index: 0;
    pointer-events: none;
  } */
  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    opacity: 1;
    z-index: 0;
    background-image: url('/images/light.svg');
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  .the-lab__title {
    margin: 3% 4% 0;
    justify-content: flex-start;
    @media (max-width: 1024px) {
      justify-content: center;
    }
  }
  .the-lab__tabs {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(4px);
    margin: 3% 4% 0;
    width: auto;
    border-radius: 16px;
    button {
      font-weight: 700;
      &.active {
        color: ${(props) => props.theme.palette.common.azure};
      }
    }
  }
  .the-lab__dnd-container {
    margin: 2% 4% 0;
    width: auto;
    height: 700px;
    flex-wrap: nowrap;
    @media (max-width: 1300px) {
      flex-wrap: wrap;
    }
    .the-lab__dnd-tabs {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 16px;

      flex: 1 1 373px;
      width: auto;
      flex-wrap: nowrap;
      position: relative;
      &:before {
        content: '';
        backdrop-filter: blur(4px);
        position: absolute;
        border-radius: 16px;
        width: 100%;
        height: 100%;
      }
      &.wraped {
      }
    }
    .the-lab__dnd-wrapper {
      margin: 25px 0;
      height: 100%;
      z-index: 1;
      overflow: auto;
      width: 100%;
      text-align: center;
      position: relative;
      //firefox
      scrollbar-color: rgba(255, 255, 255, 0.25) grey;
      scrollbar-width: thin;
      @media (max-width: 1300px) {
        height: 360px;
      }

      &::-webkit-scrollbar {
        /* display: none; */
        width: 2px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 16px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.25);
        border-radius: 16px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.45);
      }
      .the-lab__dnd-card-list {
        /* min-height: 440px; */
        padding-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 334px));
        grid-gap: 20px;
        justify-content: center;
        align-items: start;
      }
    }
    .the-lab__dnd-main {
      flex: 10 1 70.15%;
      width: auto;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 16px;
      position: relative;
      flex-wrap: nowrap;
      @media (max-width: 1300px) {
        flex-wrap: wrap;
      }
      &:before {
        content: '';
        backdrop-filter: blur(4px);
        position: absolute;
        border-radius: 16px;
        width: 100%;
        height: 100%;
      }
      .dnd-dropping__container {
        flex: 1 1 auto;
        width: auto;
        flex-wrap: nowrap;
        position: relative;
        .dnd-dropping__bracket {
          margin-top: 45px;
          max-height: 503px;
          &-left {
            margin-left: 38px;
          }
          &-right {
            margin-right: 48px;
          }
        }
        &:after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: '';
          height: 100%;
          width: auto;
          z-index: 1;
          border-right: 0.5px solid #00f3ff;
          border-top: none;
          box-shadow: 0px 0px 4px rgba(9, 235, 246, 0.25);
        }
        &.warped {
          &:after {
            height: auto;
            width: 100%;
            border-right: none;
            border-top: 0.5px solid #00f3ff;
          }
        }
        .dnd-dropping__first-slot {
          flex: 1 1 auto;
          width: auto;
          flex-wrap: nowrap;
          position: relative;
          min-width: 334px;
          padding-top: 52px;
          @media (max-width: 370px) {
            min-width: 0;
          }
          .dnd-dropping__warning {
            color: #fd2727;
            position: absolute;
            line-height: 22px;
            top: 20px;
            font-weight: 700;
            text-align: center;
            @media only screen and (max-width: 576px) {
              top: 15px;
              margin: 0px 20px;
            }
            @media only screen and (max-width: 400px) {
              top: 5px;
              margin: 0px 10px;
            }
          }
        }
      }
    }
  }
  .mad_token__icon {
    margin-left: 10px;
  }
`;
