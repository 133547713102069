import React from 'react';
import { nftListFilters } from './constant';
import { useLabCell } from './useLabCellList';

export const LabSlotContext = React.createContext();
export const LabSlotAction = React.createContext();

export const LabSlotContextProvider = ({ children }) => {
  const [activeFilter, _updateActiveFilter] = React.useState(nftListFilters[0]);

  const {
    labCells,
    actionSlot,
    cellOutcome,
    _onDragStart,
    _onDragEnd,
    _onDragReset,
    _updateCellOutcome,
  } = useLabCell();

  const labSlotData = React.useMemo(
    () => ({
      activeFilter,
      labCells,
      actionSlot,
      cellOutcome,
    }),
    [activeFilter, labCells, actionSlot, cellOutcome]
  );

  const _labSlotAction = React.useMemo(
    () => ({
      _updateActiveFilter,
      _onDragStart,
      _onDragEnd,
      _onDragReset,
      _updateCellOutcome,
    }),
    [_onDragStart, _onDragEnd, _onDragReset, _updateCellOutcome]
  );

  return (
    <LabSlotContext.Provider value={labSlotData}>
      <LabSlotAction.Provider value={_labSlotAction}>{children}</LabSlotAction.Provider>
    </LabSlotContext.Provider>
  );
};
