/* eslint-disable react/jsx-one-expression-per-line */
import { DashboardCellCard, EmptyGridMessage } from '@components';
import { Box, Grid, useMediaQuery } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { useInterval } from '../../../hooks/common/useInterval';
import { useMetacellUsersQuery } from '../../../hooks/contracts/metacell/useMetacellQuery';
import { LoadingCircle } from '../LoadingCircle';

const Cards = ({ detailsButtonHandler, activeTab }) => {
  const { data: metacellData, isLoading } = useMetacellUsersQuery();

  const mobile = useMediaQuery('(max-width:459px)');

  return (
    <>
      {metacellData?.length > 0 && (
        <Grid
          sx={{
            zIndex: 1000,
            margin: '25px 35px 0',
            width: 'auto',
            position: 'absolute',
            right: 0,
            top: '70px',
          }}>
          <Grid
            sx={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
              height: '40px',
              marginLeft: 'auto',
            }}>
            {metacellData?.length} items
          </Grid>
        </Grid>
      )}
      {isLoading ? (
        [<LoadingCircle key="loading" />]
      ) : _isEmpty(metacellData) ? (
        <EmptyGridMessage node="Metacells" />
      ) : (
        <Box
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'start',
            gap: '15px',
            gridTemplateColumns: `repeat(auto-fit, ${
              activeTab.includes('cells') && !mobile ? '383px' : '248px'
            })`,
          }}>
          {[...metacellData].map((cell) => (
            <DashboardCellCard
              key={cell.id}
              cell={cell}
              detailsButtonHandler={detailsButtonHandler}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export const MetacellDashboardCard = memo(Cards);
