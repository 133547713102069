import React, { useEffect } from 'react';
import { MARKET_PLACE_ITEM_TYPE, MARKET_PLACE_NANO_CELL_ATTRIBUTES } from '@utils/constants';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SliderWithInput from '@components/SliderWithInput';
import { MarketPlaceFilter, MarketPlaceItemTypeButton, RadioButtonGroup } from './styles';

const MarketPlaceFilters = ({
  isShowMarketFilter = true,
  isOnSale = false,
  activeTab,
  sortPrice,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsParse = Object.fromEntries([...searchParams]);
  const {
    type = MARKET_PLACE_ITEM_TYPE[0].value,
    price = JSON.stringify({ $gte: 0, $lte: 100 }),
  } = searchParamsParse;

  const types =
    activeTab === 'marketplace'
      ? MARKET_PLACE_ITEM_TYPE
      : MARKET_PLACE_ITEM_TYPE.filter((item) => item.value !== MARKET_PLACE_ITEM_TYPE[5].value);

  const listOfMarketType = isOnSale
    ? types.filter((item) => item.value !== MARKET_PLACE_ITEM_TYPE[2].value)
    : types;

  const onChangeFilter = (value, filterType) => {
    switch (filterType) {
      case 'type':
        setSearchParams({ type: value });
        break;
      case 'price':
        setSearchParams({ price: JSON.stringify({ $gte: value[0], $lte: value[1] }), type });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (isOnSale && type === MARKET_PLACE_ITEM_TYPE[2].value) {
      setSearchParams({ type: MARKET_PLACE_ITEM_TYPE[0].value });
    }
  }, [isOnSale]);

  const renderFilterOfType = () => {
    if (type === MARKET_PLACE_ITEM_TYPE[3].value) {
      return (
        <>
          <div>
            <Typography variant="ERC20forLab" justifyContent="flex-start">
              Type
            </Typography>
            <RadioButtonGroup>
              <FormControlLabel value="split" control={<Radio />} label="Split" />
              <FormControlLabel value="stage" control={<Radio />} label="Stage" />
            </RadioButtonGroup>
          </div>
          <div>
            <Typography variant="ERC20forLab" justifyContent="flex-start">
              Skills
            </Typography>
            {MARKET_PLACE_NANO_CELL_ATTRIBUTES.map((att) => (
              <div key={`attribute${att.label}`} className="market-place-item-filter__accordion">
                <Accordion disableGutters>
                  <AccordionSummary
                    expandIcon={<img src="/icons/select-arrow.svg" alt="select-arrow" />}>
                    {att.label}
                  </AccordionSummary>
                  <AccordionDetails>
                    <SliderWithInput
                      handleOnChange={(value) => onChangeFilter({ [att.value]: value }, 'attRange')}
                      isPrice
                      min={0}
                      max={5}
                      defaultValue={[0, 5]}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <MarketPlaceFilter>
      <Typography variant="ERC20forLab" justifyContent="flex-start">
        Items
      </Typography>
      <div className="market-place-item-filter__container">
        {listOfMarketType.map((item) =>
          item.value === 'scientists' || item.value === 'meta-cells' ? (
            <MarketPlaceItemTypeButton
              key={`typeBTN${item.value}`}
              onClick={() => onChangeFilter(item.value, 'type')}
              className={`${item.value === type && 'active'}`}>
              {item.label}
            </MarketPlaceItemTypeButton>
          ) : null
        )}
      </div>
      {isShowMarketFilter && (
        <div className="market-place-item-filter__price">
          <Typography variant="ERC20forLab" justifyContent="flex-start">
            Price
          </Typography>
          <SliderWithInput
            handleOnChange={(value) => onChangeFilter(value, 'price')}
            isPrice
            min={0}
            max={5}
            defaultValue={[0, 5]}
            isShowButton
            suffixInput="ETH"
            type={type}
            sortPrice={sortPrice}
          />
        </div>
      )}
      {isShowMarketFilter && renderFilterOfType()}
    </MarketPlaceFilter>
  );
};

export default MarketPlaceFilters;
