/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material';
import { parseEther } from 'ethers/lib/utils';
import {
  EvolveSvgFillerSlot1,
  EvolveSvgFillerSlot2,
  MergeSvgFillerSlot1,
  MergeSvgFillerSlot2,
  MergeIcon,
  BoostSvgAndIconAppear,
} from './svgFillers';

let first = true;

const PetriDish = ({
  slot1,
  slot2,
  setIsButtonDisabled,
  cantEvolve,
  cantMerge,
  activeLabTab,
  boostPrice,
  usersBalance,
  isMax,
}) => {
  if (first) {
    first = !((!slot1 || cantEvolve) && slot2);
  } else {
    first = !slot2;
  }
  return (
    <Box
      sx={{
        position: 'relative',
        width: '132px',
        height: activeLabTab !== 'Boost Cell' ? '180px' : '132px',
        marginLeft: '0.75rem',
      }}>
      <svg
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="132"
        height={activeLabTab !== 'Boost Cell' ? '180' : '132'}
        viewBox={`0 0 132 ${activeLabTab !== 'Boost Cell' ? '180' : '132'}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="66" cy={activeLabTab !== 'Boost Cell' ? '90' : '66'} r="65" stroke="#B9B9B9" />
        {activeLabTab !== 'Boost Cell' ? (
          <>
            <path
              d="M68 2C68 0.895431 67.1046 4.82821e-08 66 0C64.8954 -4.82821e-08 64 0.89543 64 2L68 2ZM64 23L64 25L68 25L68 23L64 23ZM64 2L64 23L68 23L68 2L64 2Z"
              fill="#B9B9B9"
            />
            <path
              d="M64 178C64 179.105 64.8954 180 66 180C67.1046 180 68 179.105 68 178L64 178ZM68 157L68 155L64 155L64 157L68 157ZM68 178L68 157L64 157L64 178L68 178Z"
              fill="#B9B9B9"
            />
          </>
        ) : null}
      </svg>
      <img
        src="images/PetriDish.png"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        alt="petri"
      />
      {slot1 ? (
        activeLabTab === 'Evolve Cell' && !cantEvolve ? (
          <EvolveSvgFillerSlot1
            slot1={slot1}
            cantEvolve={cantEvolve}
            setIsButtonDisabled={setIsButtonDisabled}
          />
        ) : activeLabTab === 'Mutate Cell' ? (
          <MergeSvgFillerSlot1 cantMerge={cantMerge} />
        ) : boostPrice && usersBalance.gt(parseEther(boostPrice)) ? (
          <BoostSvgAndIconAppear setIsButtonDisabled={setIsButtonDisabled} isMax={isMax} />
        ) : null
      ) : null}
      {slot2 ? (
        activeLabTab === 'Evolve Cell' ? (
          <EvolveSvgFillerSlot2 />
        ) : activeLabTab === 'Mutate Cell' ? (
          <MergeSvgFillerSlot2 cantMerge={cantMerge} />
        ) : null
      ) : null}
      {activeLabTab === 'Mutate Cell' && slot1 && slot2 && !cantMerge ? (
        <MergeIcon setIsButtonDisabled={setIsButtonDisabled} />
      ) : null}
      {slot1 && slot2 && (!cantEvolve || !cantMerge) ? (
        <svg
          id="glowing"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: '0',
          }}
          width="148"
          height="196"
          viewBox="0 0 148 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_5376_20346)">
            <mask id="path-1-inside-1_5376_20346" fill="white">
              <path d="M72 10C72 8.89543 72.8954 8 74 8V8C75.1046 8 76 8.89543 76 10V33H72V10Z" />
            </mask>
            <path
              d="M76 33V37H80V33H76ZM72 33H68V37H72V33ZM72 10V33H80V10H72ZM76 29H72V37H76V29ZM76 33V10H68V33H76ZM74 12C72.8954 12 72 11.1046 72 10H80C80 6.68629 77.3137 4 74 4V12ZM74 4C70.6863 4 68 6.68629 68 10H76C76 11.1046 75.1046 12 74 12V4Z"
              fill="#57F7FF"
              mask="url(#path-1-inside-1_5376_20346)"
            />
          </g>
          <g filter="url(#filter1_d_5376_20346)">
            <mask id="path-3-inside-2_5376_20346" fill="white">
              <path d="M76 186C76 187.105 75.1046 188 74 188C72.8954 188 72 187.105 72 186L72 163L76 163L76 186Z" />
            </mask>
            <path
              d="M72 163L72 159L68 159L68 163L72 163ZM76 163L80 163L80 159L76 159L76 163ZM76 186L76 163L68 163L68 186L76 186ZM72 167L76 167L76 159L72 159L72 167ZM72 163L72 186L80 186L80 163L72 163ZM74 184C75.1046 184 76 184.895 76 186L68 186C68 189.314 70.6863 192 74 192L74 184ZM74 192C77.3137 192 80 189.314 80 186L72 186C72 184.895 72.8954 184 74 184L74 192Z"
              fill="#57F7FF"
              mask="url(#path-3-inside-2_5376_20346)"
            />
          </g>
          <g filter="url(#filter2_d_5376_20346)">
            <circle cx="74" cy="98" r="65.5" stroke="#57F7FF" shapeRendering="crispEdges" />
          </g>
          <defs>
            <filter
              id="filter0_d_5376_20346"
              x="64"
              y="0"
              width="20"
              height="41"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_5376_20346"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0352941 0 0 0 0 0.921569 0 0 0 0 0.964706 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5376_20346"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5376_20346"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_5376_20346"
              x="64"
              y="155"
              width="20"
              height="41"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_5376_20346"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0352941 0 0 0 0 0.921569 0 0 0 0 0.964706 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5376_20346"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5376_20346"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_5376_20346"
              x="0"
              y="24"
              width="148"
              height="148"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_5376_20346"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0352941 0 0 0 0 0.921569 0 0 0 0 0.964706 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5376_20346"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5376_20346"
                result="shape"
              />
            </filter>
          </defs>
          <animate
            href="#glowing"
            attributeName="opacity"
            to="1"
            begin={activeLabTab === 'Evolve Cell' ? (first ? '0.5s' : '1.5s') : '1s'}
            dur="0.1s"
            fill="freeze"
          />
        </svg>
      ) : null}
    </Box>
  );
};

export default PetriDish;
