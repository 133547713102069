export const FIELDS_TABS = [
  { key: 'marketplace', label: 'Marketplace' },
  { key: 'myField', label: 'My Field' },
];

export const PRICE_SORT_OPTIONS = [
  { value: 'price', label: 'Price: Low to High' },
  { value: '-price', label: 'Price: High to Low' },
  { value: 'stage', label: 'Stage: Lowest to Highest' },
  { value: '-stage', label: 'Stage: Highest to Lowest' },
  { value: 'exp', label: 'EXP: Lowest to Highest' },
  { value: '-exp', label: 'EXP: Highest to Lowest' },
  { value: 'createdAt', label: 'Recently Added' },
  { value: '-createdAt', label: 'Oldest' },
];
export const PRICE_SORT_SCIENTIST_OPTIONS = [
  { value: 'price', label: 'Price: Low to High' },
  { value: '-price', label: 'Price: High to Low' },
  { value: 'createdAt', label: 'Recently Added' },
  { value: '-createdAt', label: 'Oldest' },
];
export const PRICE_SORT_METACELL_OPTIONS = [
  { value: 'price', label: 'Price: Low to High' },
  { value: '-price', label: 'Price: High to Low' },
  { value: 'stage', label: 'Stage: Lowest to Highest' },
  { value: '-stage', label: 'Stage: Highest to Lowest' },
  { value: 'createdAt', label: 'Recently Added' },
  { value: '-createdAt', label: 'Oldest' },
];
export const MARKET_PLACE_ITEM_TYPE = [
  { value: 'scientists', label: 'Scientists' },
  { value: 'meta-cells', label: 'Metacells' },
  { value: 'enhancers', label: 'Enhancers' },
  { value: 'nano-cells', label: 'Nanocells' },
  { value: 'modules', label: 'Modules' },
  { value: 'mad-boxes', label: 'Mad Boxes' },
];

export const MARKET_PLACE_ITEM_TYPE_MY_FIELD = ['Not on sale', 'On sale'];

export const MARKET_PLACE_NANO_CELL_ATTRIBUTES = [
  {
    value: 'endurance',
    label: 'Endurance',
  },
  {
    value: 'ai',
    label: 'AI',
  },
  {
    value: 'speed',
    label: 'Speed',
  },
  {
    value: 'energy',
    label: 'Energy',
  },
  {
    value: 'attack',
    label: 'Attack',
  },
  {
    value: 'isCarrier',
    label: 'Is Carrier',
  },
];

export const MARKET_PLACE_ITEM_LIMIT = 12;
export const NFT_ITEM_LIMIT = 12;
export const MODULE_ITEM_LIMIT = 12;

export const HEX_MAX_EVOLUTION_TIME =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const MARKET_PLACE_ITEM_TITLE = {
  scientists: 'Mad Metascientist',
  'meta-cells': 'Metacell',
  enhancers: 'Enhancers',
  'nano-cells': 'Nanocell',
  modules: 'Module',
};

// This one will need to update if these events name changed
export const MARKET_PLACE_TYPE_EVENT = {
  'nano-cells': {
    added: 'NanoCellAddedToMarketPlace',
    removed: 'NanoCellRemovedFromMarketPlace',
    editPrice: 'NanoCellPriceUpdated',
    sold: 'NanoCellSold',
  },
  'meta-cells': {
    added: 'MetaCellAddedToMarketplace',
    removed: 'MetaCellRemovedFromMarketPlace',
    editPrice: 'MetaCellPriceUpdated',
    sold: 'MetaCellSold',
  },
  scientists: {
    added: 'ScientistAddedToMarketplace',
    removed: 'ScientistRemovedFromMarketPlace',
    editPrice: 'ScientistPriceUpdated',
    sold: 'ScientistSold',
  },
  enhancers: {
    added: 'ModuleAddedToMarketPlace',
    removed: 'ModuleRemovedFromMarketPlace',
    editPrice: 'ModulePriceUpdated',
    sold: 'ModuleSold',
  },
  modules: {
    added: 'ModuleAddedToMarketPlace',
    removed: 'ModuleRemovedFromMarketPlace',
    editPrice: 'ModulePriceUpdated',
    sold: 'ModuleSold',
  },
  lab: {
    evolve: 'NewEvolutionCompleted',
    contract: 'LABORATORY',
    NewMetaCellCreated: 'NewMetaCellCreated',
  },
};

export const MAX_UINT256_VALUE = '0xffffffffffffffffffffffffffffffff';
export const MAD_METACELL_MAINNET = '0x323b35f900a767647AE2F20d1B62Ed05A9b6D03e';
export const MAD_SCIENTIST_MAINNET = '0xA37c76Dc35174d210862eb1a5aE70E52340af430';
export const MULTI_CALL_MAINNET = '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441';
