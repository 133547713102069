import { Grid } from '@mui/material';
import AltTab from './AltTab';

const AltTabBoard = ({ tabs, activeTab, setActiveTab, sx }) => {
  return (
    <Grid container rowGap="15px" columnGap="20px" sx={{ width: 'auto', ...sx }}>
      {tabs.map((tab, index) => (
        <AltTab
          key={`altTab${tab}`}
          tab={tab}
          active={tab === activeTab}
          setActiveTab={setActiveTab}
        />
      ))}
    </Grid>
  );
};

export default AltTabBoard;
