import { Modal, Box } from '@mui/material';
import UnboxLoading from '@components/Modals/UnboxModuleBoxModal/UnboxLoading';
import LabError from '@components/Modals/LabModal/LabError';
import LoadingModal from '@components/Modals/LoadingModal';

const UnboxModuleBoxModal = ({
  open,
  showConfirmModal,
  showLoadingModal,
  showErrorModal,
  setShowErrorModal,
  message,
  mining,
}) => {
  return (
    <Modal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      onClose={() => {
        setShowErrorModal(false);
      }}>
      <Box>
        {showConfirmModal ? (
          <LoadingModal open={showConfirmModal} text={message} mining={mining} />
        ) : showLoadingModal ? (
          <UnboxLoading />
        ) : showErrorModal ? (
          <LabError setShowErrorModal={setShowErrorModal} message={message} />
        ) : null}
      </Box>
    </Modal>
  );
};

export default UnboxModuleBoxModal;
