import React, { useState, useEffect } from 'react';

const useGetScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(null);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  return screenWidth;
};

export default useGetScreenWidth;
