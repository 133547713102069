import { DashboardNanocellCard } from '@components';
import { LoadingCircle } from './LoadingCircle';

export function NanocellDashboardCard({ nanoCellsLoading, usersNanocells, detailsButtonHandler }) {
  return nanoCellsLoading
    ? [<LoadingCircle key="loading" />]
    : usersNanocells.map((cell) => (
        <DashboardNanocellCard
          key={cell.id}
          cell={cell}
          detailsButtonHandler={detailsButtonHandler}
        />
      ));
}
