const { default: useGetInfoScientist } = require('@hooks/contracts/scientist/useGetInfoScientist');
const { createContext, useContext } = require('react');

const ScientistContext = createContext();

export const ScientistContextWrapper = ({ children }) => {
  const info = useGetInfoScientist();

  return <ScientistContext.Provider value={{ ...info }}>{children}</ScientistContext.Provider>;
};

export const useScientistContext = () => {
  const context = useContext(ScientistContext);
  return context;
};
