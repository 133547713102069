import { useBrowserActiveTab, useTicker } from '@hooks';
import { Box, Grid, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import formatLongPrice from '@utils/formatLongPrice';
import { useWeb3React } from '@web3-react/core';
import { useState, useMemo, memo } from 'react';
import CustomProgressBar from '@components/CustomProgressBar';
import MetacellName from '@shared/data/MetacellNameData.json';
import BuyButton from '../Buttons/BuyButton';
import DetailsButton from '../Buttons/DetailsButton';
import RemoveButton from '../Buttons/RemoveButton';
import SellButton from '../Buttons/SellButton';
import { CssPriceTypography } from './cssProperties';

const MetacellCard = ({
  detailsButtonHandler,
  sellButtonHandler,
  removeButtonHandler,
  editPriceButtonHandler,
  buyButtonHandler,
  setCurrentToken,
  cell,
  showUsers,
}) => {
  const { account } = useWeb3React();
  const [readyForEvolution, setReadyForEvolution] = useState(false);
  const browserTabActive = useBrowserActiveTab(setReadyForEvolution);
  const isMobileTablet = useMediaQuery('(max-width:900px)');
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();
  const {
    ref: priceRef,
    params: {
      isOverflown: isPriceOverflown,
      offset: priceOffset,
      transitionTime: priceTransitionTime,
    },
  } = useTicker();
  const cellImgRender = useMemo(() => {
    if (cell?.type === 'meta-cells') {
      return `${process.env.REACT_APP_RESOURCE}/metacell/${Number(cell?.stage) + 1}.png`;
    }
    return `${process.env.REACT_APP_RESOURCE}/enhancers/${cell.enhancerType}.jpg`;
  }, [cell]);
  return (
    <Grid
      key={browserTabActive}
      container
      direction="column"
      alignItems="center"
      sx={{
        width: isMobileTablet ? '400px !important' : '279px',
        background: '#17181C',
        border: `1px solid ${readyForEvolution ? '#09EBF6' : '#202228'}`,
        boxShadow: readyForEvolution ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)' : 'none',
        borderRadius: '14px',
        p: '19px 19px 24px',
      }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ background: '#000', borderRadius: '14px', overflow: 'hidden' }}>
        <Box sx={{ width: '100%', height: isMobileTablet ? '189px' : '129px' }}>
          <img
            src={cellImgRender}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            alt="cell"
          />
        </Box>
        {/* {showUsers ? (
          <Grid container justifyContent="center" sx={{ width: 'auto', flexGrow: 1 }}>
            <EvolutionClock
              id={cell.id}
              timestamp={cell.evolutionTimestamp}
              setReadyForEvolution={setReadyForEvolution}
            />
          </Grid>
        ) : null} */}
      </Grid>
      <Grid container justifyContent="space-between" wrap="nowrap" gap="5px" mt="20px">
        <Grid container alignItems="center" gap="8px">
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${cell.id}`}
          </Typography>
        </Grid>
        <Grid container wrap="nowrap" alignItems="center" gap="7px" sx={{ width: 'auto' }}>
          {account === cell?.owner && cell?.onSale ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '1px solid #09EBF6',
                borderRadius: '100px',
                width: '19px',
                height: '19px',
                cursor: 'pointer',
                ':hover': { background: '#045b61' },
              }}
              onClick={() => {
                setCurrentToken(cell);
                editPriceButtonHandler();
              }}>
              <img src="/images/Pencil.png" width="8.55px" height="8.55px" alt="edit" />
            </Grid>
          ) : null}
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            PRICE
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        gap="5px"
        alignItems="center"
        sx={{ mt: '3px', overflow: 'hidden' }}>
        <Typography
          ref={nameRef}
          variant="cardName"
          sx={{
            height: '19px',
            maxWidth: '60%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box
            sx={{
              '&:hover': isNameOverflown && {
                transform: `translateX(-${nameOffset}px)`,
                transition: `transform ${nameTransitionTime}s`,
              },
            }}>
            {/* {cell.name} */}
            {`Metacell #${cell?.id}`}
          </Box>
        </Typography>
        {cell?.price && (
          <Grid
            container
            justifyContent="flex-end"
            gap="4px"
            flexWrap="nowrap"
            sx={{ maxWidth: '40%' }}>
            <Typography ref={priceRef} variant="cardPrice" sx={CssPriceTypography}>
              <Box
                sx={{
                  '&:hover': isPriceOverflown && {
                    transform: `translateX(-${priceOffset}px)`,
                    transition: `transform ${priceTransitionTime}s`,
                  },
                }}>
                {formatLongPrice(cell.price)}
              </Box>
            </Typography>
            <Typography
              variant="cardPrice"
              sx={{
                height: '18px',
              }}>
              ETH
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box sx={{ width: '100%', mt: '21px' }}>
        <Grid container gap="4px" sx={{ width: 'auto' }}>
          <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
            Level:
          </Typography>
          <Typography variant="cardAttribute">{Number(cell.stage)}</Typography>
        </Grid>
        <CustomProgressBar value={Number(cell?.stage)} height={4} />
      </Box>
      <Box
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          m: '20px 0 19px',
          width: '100%',
        }}
      />
      <Grid container justifyContent="space-between">
        <DetailsButton
          detailsButtonHandler={() => {
            setCurrentToken(cell);
            detailsButtonHandler(cell);
          }}
        />
        {showUsers || account === cell?.owner ? (
          cell.onSale ? (
            <RemoveButton
              removeButtonHandler={() => {
                setCurrentToken(cell);
                removeButtonHandler();
              }}
              sx={{ width: '96px' }}
            />
          ) : (
            <SellButton
              sellButtonHandler={() => {
                setCurrentToken(cell);
                sellButtonHandler();
              }}
            />
          )
        ) : (
          <BuyButton
            buyButtonHandler={() => {
              setCurrentToken(cell);
              buyButtonHandler();
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo(MetacellCard);
