import React from 'react';

function ScrollTopIcon({ width, height }) {
  return (
    <svg
      width={width || '44'}
      height={height || '44'}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_5979_4351)">
        <circle cx="22" cy="22" r="22" fill="black" />
      </g>
      <path
        d="M34 34L23.9825 10H20.0175L10 34H12.9562L22.007 12.3024L31.0718 34H34Z"
        fill="url(#paint0_linear_5979_4351)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5979_4351"
          x1="10"
          y1="21.9927"
          x2="34"
          y2="21.9927"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#00ABE2" />
          <stop offset="0.12" stopColor="#45A0EA" />
          <stop offset="0.26" stopColor="#9493F3" />
          <stop offset="0.38" stopColor="#CE89F9" />
          <stop offset="0.47" stopColor="#F183FD" />
          <stop offset="0.51" stopColor="#FF81FF" />
          <stop offset="0.56" stopColor="#F982FE" />
          <stop offset="0.62" stopColor="#E785FC" />
          <stop offset="0.69" stopColor="#CA8AF9" />
          <stop offset="0.77" stopColor="#A190F4" />
          <stop offset="0.85" stopColor="#6D99EE" />
          <stop offset="0.94" stopColor="#2FA3E7" />
          <stop offset="0.99" stopColor="#00ABE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default ScrollTopIcon;
