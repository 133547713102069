import { useEffect, useRef } from 'react';

export const EvolveSvgFillerSlot1 = ({ slot1, cantEvolve, setIsButtonDisabled }) => {
  const dnaAnimate = useRef(null);
  useEffect(() => {
    if (slot1 && !cantEvolve && dnaAnimate && dnaAnimate.current) {
      const animate = dnaAnimate.current;

      const disableButtonSetter = () => {
        setIsButtonDisabled(false);
      };

      animate.addEventListener('endEvent', disableButtonSetter);

      return () => {
        console.log('remove event');
        animate.removeEventListener('endEvent', disableButtonSetter);
        setIsButtonDisabled(true);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
        }}
        width="4"
        height="25"
        viewBox="0 0 4 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="up-stroke"
          d="M2 2V25"
          stroke="#57F7FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray="23"
          strokeDashoffset="23"
        />
        <animate
          href="#up-stroke"
          attributeName="stroke-dashoffset"
          to="0"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          top: '24px',
        }}
        width="66"
        height="132"
        viewBox="0 0 66 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="left-half-circle"
          d="M66 1.00001C30.1015 1 1.00002 30.1015 1.00001 66C1.00001 101.899 30.1015 131 66 131"
          stroke="#57F7FF"
          strokeDasharray="205"
          strokeDashoffset="205"
        />
        <animate
          href="#left-half-circle"
          attributeName="stroke-dashoffset"
          to="0"
          begin="0.5s"
          dur="1s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          top: '24px',
        }}
        width="66"
        height="132"
        viewBox="0 0 66 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="right-half-circle"
          d="M-1.23186e-05 1C35.8985 1 65 30.1015 65 66C65 101.899 35.8985 131 -9.53674e-07 131"
          stroke="#57F7FF"
          strokeDasharray="205"
          strokeDashoffset="205"
        />
        <animate
          href="#right-half-circle"
          attributeName="stroke-dashoffset"
          to="0"
          begin="0.5s"
          dur="1s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          id="gradient-circle"
          cx="34"
          cy="34"
          r="34"
          fill="url(#paint0_linear_5376_20328)"
          opacity="0"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5376_20328"
            x1="6"
            y1="40"
            x2="65.8923"
            y2="40"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#09EBF6" />
            <stop offset="0.520833" stopColor="#7613FF" />
            <stop offset="1" stopColor="#BD0CFF" />
          </linearGradient>
        </defs>
        <animate
          href="#gradient-circle"
          attributeName="opacity"
          to="1"
          begin="2s"
          dur="0.3s"
          fill="freeze"
        />
      </svg>
      <svg
        id="dna"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="63"
        height="54"
        viewBox="0 0 63 54"
        fill="none"
        opacity="0"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.0118 32.2862C22.4487 29.4103 17.9189 23.0382 22.4466 16.5118C29.4489 12.7621 35.3587 18.1045 37.8224 20.8688"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M43.0526 50.6875C40.9555 48.3346 37.2743 43.0987 41.2034 37.5588C47.1572 34.2907 52.0174 38.6395 54.0331 40.9012"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M25.0067 32.2851C27.8028 35.4224 34.0837 41.1802 41.1985 37.5567C45.6137 30.9041 40.5049 23.8834 37.8173 20.8678"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M8.79983 12.2548C11.1299 14.8693 16.3793 19.6538 22.4456 16.5119C26.2621 10.8458 22.0201 4.98156 19.7803 2.46855"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path d="M13.4434 16.8242L23.5963 7.77553" stroke="white" />
        <path d="M20.6836 24.9463L30.8365 15.8976" stroke="white" />
        <path d="M32.4453 38.1455L42.5982 29.0968" stroke="white" />
        <path d="M39.6816 46.2676L49.8345 37.2189" stroke="white" />
        <path d="M23.2871 29.9121L35.4706 19.0537" stroke="white" />
        <path d="M27.8086 34.9893L39.9921 24.1308" stroke="white" />
        <path d="M41.5195 50.459L53.703 39.6006" stroke="white" />
        <path d="M10.2402 13.7393L21.4084 3.7857" stroke="white" />
        <animate
          ref={dnaAnimate}
          href="#dna"
          attributeName="opacity"
          to="1"
          begin="2s"
          dur="0.3s"
          fill="freeze"
        />
      </svg>
    </>
  );
};

export const EvolveSvgFillerSlot2 = () => (
  <>
    <svg
      style={{
        position: 'absolute',
        left: '50%',
        bottom: '0',
        transform: 'translate(-50%) rotate(180deg)',
      }}
      width="4"
      height="25"
      viewBox="0 0 4 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        id="down-stroke"
        d="M2 2V25"
        stroke="#57F7FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeDasharray="23"
        strokeDashoffset="23"
      />
      <animate
        href="#down-stroke"
        attributeName="stroke-dashoffset"
        to="0"
        dur="0.5s"
        fill="freeze"
      />
    </svg>
  </>
);

export const MergeSvgFillerSlot1 = ({ slot1, slot2, cantMerge, setIsButtonDisabled }) => {
  const filler = !cantMerge ? (
    <>
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
        }}
        width="4"
        height="25"
        viewBox="0 0 4 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="up-stroke"
          d="M2 2V25"
          stroke="#57F7FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray="23"
          strokeDashoffset="23"
        />
        <animate
          href="#up-stroke"
          attributeName="stroke-dashoffset"
          to="0"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          top: '24px',
        }}
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="top-left-quarter-circle"
          d="M66 1.00001C30.1015 1 1.00002 30.1015 1.00001 66"
          stroke="#57F7FF"
          strokeDasharray="103"
          strokeDashoffset="103"
        />
        <animate
          href="#top-left-quarter-circle"
          attributeName="stroke-dashoffset"
          to="0"
          begin="0.5s"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          top: '24px',
        }}
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="top-right-quarter-circle"
          d="M-1.23186e-05 1C35.8985 1 65 30.1015 65 66"
          stroke="#57F7FF"
          strokeDasharray="103"
          strokeDashoffset="103"
        />
        <animate
          href="#top-right-quarter-circle"
          attributeName="stroke-dashoffset"
          to="0"
          begin="0.5s"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
    </>
  ) : null;

  return filler;
};

export const MergeSvgFillerSlot2 = ({ slot1, slot2, cantMerge, setIsButtonDisabled }) => {
  const filler = !cantMerge ? (
    <>
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          bottom: '0',
          transform: 'translate(-50%) rotate(180deg)',
        }}
        width="4"
        height="25"
        viewBox="0 0 4 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="down-stroke"
          d="M2 2V25"
          stroke="#57F7FF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray="23"
          strokeDashoffset="23"
        />
        <animate
          href="#down-stroke"
          attributeName="stroke-dashoffset"
          to="0"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          bottom: '24px',
        }}
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="bottom-left-quarter-circle"
          d="M66 65C30.1015 65 1.00002 35.8985 1.00001 0"
          stroke="#57F7FF"
          strokeDasharray="103"
          strokeDashoffset="103"
        />
        <animate
          href="#bottom-left-quarter-circle"
          attributeName="stroke-dashoffset"
          to="0"
          begin="0.5s"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          bottom: '24px',
        }}
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          id="bottom-right-quarter-circle"
          d="M-1.23186e-05 65C35.8985 65 65 35.8985 65 0"
          stroke="#57F7FF"
          strokeDasharray="103"
          strokeDashoffset="103"
        />
        <animate
          href="#bottom-right-quarter-circle"
          attributeName="stroke-dashoffset"
          to="0"
          begin="0.5s"
          dur="0.5s"
          fill="freeze"
        />
      </svg>
    </>
  ) : null;
  return filler;
};

export const MergeIcon = ({ setIsButtonDisabled }) => {
  const cellsAnimate = useRef(null);

  useEffect(() => {
    if (cellsAnimate && cellsAnimate.current) {
      const animate = cellsAnimate.current;

      const disableButtonSetter = () => {
        setIsButtonDisabled(false);
      };

      animate.addEventListener('endEvent', disableButtonSetter);

      return () => {
        animate.removeEventListener('endEvent', disableButtonSetter);
        setIsButtonDisabled(true);
      };
    }
  }, []);

  return (
    <>
      <svg
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          id="gradient-circle"
          cx="34"
          cy="34"
          r="34"
          fill="url(#paint0_linear_5376_20328)"
          opacity="0"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5376_20328"
            x1="6"
            y1="40"
            x2="65.8923"
            y2="40"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#09EBF6" />
            <stop offset="0.520833" stopColor="#7613FF" />
            <stop offset="1" stopColor="#BD0CFF" />
          </linearGradient>
        </defs>
        <animate
          href="#gradient-circle"
          attributeName="opacity"
          to="1"
          begin="1.5s"
          dur="0.3s"
          fill="freeze"
        />
      </svg>
      <svg
        id="cells"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="43"
        height="44"
        opacity="0"
        viewBox="0 0 43 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="26.6093" cy="16.3007" r="15.3007" stroke="white" strokeWidth="2" />
        <circle r="2" transform="matrix(1 0 0 -1 26.5 9.5)" stroke="white" />
        <circle r="1.62618" transform="matrix(1 0 0 -1 30.8606 23.3885)" stroke="white" />
        <circle r="3.75237" transform="matrix(1 0 0 -1 21.6469 19.8443)" stroke="white" />
        <path
          d="M37.2406 14.8836C37.2406 13.7093 36.2887 12.7574 35.1145 12.7574C33.9402 12.7574 32.9883 13.7093 32.9883 14.8836C32.9883 16.0578 33.9402 17.0098 35.1145 17.0098C36.2887 17.0098 37.2406 16.0578 37.2406 14.8836Z"
          fill="white"
        />
        <circle r="1.41746" transform="matrix(1 0 0 -1 28.7378 15.5923)" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7777 39.8188C16.7958 44.8007 8.71843 44.8007 3.73647 39.8188C-1.24549 34.8368 -1.24549 26.7594 3.73647 21.7775C8.71843 16.7955 16.7958 16.7955 21.7777 21.7775C26.7597 26.7594 26.7597 34.8368 21.7777 39.8188ZM8.89112 23.6787C10.6874 23.6787 12.1435 25.1348 12.1435 26.931C12.1435 28.7273 10.6874 30.1834 8.89112 30.1834C7.09489 30.1834 5.63876 28.7273 5.63876 26.931C5.63876 25.1348 7.09489 23.6787 8.89112 23.6787ZM8.89112 22.6787C11.2396 22.6787 13.1435 24.5825 13.1435 26.931C13.1435 29.2795 11.2396 31.1834 8.89112 31.1834C6.54261 31.1834 4.63876 29.2795 4.63876 26.931C4.63876 24.5825 6.54261 22.6787 8.89112 22.6787ZM6.47313 34.7275C6.47313 34.1055 5.96892 33.6013 5.34695 33.6013C4.72497 33.6013 4.22076 34.1055 4.22076 34.7275C4.22076 35.3495 4.72497 35.8537 5.34695 35.8537C5.96892 35.8537 6.47313 35.3495 6.47313 34.7275ZM7.47313 34.7275C7.47313 33.5532 6.5212 32.6013 5.34695 32.6013C4.17269 32.6013 3.22076 33.5532 3.22076 34.7275C3.22076 35.9017 4.17269 36.8537 5.34695 36.8537C6.52121 36.8537 7.47313 35.9017 7.47313 34.7275ZM17.3945 33.3101C17.3945 32.1358 16.4426 31.1839 15.2684 31.1839C14.0941 31.1839 13.1422 32.1358 13.1422 33.3101C13.1422 34.4843 14.0941 35.4362 15.2684 35.4362C16.4426 35.4362 17.3945 34.4843 17.3945 33.3101ZM11.7282 36.8536C12.511 36.8536 13.1456 37.4882 13.1456 38.2711C13.1456 39.0539 12.511 39.6885 11.7282 39.6885C10.9453 39.6885 10.3107 39.0539 10.3107 38.2711C10.3107 37.4882 10.9453 36.8536 11.7282 36.8536Z"
          fill="white"
        />
        <animate
          ref={cellsAnimate}
          href="#cells"
          attributeName="opacity"
          to="1"
          begin="1.5s"
          dur="0.3s"
          fill="freeze"
        />
      </svg>
    </>
  );
};

export const BoostSvgAndIconAppear = ({ setIsButtonDisabled, isMax }) => {
  const arrowAnimate = useRef(null);

  useEffect(() => {
    if (arrowAnimate && arrowAnimate.current) {
      const animate = arrowAnimate.current;

      const disableButtonSetter = () => {
        setIsButtonDisabled(false);
      };

      animate.addEventListener('endEvent', disableButtonSetter);

      return () => {
        animate.removeEventListener('endEvent', disableButtonSetter);
        setIsButtonDisabled(true);
      };
    }
  }, []);

  return (
    <>
      <svg
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        id="circle"
        width="132"
        height="132"
        opacity="0"
        viewBox="0 0 132 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="66" cy="66" r="65.5" stroke="#57F7FF" />
        <animate href="#circle" attributeName="opacity" to="1" dur="0.5s" fill="freeze" />
      </svg>
      <svg
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="68"
        height="68"
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          id="gradient-circle"
          cx="34"
          cy="34"
          r="34"
          fill="url(#paint0_linear_5376_20328)"
          opacity="0"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5376_20328"
            x1="6"
            y1="40"
            x2="65.8923"
            y2="40"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#09EBF6" />
            <stop offset="0.520833" stopColor="#7613FF" />
            <stop offset="1" stopColor="#BD0CFF" />
          </linearGradient>
        </defs>
        <animate
          href="#gradient-circle"
          attributeName="opacity"
          to="1"
          begin="0.5s"
          dur="0.3s"
          fill="freeze"
        />
      </svg>
      <svg
        id="arrow"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        width="43"
        height="24"
        opacity="0"
        viewBox="0 0 43 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5147 1.39344L42.0607 10.9394C42.6464 11.5252 42.6464 12.4749 42.0607 13.0607L32.5147 22.6066C31.9289 23.1924 30.9792 23.1924 30.3934 22.6066C29.8076 22.0209 29.8076 21.0711 30.3934 20.4853L37.3787 13.5H9C8.17157 13.5 7.5 12.8285 7.5 12C7.5 11.1716 8.17157 10.5 9 10.5H37.3787L30.3934 3.51476C29.8076 2.92897 29.8076 1.97923 30.3934 1.39344C30.9792 0.807655 31.9289 0.807655 32.5147 1.39344ZM17 5.00004C17.5523 5.00004 18 5.44776 18 6.00004C18 6.55233 17.5523 7.00004 17 7.00004H1C0.447716 7.00004 0 6.55233 0 6.00004C0 5.44776 0.447716 5.00004 1 5.00004H17ZM23 18C23 17.4478 22.5523 17 22 17H6C5.44772 17 5 17.4478 5 18C5 18.5523 5.44772 19 6 19H22C22.5523 19 23 18.5523 23 18Z"
          fill="white"
        />
        <animate
          ref={arrowAnimate}
          href="#arrow"
          attributeName="opacity"
          to="1"
          begin="0.5s"
          dur="0.3s"
          fill="freeze"
        />
      </svg>
      {isMax ? (
        <svg
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          width="148"
          height="148"
          viewBox="0 0 148 148"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_5393_8166)">
            <circle cx="74" cy="74" r="65.5" stroke="#57F7FF" shapeRendering="crispEdges" />
          </g>
          <defs>
            <filter
              id="filter0_d_5393_8166"
              x="0"
              y="0"
              width="148"
              height="148"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="4"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_5393_8166"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0352941 0 0 0 0 0.921569 0 0 0 0 0.964706 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5393_8166"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5393_8166"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      ) : null}
    </>
  );
};
