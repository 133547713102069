import { Modal, Grid, Typography, TextField } from '@mui/material';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import getWalletName from '@utils/getWalletName';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { ethers } from 'ethers';
import * as Yup from 'yup';
import ConfirmButton from '@components/Buttons/ConfirmButton';
import { useContracts } from '../../../providers';

const AddAdminModal = ({
  open,
  setShowAddAdminModal,
  setShowLoadingModal,
  setLoadingModalMessage,
  setMining,
  activeContractTab,
  accessRights,
}) => {
  const connectedWalletStatus = JSON.parse(
    localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
  );
  const walletText = getWalletName(connectedWalletStatus);
  const contracts = useContracts();

  const addAdminButtonHandler = async (values) => {
    const { address } = values;
    try {
      setShowAddAdminModal(!open);
      setShowLoadingModal(true);
      setLoadingModalMessage(`Please confirm transaction in your ${walletText}}`);
      const tx = await contracts[activeContractTab].addAdmin(address);
      setLoadingModalMessage('Waiting for transaction to be mined');
      setMining(true);
      await tx.wait();
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoadingModal(false);
      setMining(false);
    }
  };

  return (
    <Modal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      onClose={() => {
        setShowAddAdminModal(!open);
      }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          maxWidth: '544px',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(20px)',
          borderRadius: '14px',
          p: '26px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
          onClick={() => {
            setShowAddAdminModal(!open);
          }}>
          <path
            opacity="0.4"
            d="M0.260185 11.7415C0.374895 11.8563 0.510462 11.9319 0.666886 11.9685C0.82331 12.0102 0.977126 12.0102 1.12834 11.9685C1.28476 11.9319 1.42033 11.8589 1.53504 11.7493L6.00093 7.2727L10.4746 11.7493C10.6415 11.9163 10.8501 11.9998 11.1003 11.9998C11.3506 12.005 11.5644 11.9189 11.7417 11.7415C11.9137 11.5641 11.9998 11.3502 11.9998 11.0998C11.9998 10.8493 11.9164 10.6406 11.7495 10.4737L7.27578 5.99702L11.7495 1.52821C11.9164 1.36125 11.9998 1.15255 11.9998 0.902109C12.005 0.646451 11.919 0.432532 11.7417 0.260354C11.5644 0.0881761 11.3506 0.00208701 11.1003 0.00208701C10.8501 0.00208701 10.6415 0.0855673 10.4746 0.252528L6.00093 4.72916L1.53504 0.252528C1.42033 0.14296 1.28476 0.0699148 1.12834 0.0333921C0.977126 -0.00834803 0.82331 -0.0109568 0.666886 0.0255659C0.510462 0.0620885 0.374895 0.140351 0.260185 0.260354C0.150688 0.37514 0.0750833 0.510795 0.0333704 0.667321C-0.00312847 0.823847 -0.00312847 0.980372 0.0333704 1.1369C0.0750833 1.28821 0.150688 1.41864 0.260185 1.52821L4.72608 5.99702L0.260185 10.4737C0.150688 10.5832 0.0750833 10.7163 0.0333704 10.8728C-0.00834259 11.0241 -0.0109497 11.178 0.0255492 11.3345C0.0672622 11.4963 0.145474 11.6319 0.260185 11.7415Z"
            fill="white"
          />
        </svg>
        <Typography variant="modalTitle" sx={{ mt: '14px', height: '29px' }}>
          Add admin
        </Typography>
        <Typography
          variant="modalText"
          sx={{
            mt: '43px',
            color: '#E6E6E6',
            whiteSpace: 'normal',
            textAlign: 'center',
          }}>
          Please enter address
        </Typography>
        <Formik
          initialValues={{
            address: '',
          }}
          validationSchema={Yup.object({
            address: Yup.string()
              .required('Required')
              .test('isAddress', 'Not an address', (value) => {
                return ethers.utils.isAddress(value);
              })
              .test('renderAdminPanel', 'Already admin', (value) => {
                return accessRights[activeContractTab]
                  ? !accessRights[activeContractTab].includes(value)
                  : true;
              }),
          })}
          onSubmit={async (values) => {
            await addAdminButtonHandler(values);
          }}>
          {(props) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isValid,
              dirty,
              handleChange,
            } = props;
            return (
              <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
                <div>
                  <TextField
                    variant="outlined"
                    id="address"
                    placeholder="Enter address"
                    value={values.address}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.address && touched.address}
                    InputProps={{
                      sx: {
                        height: '40px',
                        width: '200px',
                        color: values.address ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                        '&:hover': {
                          color: values.address ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                        },
                        '&:focus-within': {
                          color: '#fff',
                        },
                      },
                    }}
                    sx={{
                      mt: '30px',
                      '& fieldset': {
                        borderRadius: '31px',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                      },
                      '& .MuiOutlinedInput-input': {
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                        },
                        '&:disabled': {
                          WebkitTextFillColor: '#4A4A4A',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(185, 185, 185, 0.5)',
                      },
                      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(185, 185, 185, 0.5)',
                      },
                      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  />
                  {errors.address && touched.address && (
                    <div
                      style={{
                        color: '#FD2727',
                        position: 'absolute',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}>
                      {errors.address}
                    </div>
                  )}
                </div>
                <ConfirmButton
                  disabled={!isValid || !dirty}
                  confirmButtonHandler={handleSubmit}
                  sx={{ mt: '58px', mb: '5%' }}
                />
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Modal>
  );
};

export default AddAdminModal;
