import { BigNumber, ethers } from 'ethers';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toString from 'lodash/toString';
import { numberToString } from '.';

const showTokenName = (type, tokenId) => {
  if (type === 'scientists') return `Mad Scientist ${tokenId}`;
  if (type === 'modules') return `Module ${tokenId}`;
  return `#${tokenId}`;
};

export default (token = {}, type = '') => {
  if (!_isEmpty(token) && type !== 'enhancers') {
    const tokenId = token?.tokenId ? Number(token?.tokenId) : token?.id;

    let priceBigNumber;

    if (token?.priceHex) {
      priceBigNumber = BigNumber.from(token?.priceHex);
    } else {
      priceBigNumber = token?.price
        ? ethers.utils.parseUnits(numberToString(`${token?.price}`))
        : undefined;
    }

    const transformCell = {
      ...token,
      id: tokenId,
      price: _toString(token?.price),
      priceBigNumber,
      name: showTokenName(type, tokenId),
      owner: token?.tokenId ? _get(token, 'sellerAddress', '') : token?.owner,
      type,
      abilities: token?.abilities || {
        rarity: 'rare',
        type: 'Combat',
      },
      describe: token?.describe || 'Legendary shield.',
      specs: token?.specs || {
        moduleHp: 50,
        platformHp: 120,
        damage: 36,
        ec: 15,
        range: 20,
        angle: 85,
      },
    };
    return transformCell;
  }
  return token;
};
