import { Button, Divider, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  ButtonReset,
  ButtonWithStyle,
  InputSlider,
  SliderWithInputContainer,
  SliderWithStyle,
} from './style';

const SliderWithInput = ({
  handleOnChange,
  defaultValue = [],
  step,
  suffixInput,
  isShowButton = false,
  max = 5,
  min = 0,
  type,
  sortPrice,
}) => {
  const [sliderMaxValue, setSliderMaxValue] = useState(defaultValue[1] || 5);
  const [sliderMinValue, setSliderMinValue] = useState(defaultValue[0] || 0);
  const arrayVal = [sliderMinValue || 0, sliderMaxValue || 10];

  const suffixConfig = {
    customInput: (props) => (
      <InputSlider
        {...(suffixInput && {
          InputProps: {
            endAdornment: <InputAdornment position="end">ETH</InputAdornment>,
          },
        })}
        {...props}
      />
    ),
  };

  const handleChange = (event, newValue = []) => {
    const [minValue, maxValue] = newValue;
    setSliderMinValue(minValue >= maxValue && minValue !== min ? minValue - 0.01 : minValue);
    setSliderMaxValue(minValue >= maxValue && maxValue !== max ? maxValue + 0.01 : maxValue);
  };

  const onSubmitButton = () => {
    if (handleOnChange) {
      const arrayVal = [sliderMinValue || 0, sliderMaxValue || 10];
      handleOnChange(arrayVal);
    }
  };
  const onResetButton = () => {
    const resetVal = [0, 5];
    handleOnChange(resetVal);
    setSliderMinValue(0);
    setSliderMaxValue(5);
  };

  useEffect(() => {
    if (type) {
      setSliderMaxValue(defaultValue[1] || 5);
      setSliderMinValue(defaultValue[0] || 0);
    }
  }, [type, sortPrice]);

  return (
    <SliderWithInputContainer className="slider-input__container">
      <div className="slider-input__inputs">
        <NumberFormat
          value={sliderMinValue}
          allowNegative={false}
          onValueChange={(values) => setSliderMinValue(values.floatValue)}
          onBlur={() => {
            sliderMinValue < min && setSliderMaxValue(min);
            sliderMinValue >= setSliderMaxValue && setSliderMaxValue(setSliderMaxValue - 0.01);
          }}
          min={min}
          {...suffixConfig}
        />
        <Divider orientation="horizontal" className="slider-input__inputs-connect" />
        <NumberFormat
          value={sliderMaxValue}
          allowNegative={false}
          onValueChange={(values) => setSliderMaxValue(values.floatValue)}
          onBlur={() => {
            sliderMaxValue > max && setSliderMaxValue(max);
            sliderMaxValue <= sliderMinValue && setSliderMaxValue(sliderMinValue + 0.01);
          }}
          max={max}
          {...suffixConfig}
        />
      </div>
      <SliderWithStyle
        onChange={handleChange}
        step={0.01}
        min={min}
        max={max}
        value={arrayVal}
        valueLabelDisplay="auto"
        disableSwap
      />
      {isShowButton && (
        <div className="slider-input__button">
          <ButtonWithStyle onClick={onSubmitButton}>CONFIRM</ButtonWithStyle>
          <ButtonReset onClick={onResetButton}>RESET</ButtonReset>
        </div>
      )}
    </SliderWithInputContainer>
  );
};

export default SliderWithInput;
