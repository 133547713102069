/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConfirmSwitchNetworkModal,
  ErrorModal,
  GlobalLoadingModal,
  Header,
  InfoModal,
  LoginModal,
  MetacellLeaderBoard,
  NeonLightButton,
} from '@components';
import Analytics from '@components/Analytics';
import ChangeWalletModal from '@components/Modals/ChangeWalletModal';
import useConnectors from '@hooks/wallets/useConnectors';

import {
  closeChangeWalletModal,
  closeErrorModal,
  closeLoginModal,
  openChangeWalletModal,
  openErrorModal,
  openLoginModal,
  openSwitchNetworkModal,
  setErrorMessage,
} from '@providers/ModalsProvider';
import WhitelistModal from '@components/Modals/WhitelistModal';
import { connectorByConstructorName, connectorsByName, supportedChainIds } from '@utils/connectors';
import { Outlet, useLocation } from 'react-router-dom';
import useAuth from '@hooks/users/useAuth';
import { useConnectWalletOnPageLoad } from './hooks/wallets/useConnectWalletOnPageLoad';
import { ITEM_NAME_STORAGE } from './shared';
import theme from './shared/theme';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const disappearMenuPath = ['/scientist-mint', '/metacell-mint'];

function App() {
  const { account, chainId } = useWeb3React();
  const [, setShowSignIn] = useState(false);
  const [, setShowUserSettings] = useState(false);
  const [showWLModal, setShowWLModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const dispatch = useDispatch();
  const isAuth = useAuth();

  const {
    loginModalStatus,
    changeWalletModalStatus,
    errorModalStatus,
    errorMessage,
    switchNetWorkStatus,
    connector: switchNetWorkConnector,
    connectorName: switchNetWorkConnectorName,
    loadingModalStatus,
    loadingMessage,
  } = useSelector((state) => state.modals);
  const location = useLocation();

  const { onConnectMetamaskWallet, onConnectWallet, disconnect, onConnectBitkeep } = useConnectors(
    (msg) => {
      dispatch(setErrorMessage(msg));
      dispatch(openErrorModal());
    },
    (status) => {
      // eslint-disable-next-line no-unused-expressions
      status ? dispatch(openChangeWalletModal()) : dispatch(closeChangeWalletModal());
    }
  );

  const hasConnector = useMemo(() => {
    return localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
      ? Object.values(
          JSON.parse(localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus))
        ).includes(true)
      : false;
  }, []);

  const injected = new InjectedConnector({
    supportedChainIds,
  });

  const [showAccountPopover, setShowAccountPopover] = useState(false);
  const accountAnchorEl = useRef(null);

  const onAccountClick = (event) => {
    if (account) {
      setShowAccountPopover(true);
      accountAnchorEl.current = event.currentTarget;
    } else {
      dispatch(openLoginModal());
    }
  };

  const onLogoutClick = () => {
    disconnect().then(() => {
      setShowAccountPopover(false);
    });
  };

  const onHandleDisconnectWallet = () => {
    dispatch(closeChangeWalletModal());
    return disconnect();
  };

  const backgroundImage = useMemo(() => {
    let path = '';
    if (location.pathname.includes('/the-field')) {
      path = '/images/TheFieldBackground.webp';
    } else if (location.pathname.includes('/lab')) {
      path = '/images/TheLabBackground.webp';
    } else path = '/images/MyCollectionBackground.webp';

    return path;
  }, [location.pathname]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!account && (!hasConnector || !window.ethereum?.selectedAddress)) {
  //       if (!switchNetWorkStatus) dispatch(openLoginModal());
  //     }
  //   }, 500);
  // }, [account, dispatch, hasConnector, switchNetWorkStatus]);

  useEffect(() => {
    if (switchNetWorkStatus) dispatch(closeLoginModal());
  }, [dispatch, switchNetWorkStatus]);

  useEffect(() => {
    if (isAuth === false) {
      disconnect();
      dispatch(closeLoginModal());
      setShowWLModal(true);
      localStorage.clear();
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (account && !supportedChainIds.includes(chainId)) {
      setTimeout(() => {
        if (localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)) {
          const connectedWalletStatus = JSON.parse(
            localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
          );
          const entries = Object.entries(connectedWalletStatus);
          let connectorName = '';
          entries.forEach((item) => {
            if (item[1]) connectorName = item[0];
          });
          const currConnector = connectorByConstructorName[connectorName].connector;
          const currConnectorName = connectorByConstructorName[connectorName].connectorName;
          dispatch(
            openSwitchNetworkModal({ connector: currConnector, connectorName: currConnectorName })
          );
        }
      }, 300);
    }
  }, [account, chainId, dispatch]);

  useConnectWalletOnPageLoad();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Analytics /> */}
      <Toaster
        toastOptions={{
          className: '',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            maxWidth: '60%',
          },
        }}
      />
      <ConfirmSwitchNetworkModal
        open={switchNetWorkStatus}
        connector={switchNetWorkConnector}
        connectorName={switchNetWorkConnectorName}
      />
      <WhitelistModal
        open={showWLModal}
        onClose={() => {
          setShowWLModal(false);
        }}
      />
      <Box
        // id="widget"
        sx={{
          background:
            location.pathname === '/lab'
              ? 'transparent'
              : `${
                  location.pathname !== '/dashboard' && location.pathname !== '/the-field'
                    ? 'linear-gradient(180deg, rgba(12, 12, 14, 0) 0%, rgba(12, 12, 14, 0.48) 49.87%, rgba(12, 12, 14, 0.8) 100%), '
                    : ''
                } url("${backgroundImage}") center / cover no-repeat`,
          minHeight: '100vh',
          pb: disappearMenuPath.includes(location.pathname) ? '0' : '20px',
        }}>
        {location.pathname === '/lab' && (
          <>
            <video
              autoPlay
              muted
              loop
              playsInline
              style={{
                position: 'fixed',
                right: 0,
                minWidth: '100%',
                bottom: 0,
                height: '100vh',
                zIndex: -1,
              }}>
              <source
                src={`${process.env.REACT_APP_RESOURCE}/videos/the-lab-bg.mp4`}
                type="video/mp4"
              />
            </video>
          </>
        )}
        {!disappearMenuPath.includes(location.pathname) ? (
          <Header
            path={location.pathname}
            onAccountClick={onAccountClick}
            showAccountPopover={showAccountPopover}
            setShowAccountPopover={setShowAccountPopover}
            onLogoutClick={onLogoutClick}
            setShowUserSettings={setShowUserSettings}
            setShowSignIn={setShowSignIn}
            setShowInfoModal={setShowInfoModal}
          />
        ) : null}
        {!account ? (
          <>
            {disappearMenuPath.includes(location.pathname) ? (
              <>
                <Outlet />
                <LoginModal
                  open={loginModalStatus}
                  onCloseClick={() => dispatch(closeLoginModal())}
                  injected={injected}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    marginLeft: '50vw',
                    marginTop: '45vh',
                    transform: 'translate(-50%, -50%)',
                    top: 0,
                    left: 0,
                  }}>
                  <NeonLightButton handleClick={() => dispatch(openLoginModal())} />
                </div>

                <LoginModal
                  open={loginModalStatus}
                  onCloseClick={() => dispatch(closeLoginModal())}
                  injected={injected}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Outlet />
            {/* <FeedbackForms /> */}
            <MetacellLeaderBoard />
          </>
        )}
        {/* <UserSettingsModal
            open={showUserSettings}
            setShowUserSettings={setShowUserSettings}
            account={account}
          />
          <EmailConfirmModal
            open={showEmailConfirm}
            setShowEmailConfirm={setShowEmailConfirm}
            account={account}
          /> */}
        {/* {disappearMenuPath.includes(location.pathname) ? null : (
            <NetworkSwitchModal
              open={
                Boolean(window.ethereum) &&
                Boolean(window.ethereum.selectedAddress) &&
                !supportedChainIds.includes(chainId)
              }
            />
          )} */}
        <InfoModal
          open={showInfoModal}
          setShowModal={setShowInfoModal}
          textContent={{
            title: 'Error!',
            message:
              'Cannot connect to game server. You can try to reconnect in your account pop-up.',
          }}
        />
        {false && (
          <ChangeWalletModal
            open={changeWalletModalStatus}
            onCloseClick={() => dispatch(closeChangeWalletModal())}
            connectMetamask={onConnectMetamaskWallet}
            onConnectBitkeep={onConnectBitkeep}
            connectCoinbase={() => {
              onConnectWallet(connectorsByName.WalletLink, 'WalletLink');
            }}
            connectWalletConnect={() => {
              onConnectWallet(connectorsByName.WalletConnect, 'WalletConnect');
            }}
            disConnectWallet={onHandleDisconnectWallet}
          />
        )}
        <ErrorModal
          open={errorModalStatus}
          setShowErrorModal={() => dispatch(closeErrorModal())}
          message={errorMessage}
        />
        <GlobalLoadingModal open={loadingModalStatus} text={loadingMessage} />
      </Box>
    </ThemeProvider>
  );
}

export default App;
