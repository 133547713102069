import { LabTabStyled } from './styles';

const LabTab = ({ tabName, active, setActiveLabTab }) => {
  return (
    <LabTabStyled
      variant="cardPrice"
      className={`${active && 'active'}`}
      onClick={() => {
        setActiveLabTab(tabName);
      }}>
      {tabName}
    </LabTabStyled>
  );
};

export default LabTab;
