import { Button, ClickAwayListener, Snackbar, useMediaQuery } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { openLoginModal } from '@providers/ModalsProvider';
import useConnectors from '@hooks/wallets/useConnectors';
import useGetWalletIcon from '@hooks/wallets/useGetWalletIcon';
import { useWeb3React } from '@web3-react/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppStore } from '../../store';
import MenuItem from './MenuItem';
import { ConsoleIconWrapper, IconAdmin, IconWallet, ListMenuWrapper } from './styles';

const options = [
  'My Collection',
  'The Field',
  'The Lab',
  // 'Scientist Mint', 'Metacell Mint'
];
const paths = {
  'My Collection': '/dashboard',
  'The Field': '/the-field',
  'The Lab': '/lab',
  'Scientist Mint': '/scientist-mint',
  'Metacell Mint': '/metacell-mint',
};
const urlImage = {
  'My Collection': '/icons/collection-icon.svg',
  'The Field': '/icons/field.svg',
  'The Lab': '/icons/lab-icon.svg',
  'Scientist Mint': '/icons/scientist.svg',
  'Metacell Mint': '/icons/cell-icon.svg',
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ListMenu = ({ isTablet }) => {
  const { account, connector } = useWeb3React();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [copyAlert, setCopyAlert] = useState(false);
  const { renderAdminPanel } = useAppStore();
  const { disconnect } = useConnectors();
  const { walletIcon } = useGetWalletIcon();
  const mobile = useMediaQuery('(max-width:480px)');

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const slicedAccount = (account) => {
    let slicedAcc = '';
    if (account) {
      const arr = account.split('');
      slicedAcc = arr.slice(0, 5).concat('....', arr.slice(-4)).join('');
    }
    return slicedAcc;
  };

  const handleCopyAddress = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setCopyAlert(true);
      setOpen(false);
      return navigator.clipboard.writeText(account);
    }
    return console.log('The Clipboard API is not available.');
  };

  const handleCloseCopyAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopyAlert(false);
  };

  const handleDisconnect = () => {
    setOpen(false);
    disconnect();
  };

  return (
    <ListMenuWrapper istablet={isTablet}>
      {options.map((item, index) => (
        <MenuItem key={`${item}+menuItem`} src={urlImage[item]} title={item} path={paths[item]} />
      ))}
      <ConsoleIconWrapper>
        {renderAdminPanel ? (
          <IconAdmin to="/admin-panel">
            <img src="/icons/admin.svg" alt="admin" width="20px" height="20px" />
          </IconAdmin>
        ) : (
          ''
        )}
        {account ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{ position: 'relative', width: '24px' }}>
              <Button
                variant="contained"
                startIcon={walletIcon}
                className="wallet__button"
                onClick={handleClick}>
                {isTablet ? slicedAccount(account) : null}
              </Button>
              {open ? (
                <div className="popup__wrapper">
                  <div className="popup__content">
                    <Button
                      className="popup__button"
                      startIcon={walletIcon}
                      onClick={handleCopyAddress}>
                      {slicedAccount(account)}
                      <img
                        src="/icons/copy-icon.svg"
                        alt="copy"
                        style={{ width: '16px', height: '16px' }}
                      />
                    </Button>

                    <Button className="popup__button" onClick={handleDisconnect}>
                      Disconnect wallet
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        ) : (
          <IconWallet
            onClick={() => {
              dispatch(openLoginModal());
            }}>
            <img
              src="/icons/wallet-icon.svg"
              alt="wallet"
              style={{ width: '24px', height: '24px', marginRight: account ? '18px' : '0px' }}
            />
          </IconWallet>
        )}
        {/* {account ? (
          <IconUser to="/user-settings">
            <img src="/icons/user-icon.svg" width="22px" height="22px" alt="user icon" />
          </IconUser>
        ) : null} */}
      </ConsoleIconWrapper>
      <Snackbar
        open={copyAlert}
        autoHideDuration={3000}
        onClose={handleCloseCopyAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert
          onClose={handleCloseCopyAlert}
          sx={{
            backgroundColor: 'rgba(171, 110, 255, 0.5)',
            marginTop: mobile ? '10px' : '30px',
            marginRight: mobile ? '30px' : '0px',
          }}>
          Address copied!
        </Alert>
      </Snackbar>
    </ListMenuWrapper>
  );
};
export default ListMenu;
