import { useLocation } from 'react-router-dom';
import { MenuText, MenuItemWrapper } from './styles.js';

const MenuItem = ({ path = '', src = '', title = '' }) => {
  const location = useLocation();
  return (
    <MenuItemWrapper
      to={path}
      pathactive={location.pathname === path || location.pathname?.includes(path) ? 'true' : ''}>
      <img src={src} alt="icon-menu" />
      <MenuText>{title}</MenuText>
    </MenuItemWrapper>
  );
};

export default MenuItem;
