import { delay } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  closeChangeWalletModal,
  openChangeWalletModal,
  openErrorModal,
  setErrorMessage,
} from '../../providers/ModalsProvider';
import { ITEM_NAME_STORAGE } from '../../shared';
import { connectorsByName } from '../../utils/connectors';
import useConnectors, { defaultConnectedWalletStatus } from './useConnectors';
import { useLocalStorage } from '../common/useLocalStorage';

const isMetaMaskUnlocked = async () => {
  let isUnlocked = false;
  if (window.ethereum?.providers) {
    const metamaskProvider = window.ethereum.providers.find((provider) => provider?.isMetaMask);
    isUnlocked = await metamaskProvider._metamask.isUnlocked();
  } else if (window.ethereum?.isMetaMask) {
    isUnlocked = await window.ethereum._metamask.isUnlocked();
  } else isUnlocked = false;
  return isUnlocked;
};

export const useConnectWalletOnPageLoad = () => {
  const [connectedStatus] = useLocalStorage(
    ITEM_NAME_STORAGE.connectedWalletStatus,
    defaultConnectedWalletStatus
  );
  const dispatch = useDispatch();

  const { onConnectMetamaskWallet, onConnectWallet, disconnect, onConnectBitkeep } = useConnectors(
    (msg) => {
      dispatch(setErrorMessage(msg));
      dispatch(openErrorModal());
    },
    (status) => {
      // eslint-disable-next-line no-unused-expressions
      status ? dispatch(openChangeWalletModal()) : dispatch(closeChangeWalletModal());
    }
  );

  React.useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      const isConnected = Object.values(connectedStatus).includes(true);
      if (!isConnected) return;
      if (window.ethereum) {
        const isUnlocked = await isMetaMaskUnlocked();

        if (connectedStatus?.Injected && isUnlocked) {
          onConnectMetamaskWallet();
          return;
        }
      }
      if (connectedStatus?.WalletConnect) {
        onConnectWallet(connectorsByName.WalletConnect, 'WalletConnect');
        return;
      }
      if (connectedStatus?.WalletLink) {
        onConnectWallet(connectorsByName.WalletLink, 'WalletLink');
      }
      if (connectedStatus.Bitkeep) {
        onConnectBitkeep();
      }
    };

    connectWalletOnPageLoad();

    window.addEventListener('storage', (e) => {
      delay(connectWalletOnPageLoad, 1000);
    });

    return () => {
      window.removeEventListener('storage', () => {});
    };
  }, [connectedStatus]);
};
