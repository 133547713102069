import { Grid, Typography, Box } from '@mui/material';
import MintButton from '@components/Buttons/MintButton';
import { useTicker } from '@hooks';
import _includes from 'lodash/includes';

const DashboardScientistCard = ({
  token,
  detailsButtonHandler,
  setShowMintConfirmModal,
  setCurrentToken,
}) => {
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();

  return (
    <Box
      sx={{
        background: '#17181C',
        border: '1px solid #202228',
        borderRadius: '14px',
        p: '19px 19px 24px',
      }}>
      <Box sx={{ width: '208px', height: '122px' }}>
        <img
          src={token.image}
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: '14px',
            boxShadow: 'inset 0px 0px 50px 15px rgba(0, 0, 0, 0.3)',
          }}
          alt="token"
          onError={(e) => {
            e.target.src = `${process.env.REACT_APP_SCIENTIST_IMAGES_URL}/116.gif`;
          }}
        />
      </Box>
      <Grid container mt="20px" justifyContent="space-between" sx={{ overflow: 'hidden' }}>
        <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${token.id}`}
          </Typography>
        </Grid>
        <svg
          style={{ cursor: 'pointer' }}
          width="15"
          height="20"
          viewBox="0 0 9 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            detailsButtonHandler(token);
          }}>
          <circle cx="1.5" cy="1.5" r="1.5" fill="#B9B9B9" />
          <circle cx="1.5" cy="6.5" r="1.5" fill="#B9B9B9" />
          <circle cx="1.5" cy="11.5" r="1.5" fill="#B9B9B9" />
          <circle cx="7.5" cy="1.5" r="1.5" fill="#B9B9B9" />
          <circle cx="7.5" cy="6.5" r="1.5" fill="#B9B9B9" />
          <circle cx="7.5" cy="11.5" r="1.5" fill="#B9B9B9" />
        </svg>
      </Grid>
      <Typography
        ref={nameRef}
        variant="cardName"
        sx={{
          height: '19px',
          mt: '3px',
          maxWidth: '70%',
          justifyContent: 'flex-start',
          overflow: 'hidden',
          position: 'relative',
          '&:after': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            background: 'linear-gradient(to right, transparent 190px, #17181C)',
          },
          '&:hover:after': {
            width: 0,
            height: 0,
          },
        }}>
        <Box
          sx={{
            '&:hover': isNameOverflown && {
              transform: `translateX(-${nameOffset}px)`,
              transition: `transform ${nameTransitionTime}s`,
            },
          }}>
          {token.name}
        </Box>
      </Typography>
      <Box
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          m: '20px 0 19px',
          width: '100%',
        }}
      />
      <MintButton
        disabled={!token?.mintable}
        mintButtonHandler={() => {
          setCurrentToken(token);
          setShowMintConfirmModal(true);
        }}
      />
    </Box>
  );
};

export default DashboardScientistCard;
