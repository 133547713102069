export default (token) => {
  const scientificField = Number(token.scientificField);
  const personalityDiorders = Number(token.personalityDisorders);
  const obedienceToAuthority = Number(token.psychologicalForce.obedienceToAuthority);
  const obedienceToConventions = Number(token.psychologicalForce.obedienceToConventions);
  const tendencyTowardViolence = Number(token.psychologicalForce.tendencyTowardViolence);
  const vice = Number(token.vice);
  const magnitudeOfStress = Number(token.magnitudeOfStress);

  return {
    scientificField,
    personalityDiorders,
    obedienceToAuthority,
    obedienceToConventions,
    tendencyTowardViolence,
    vice,
    magnitudeOfStress,
  };
};
