import { nanoid } from 'nanoid';
import { ethers } from 'ethers';

export default async (token, getAmount) => {
  const enhancerTypes = { 1: 'Stage', 2: 'Split' };
  const id = token.id.toNumber();
  const enhancerType = enhancerTypes[token.typeId];
  const probability = Number(token.probability);
  const price = ethers.utils.formatEther(token.basePrice);
  const amount = getAmount(token.id);
  const { name } = token;
  const { tokenAddress } = token;

  return {
    id,
    type: 'enhancers',
    enhancerType,
    probability,
    price,
    amount: amount.toNumber(),
    nanoId: nanoid(),
    name,
    tokenAddress,
  };
};
