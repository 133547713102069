import { DashboardModuleCard } from '@components';
import transformModule from '@utils/transformModule';
import { LoadingCircle } from './LoadingCircle';

export function ModuleDashboard({ modulesLoading, usersModules, detailsButtonHandler }) {
  return modulesLoading
    ? [<LoadingCircle key="loading" />]
    : usersModules.map((cellModule) => (
        <DashboardModuleCard
          key={cellModule.id}
          module={transformModule(cellModule)}
          detailsButtonHandler={detailsButtonHandler}
        />
      ));
}
