/* eslint-disable react/jsx-one-expression-per-line */
import CustomProgressBar from '@components/CustomProgressBar';
import { environment } from '@environment';
import { useTicker, useWrapDetect } from '@hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, Modal, Tooltip, Typography, useMediaQuery } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import MetacellName from '@shared/data/MetacellNameData.json';
import { useWeb3React } from '@web3-react/core';
import numeral from 'numeral';
import { useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import FieldCellDetail from './FieldCellDetail';
import FieldCellDetailWithoutPrice from './FieldCellDetailWithoutPrice';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 'fit-content',
    marginBottom: '14px !important',
  },
}));

const CellDetailsModal = ({
  open,
  setCurrentToken,
  detailsButtonHandler,
  buyButtonHandler,
  sellButtonHandler,
  removeButtonHandler,
  editPriceButtonHandler,
  cell,
  showUsers,
  field,
  hideButton,
  handleClose,
}) => {
  const { account, library } = useWeb3React();
  const ref = useRef(null);
  const isWraped = useWrapDetect(open, ref, 405);
  const buttonRef = useRef(null);
  const isButtonWraped = useWrapDetect(open, buttonRef, 36);
  const isTablet = useMediaQuery('(max-width:931px)');
  const classes = useStyles();
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();
  const {
    ref: priceRef,
    params: {
      isOverflown: isPriceOverflown,
      offset: priceOffset,
      transitionTime: priceTransitionTime,
    },
  } = useTicker();

  const nextTime = useMemo(() => {
    if (cell?.evolutionTimestamp) {
      return cell.evolutionTimestamp;
    }
    if (cell?.nextEvolutionBlock) {
      const nextBlock = Number(cell?.nextEvolutionBlock) - library.blockNumber;

      if (nextBlock > 0) {
        return Date.now() + nextBlock * 1000 * Number(environment.AVERAGE_BLOCK_TIME);
      }
      return 0;
    }
    return 0;
  }, [cell?.evolutionTimestamp, cell?.nextEvolutionBlock, library.blockNumber]);
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return 'READY';
    }
    return (
      <span>
        {days > 0 ? (days < 10 ? `0${days} ` : `${days} `) : '00 '}:
        {hours > 0 ? (hours < 10 ? ` 0${hours} ` : ` ${hours} `) : ' 00 '}:
        {minutes > 0 ? (minutes < 10 ? ` 0${minutes}` : ` ${minutes} `) : ' 00 '}:
        {seconds > 0 ? (seconds < 10 ? ` 0${seconds}` : ` ${seconds}`) : ' 00'}
      </span>
    );
  };
  const slicedAccount = (account) => {
    let slicedAcc = '';
    if (account) {
      const arr = account.split('');
      slicedAcc = arr.slice(0, 5).concat('....', arr.slice(-4)).join('');
    }
    return slicedAcc;
  };
  const walletAddress = useMemo(() => {
    let addrs;
    if (cell?.buyerAddress) {
      addrs = cell?.buyerAddress;
    } else if (cell?.sellerAddress) {
      addrs = cell?.sellerAddress;
    } else {
      addrs = cell?.owner;
    }

    if (`${addrs}`.toLowerCase() === account.toLowerCase()) {
      return 'you';
    }
    return slicedAccount(addrs);
  }, [account, cell?.buyerAddress, cell?.owner, cell?.sellerAddress]);
  const information = (
    <Grid
      container
      gap="8px"
      alignItems="center"
      sx={{
        height: '31px',
        borderRadius: '7px',
        padding: '4px 9px',
        width: 'fit-content',
      }}>
      <Typography
        variant="modalAttribute"
        sx={{
          height: '21px',
          justifyContent: 'flex-start',
          width: 'fit-content',
          position: 'relative',
          '@media screen and (max-width: 576px)': {
            fontSize: '12px',
          },
        }}>
        <Box>{MetacellName[cell?.id]?.slice(14, 43)}</Box>
      </Typography>
    </Grid>
  );
  return (
    <Modal
      disablePortal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'hidden',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::-webkit-scrollbar': { display: 'none' },
        '@media only screen and (max-width: 931px)': { margin: '20px' },
      }}
      onClose={handleClose || detailsButtonHandler}>
      <Grid
        ref={ref}
        container
        direction="column"
        sx={{
          maxWidth: '918px',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(20px)',
          borderRadius: '14px',
          p: '26px',
          position: isWraped ? 'static' : 'absolute',
          top: '50%',
          left: '50%',
          transform: isWraped ? 'none' : 'translate(-50%, -50%)',
          '&:focus-visible': {
            outline: 'none',
          },
        }}>
        <div
          role="button"
          tabIndex={0}
          width="20"
          height="20"
          style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
          onClick={handleClose || detailsButtonHandler}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.4"
              d="M0.260185 11.7415C0.374895 11.8563 0.510462 11.9319 0.666886 11.9685C0.82331 12.0102 0.977126 12.0102 1.12834 11.9685C1.28476 11.9319 1.42033 11.8589 1.53504 11.7493L6.00093 7.2727L10.4746 11.7493C10.6415 11.9163 10.8501 11.9998 11.1003 11.9998C11.3506 12.005 11.5644 11.9189 11.7417 11.7415C11.9137 11.5641 11.9998 11.3502 11.9998 11.0998C11.9998 10.8493 11.9164 10.6406 11.7495 10.4737L7.27578 5.99702L11.7495 1.52821C11.9164 1.36125 11.9998 1.15255 11.9998 0.902109C12.005 0.646451 11.919 0.432532 11.7417 0.260354C11.5644 0.0881761 11.3506 0.00208701 11.1003 0.00208701C10.8501 0.00208701 10.6415 0.0855673 10.4746 0.252528L6.00093 4.72916L1.53504 0.252528C1.42033 0.14296 1.28476 0.0699148 1.12834 0.0333921C0.977126 -0.00834803 0.82331 -0.0109568 0.666886 0.0255659C0.510462 0.0620885 0.374895 0.140351 0.260185 0.260354C0.150688 0.37514 0.0750833 0.510795 0.0333704 0.667321C-0.00312847 0.823847 -0.00312847 0.980372 0.0333704 1.1369C0.0750833 1.28821 0.150688 1.41864 0.260185 1.52821L4.72608 5.99702L0.260185 10.4737C0.150688 10.5832 0.0750833 10.7163 0.0333704 10.8728C-0.00834259 11.0241 -0.0109497 11.178 0.0255492 11.3345C0.0672622 11.4963 0.145474 11.6319 0.260185 11.7415Z"
              fill="white"
            />
          </svg>
        </div>
        <Grid
          container
          justifyContent="center"
          gap="33px"
          sx={{
            p: '7px 19px 18px 18px',
            '@media screen and (max-height: 760px)': {
              maxHeight: '550px',
              overflowY: 'scroll',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
            },
          }}>
          <Box
            sx={{
              width: isTablet ? '100%' : '316px',
              height: '316px',
              '@media only screen and (max-width: 600px)': { height: '158px' },
            }}>
            <img
              src={`${process.env.REACT_APP_GIF_URL}/MetaCell_${numeral(
                Number(cell?.stage) + 1
              ).format('000')}.gif`}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: '14px',
                boxShadow: 'inset 0px 0px 50px 15px rgba(0, 0, 0, 0.3)',
              }}
              onError={(e) => {
                e.target.src = `${process.env.REACT_APP_RESOURCE}/metacell/1.png`;
              }}
              alt="cell"
            />
          </Box>
          <Grid container direction="column" sx={{ width: 'auto', flexGrow: 1 }}>
            <Grid container gap="8px" alignItems="center">
              <Typography variant="modalId" sx={{ opacity: 0.4, height: '21px' }}>
                Token ID
              </Typography>
              <Typography
                variant="modalId"
                sx={{
                  background: 'rgba(255, 255, 255, 0.08)',
                  borderRadius: '4px',
                  height: '21px',
                  p: '0 4px',
                }}>
                {`# ${cell?.id}`}
              </Typography>
            </Grid>
            <Typography
              ref={nameRef}
              variant="modalName"
              sx={{
                height: '42px',
                mt: '3px',
                justifyContent: 'flex-start',
                maxWidth: isWraped ? '300px' : '480px',
                overflow: 'hidden',
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                },
                '&:hover:after': {
                  width: 0,
                  height: 0,
                },
              }}>
              <Box
                sx={{
                  '&:hover': isNameOverflown && {
                    transform: `translateX(-${nameOffset}px)`,
                    transition: `transform ${nameTransitionTime}s`,
                  },
                }}>
                {`Metacell #${cell?.id}`}
              </Box>
            </Typography>
            <Grid container gap="8px" alignItems="center" marginTop="17px" sx={{ width: 'auto' }}>
              <Typography variant="modalId" sx={{ opacity: 0.4, height: '21px' }}>
                Owned by
              </Typography>
              <Typography
                ref={nameRef}
                variant="modalId"
                sx={{
                  height: '21px',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  },
                  '&:hover:after': {
                    width: 0,
                    height: 0,
                  },
                }}>
                <Box
                  sx={{
                    maxWidth: isWraped ? '300px' : '480px',
                    overflow: 'hidden',
                  }}>
                  {walletAddress}
                </Box>
              </Typography>
            </Grid>
            <FieldCellDetailWithoutPrice onSale={cell?.onSale} />
            <Grid
              ref={buttonRef}
              container
              justifyContent={isButtonWraped ? 'center' : 'space-between'}
              alignItems="center"
              gap="10px"
              sx={{ mt: field ? '17px' : '25px' }}>
              {field && (
                <FieldCellDetail
                  field={field}
                  onSale={cell?.onSale}
                  showUsers={showUsers}
                  priceRef={priceRef}
                  isPriceOverflown={isPriceOverflown}
                  price={cell?.price}
                  priceOffset={priceOffset}
                  editPriceButtonHandler={editPriceButtonHandler}
                  priceTransitionTime={priceTransitionTime}
                  owner={cell?.buyerAddress || cell?.sellerAddress || cell?.owner}
                  removeButtonHandler={removeButtonHandler}
                  sellButtonHandler={sellButtonHandler}
                  buyButtonHandler={buyButtonHandler}
                  setCurrentToken={setCurrentToken}
                  hideButton
                />
              )}
            </Grid>
            <Grid
              container
              gap="8px"
              alignItems="center"
              sx={{
                height: '31px',
                border: '1px solid rgba(255, 255, 255, 0.4)',
                borderRadius: '7px',
                padding: '4px 9px',
                mt: field ? '17px' : '25px',
              }}>
              <Typography variant="modalAttribute" sx={{ opacity: 0.4, height: '21px' }}>
                Genetic code:
              </Typography>
              <Typography
                variant="modalAttribute"
                sx={{
                  height: '21px',
                  justifyContent: 'flex-start',
                  position: 'relative',
                }}>
                <Box
                  sx={{
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '16px',
                    '@media screen and (max-width: 576px)': {
                      maxWidth: '100px',
                    },
                    '@media screen and (max-width: 400px)': {
                      maxWidth: '80px',
                    },
                  }}>
                  {MetacellName[cell?.id]?.slice(14, 43)}
                </Box>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    title={information}
                    arrow
                    placement="top-end"
                    classes={{ tooltip: classes.customWidth }}>
                    <InfoOutlinedIcon
                      onClick={handleTooltipOpen}
                      onMouseEnter={handleTooltipOpen}
                      onMouseLeave={handleTooltipClose}
                      fontSize="inherit"
                      sx={{
                        cursor: 'pointer',
                        '@media screen and (min-width: 577px)': {
                          display: 'none',
                        },
                      }}
                    />
                  </Tooltip>
                </ClickAwayListener>
              </Typography>
            </Grid>
            <Box sx={{ width: '100%', mt: field ? '19px' : '26px' }}>
              <Grid container gap="4px" sx={{ width: 'fit-content' }}>
                <Typography variant="modalLevel" sx={{ opacity: 0.4, height: '16px' }}>
                  Level:
                </Typography>
                <Typography variant="modalLevel">{Number(cell?.stage)}</Typography>
              </Grid>
              <CustomProgressBar value={Number(cell?.stage)} height={6} />
            </Box>
            <Grid
              container
              justifyContent="space-between"
              gap="10px"
              sx={{
                mt: '0px',
                mb: '15px',
                pb: '23px',
                borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
              }}>
              <Typography variant="modalAttribute" sx={{ opacity: 0.4, height: '21px' }}>
                {/* Split skill modifiers: */}
              </Typography>
              <Typography variant="modalAttribute" sx={{ height: '21px' }}>
                {/* 51% */}
              </Typography>
            </Grid>
            <Typography variant="modalAttribute" sx={{ mt: '20px', opacity: 0.4, height: '21px' }}>
              Next Evolution
            </Typography>
            <Typography variant="modalTimer" sx={{ mt: '3px', color: '#09EBF6', height: '36px' }}>
              {cell?.isMaxEvolution ? (
                'MAX EVOLUTION'
              ) : (
                <Countdown date={nextTime || Date.now() + 50} renderer={renderer} />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CellDetailsModal;
