import { Coinbase, Metamask } from '@components/Icons';
import { useSelector } from 'react-redux';
import { BitkeepIcon } from '@components/Icons/Bitkeep';
import Skeleton from '@mui/material/Skeleton';
import { ITEM_NAME_STORAGE } from '@shared';
import { connectorsByName, ConnectorNames } from '@utils/connectors';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { defaultConnectedWalletStatus } from './useConnectors';

function useGetWalletIcon(size = 24) {
  const { connector } = useWeb3React();
  const { walletName } = useSelector((state) => state.wallet);
  const { Injected, WalletConnect, WalletLink, Bitkeep: isBitkeep } =
    JSON.parse(localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)) ||
    defaultConnectedWalletStatus;

  const walletIcon = useMemo(() => {
    const connectedWalletStatus =
      JSON.parse(localStorage?.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)) ||
      defaultConnectedWalletStatus;
    const entries = Object.entries(connectedWalletStatus);
    let icon = <Skeleton variant="circular" width={size} height={size} />;
    if (walletName === connectorsByName.Injected || Injected) {
      icon = <Metamask width={size} />;
    } else if (walletName === connectorsByName.WalletConnect || WalletConnect) {
      icon = (
        <img
          style={{ width: `${size}px`, height: `${size}px` }}
          src="/images/wallet-connect-logo.png"
          alt="wallet connect icon"
        />
      );
    } else if (walletName === connectorsByName.WalletLink || WalletLink) {
      icon = <Coinbase width={size} />;
    } else if (isBitkeep) {
      icon = <BitkeepIcon width={size} />;
    }
    return icon;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector, walletName, Injected, WalletConnect, WalletLink, isBitkeep]);

  return {
    walletIcon,
  };
}

export default useGetWalletIcon;
