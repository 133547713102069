import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

const initialState = {
  list: [],
  paginate: 1,
  isLoading: false,
  total: null,
};

export const NFTSlice = createSlice({
  name: 'NFT',
  initialState,
  reducers: {
    setPaginate: (state, action) => {
      state.paginate = _get(action, 'payload', 1);
    },
    getListNFTs: (state, action) => {
      state.isLoading = true;
      state.list = [];
    },
    getListNFTsSuccess: (state, action) => {
      state.list = _get(action, 'payload.data');
      state.total = _get(action, 'payload.total');
      state.isLoading = false;
    },
    getListNFTsFailure: (state, action) => {
      state.list = [];
      state.isLoading = false;
    },
    resetState: (state) => {
      state.list = [];
      state.total = null;
    },
  },
});

export const {
  getListNFTs,
  getListNFTsSuccess,
  getListNFTsFailure,
  resetState,
  setPaginate,
} = NFTSlice.actions;

export const selectNFT = (state) => state.NFT;

export default NFTSlice.reducer;
