import { styled } from '@mui/material/styles';

export const LoginContentWrapper = styled('div')`
  .modal_login__wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .modal_login__wrapper {
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
    }
  }
`;

export const DotPulse = styled('div')`
  /**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
  margin-left: 24px;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 9999px 0 0 -5px #ffffff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  ::before,
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #ffffff;
  }

  ::before {
    box-shadow: 9984px 0 0 -5px #ffffff;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  ::after {
    box-shadow: 10014px 0 0 -5px #ffffff;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #ffffff;
    }
    30% {
      box-shadow: 9984px 0 0 2px #ffffff;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #ffffff;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #ffffff;
    }
    30% {
      box-shadow: 9999px 0 0 2px #ffffff;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #ffffff;
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #ffffff;
    }
    30% {
      box-shadow: 10014px 0 0 2px #ffffff;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #ffffff;
    }
  }
`;
