import { Popover, useMediaQuery } from '@mui/material';
import useGetScreenWidth from '@hooks/common/useGetScreenWidth';
import { forwardRef } from 'react';
import { SelectableValuesWrapper, SpecItem, ItemBorder, WrapperSpecItem } from '../styles';

const PickItemPopover = forwardRef(
  (
    {
      openSelectionTable = () => {},
      setOpenSelectionTable = () => {},
      onSelectValue = () => {},
      selectableValues,
      name,
      value,
      modalLeftDistance,
    },
    ref
  ) => {
    const isSmallDevice = useMediaQuery('( max-width: 1024px)');
    const isSmallDeviceNotRotate = useMediaQuery('( max-width: 1024px) and ( min-height: 451px )');
    const isSmallDeviceRotateMode = useMediaQuery('( max-width: 1024px) and ( max-height: 450px )');

    return (
      <Popover
        id={name}
        open={openSelectionTable}
        anchorEl={ref.current}
        onClose={() => setOpenSelectionTable(false)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            background: 'none',
            top: isSmallDeviceNotRotate ? '50% !important' : 'unset',
            bottom: isSmallDeviceRotateMode ? '10% !important' : 'unset',
            left: isSmallDevice
              ? 'calc(50vw - 175px) !important'
              : `${modalLeftDistance + 20}px !important`,
          },
        }}>
        <SelectableValuesWrapper>
          {selectableValues.map((item) => {
            return (
              <WrapperSpecItem key={item.value}>
                <ItemBorder active={value === item.value ? 'true' : ''} />
                <SpecItem
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectValue(name, item.value);
                  }}
                  type="button"
                  active={value === item.value ? 'true' : ''}
                  blur={item?.isBlur ? 'blur' : ''}>
                  {item?.background ? (
                    <img
                      src={
                        item?.background ||
                        'https://cdn.akamai.steamstatic.com/steam/apps/1210920/capsule_616x353.jpg?t=1636655672'
                      }
                      alt="bg"
                      className="spec__background"
                    />
                  ) : null}
                  <img src={item.icon} alt="specIcon" className="spec__icon" />
                  <span>{item.title}</span>
                </SpecItem>
              </WrapperSpecItem>
            );
          })}
        </SelectableValuesWrapper>
      </Popover>
    );
  }
);

PickItemPopover.displayName = 'PickItemPopover';

export default PickItemPopover;
