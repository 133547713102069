import { useMediaQuery } from '@mui/material';
import useAuth from '@hooks/users/useAuth';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:1100px)');
  return <>{isMobile ? <HeaderMobile /> : <HeaderDesktop />}</>;
};
export default Header;
