import { ethers } from 'ethers';
import { nanoid } from 'nanoid';
import { environment } from '../environment';
import { HEX_MAX_EVOLUTION_TIME } from './constants';

export default (token, { blockNumber, queryDate }) => {
  const tokenID = token.tokenId.toNumber();
  const tokenClass = token.class;
  const stage = token.stage.toNumber();
  const { onSale } = token;
  const price = ethers.utils.formatEther(token.price);
  const image = `${process.env.REACT_APP_RESOURCE}/metacell/${stage + 1}.png`;
  const isMaxNumber = ethers.BigNumber.from(HEX_MAX_EVOLUTION_TIME).eq(token.nextEvolutionBlock);
  const name = `Metacell #${token.tokenId}`;
  const owner = token.user;
  let evolutionTimestamp;
  if (!isMaxNumber) {
    evolutionTimestamp =
      queryDate +
      (token.nextEvolutionBlock.toNumber() - blockNumber) *
        1000 *
        Number(environment.AVERAGE_BLOCK_TIME);
  }

  return {
    ...token,
    id: tokenID,
    class: tokenClass,
    stage,
    evolutionTimestamp,
    type: 'cell',
    name,
    onSale,
    price,
    owner,
    nanoId: nanoid(),
    isMaxEvolution: isMaxNumber,
    image,
    // variant,
  };
};
