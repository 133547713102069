import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  walletName: '',
  walletText: '',
  connector: null,
};

export const WalletSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setWalletName: (state, action) => {
      state.walletName = action.payload;
      if (action.payload === 'WalletLink') {
        state.walletText = 'Coinbase wallet';
      } else if (action.payload === 'Injected') {
        state.walletText = 'MetaMask wallet';
      } else if (action.payload === 'Bitkeep') {
        state.walletText = 'BitKeep wallet';
      } else state.walletText = `${action.payload} wallet`;
    },
    setConnector: (state, action) => {
      state.connector = action.payload;
    },
    resetState: (state) => {
      state.walletName = '';
      state.connector = null;
      state.walletText = '';
    },
  },
});

export const { setWalletName, setConnector, resetState } = WalletSlice.actions;

export const selectWalletStatus = (state) => state.modals;

export default WalletSlice.reducer;
