import { Box, Grid, Typography, LinearProgress } from '@mui/material';
import SellButton from '@components/Buttons/SellButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import DetailsButton from '@components/Buttons/DetailsButton';
import BuyButton from '@components/Buttons/BuyButton';
import { formatPrice } from '@utils';
import { useTicker } from '@hooks';
import { useWeb3React } from '@web3-react/core';

const NanocellCard = ({
  detailsButtonHandler,
  sellButtonHandler,
  removeButtonHandler,
  editPriceButtonHandler,
  buyButtonHandler,
  setCurrentToken,
  cell,
  showUsers,
}) => {
  const { account } = useWeb3React();
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();
  const {
    ref: priceRef,
    params: {
      isOverflown: isPriceOverflown,
      offset: priceOffset,
      transitionTime: priceTransitionTime,
    },
  } = useTicker();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        width: '279px',
        background: '#17181C',
        border: '1px solid  #202228',
        borderRadius: '14px',
        p: '19px 19px 24px',
      }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ background: '#000', borderRadius: '14px', overflow: 'hidden', height: '129px' }}>
        <Box sx={{ width: '115px', height: '115px' }}>
          <img
            src={cell.image || `${process.env.REACT_APP_RESOURCE}/metacell/1.png`}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            alt="nanocard"
          />
        </Box>
      </Grid>
      <Grid container justifyContent="space-between" wrap="nowrap" gap="5px" mt="20px">
        <Grid container alignItems="center" gap="8px">
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${cell.id}`}
          </Typography>
        </Grid>
        <Grid container wrap="nowrap" alignItems="center" gap="7px" sx={{ width: 'auto' }}>
          {showUsers && cell?.onSale ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '1px solid #09EBF6',
                borderRadius: '100px',
                width: '19px',
                height: '19px',
                cursor: 'pointer',
                ':hover': { background: '#09595e' },
              }}
              onClick={() => {
                setCurrentToken(cell);
                editPriceButtonHandler();
              }}>
              <img src="/images/Pencil.png" width="8.55px" height="8.55px" alt="edit" />
            </Grid>
          ) : null}
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            PRICE
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        gap="5px"
        alignItems="center"
        sx={{ mt: '3px', overflow: 'hidden' }}>
        <Typography
          ref={nameRef}
          variant="cardName"
          sx={{
            height: '19px',
            maxWidth: '50%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'linear-gradient(to right, transparent 80px, #17181C)',
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box
            sx={{
              '&:hover': isNameOverflown && {
                transform: `translateX(-${nameOffset}px)`,
                transition: `transform ${nameTransitionTime}s`,
              },
            }}>
            {cell.name}
          </Box>
        </Typography>
        <Grid
          container
          justifyContent="flex-end"
          gap="4px"
          flexWrap="nowrap"
          sx={{ maxWidth: '50%' }}>
          <Typography
            ref={priceRef}
            variant="cardPrice"
            sx={{
              height: '18px',
              justifyContent: 'flex-start',
              overflow: 'hidden',
              position: 'relative',
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                background: 'linear-gradient(to right, transparent 50px, #17181C)',
              },
              '&:hover:after': {
                width: 0,
                height: 0,
              },
            }}>
            <Box
              sx={{
                '&:hover': isPriceOverflown && {
                  transform: `translateX(-${priceOffset}px)`,
                  transition: `transform ${priceTransitionTime}s`,
                },
              }}>
              {formatPrice(cell.price, 4)}
            </Box>
          </Typography>
          <Typography
            variant="cardPrice"
            sx={{
              height: '18px',
            }}>
            BIOMETA
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', mt: '21px' }}>
        <Grid container gap="4px" sx={{ width: 'auto' }}>
          <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
            Level:
          </Typography>
          <Typography variant="cardAttribute">65</Typography>
        </Grid>
        <LinearProgress
          variant="determinate"
          value={65}
          sx={{
            borderRadius: '7px',
            backgroundColor: '#242424',
            mt: '4px',
            '.MuiLinearProgress-bar': {
              background:
                'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          m: '20px 0 19px',
          width: '100%',
        }}
      />
      <Grid container justifyContent="space-between">
        <DetailsButton
          detailsButtonHandler={() => {
            setCurrentToken(cell);
            detailsButtonHandler(cell);
          }}
        />
        {account === cell?.owner || showUsers ? (
          cell.onSale ? (
            <RemoveButton
              removeButtonHandler={() => {
                setCurrentToken(cell);
                removeButtonHandler();
              }}
              sx={{ width: '96px' }}
            />
          ) : (
            <SellButton
              sellButtonHandler={() => {
                setCurrentToken(cell);
                sellButtonHandler();
              }}
            />
          )
        ) : (
          <BuyButton
            buyButtonHandler={() => {
              setCurrentToken(cell);
              buyButtonHandler();
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default NanocellCard;
