import { useState, useEffect } from 'react';

const useBrowserActiveTab = (setReadyForEvolution) => {
  const [browserTabActive, setBrowserTabActive] = useState(true);

  useEffect(() => {
    const changeBrowserTabActive = () => {
      if (setReadyForEvolution) setReadyForEvolution(false);
      setBrowserTabActive(!browserTabActive);
    };

    document.addEventListener('visibilitychange', changeBrowserTabActive);

    return () => {
      document.removeEventListener('visibilitychange', changeBrowserTabActive);
    };
  }, [browserTabActive]);

  return browserTabActive;
};

export default useBrowserActiveTab;
