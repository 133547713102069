import { Manager } from 'socket.io-client';
import { environment } from '../environment';

export const signIn = (library, account, setShowSignIn, setUser, setShowInfoModal) => {
  if (account && !localStorage?.getItem('connectionError')) {
    const manager = new Manager(environment.SERVER_URL);

    manager.on('error', () => {
      manager.reconnection(false);
      setShowInfoModal(true);
      setShowSignIn(false);
      localStorage.setItem('connectionError', true);
    });

    const authSocket = manager.socket('/auth');

    if (!localStorage?.getItem(account)) {
      setShowSignIn(true);

      authSocket.emit('getNonce', { address: account }, async (getNonceAck) => {
        try {
          if (getNonceAck.error) {
            throw new Error(getNonceAck.error.name, getNonceAck.error.message);
          }

          const message = `Test ${getNonceAck.data.nonce.value}`;
          const signature = await library.getSigner().signMessage(message);

          authSocket.emit('singUpByETH', { signature, message }, (singUpByETHAck) => {
            if (singUpByETHAck.error) {
              throw new Error(singUpByETHAck.error.name, singUpByETHAck.error.message);
            }

            localStorage.setItem(
              account,
              JSON.stringify({
                accessToken: singUpByETHAck.data.accessToken,
                refreshToken: singUpByETHAck.data.refreshToken,
              })
            );

            localStorage.removeItem('connectionError');

            setUser({
              nickname: singUpByETHAck.data.user.nickname,
              email: singUpByETHAck.data.user.email,
              emailConfirmed: singUpByETHAck.data.user.roles.includes('CONFIRMED'),
            });
          });
        } catch (error) {
          localStorage.setItem('connectionError', true);
        } finally {
          setShowSignIn(false);
        }
      });
    } else {
      const tokens = JSON.parse(localStorage.getItem(account));

      const usersSocket = manager.socket('/users', {
        auth: { token: `Bearer ${tokens.accessToken}` },
      });

      usersSocket.emit('getMyUser', (getMyUserAck) => {
        if (getMyUserAck.error) {
          authSocket.emit(
            'updateTokens',
            { refreshToken: tokens.refreshToken },
            (updateTokensAck) => {
              if (updateTokensAck.error) {
                localStorage.removeItem(account);
              } else {
                localStorage.setItem(
                  account,
                  JSON.stringify({
                    accessToken: updateTokensAck.data.accessToken,
                    refreshToken: updateTokensAck.data.refreshToken,
                  })
                );
              }
              signIn();
            }
          );
        } else {
          setUser({
            nickname: getMyUserAck.data.user.nickname,
            email: getMyUserAck.data.user.email,
            emailConfirmed: getMyUserAck.data.user.roles.includes('CONFIRMED'),
          });
        }
      });
    }
  }
};
