import { Button } from '@mui/material';

const BuyButton = ({ buyButtonHandler, disabled, text, sx }) => {
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      sx={{
        color: '#09EBF6',
        borderColor: '#09EBF6',
        borderRadius: '18px',
        maxWidth: '100%',
        width: '74px',
        height: '36px',
        fontSize: '14px',
        lineHeight: '16.7px',
        fontWeight: 600,
        '&:hover': {
          color: '#fff',
          background: 'rgba(9, 235, 246, 0.5)',
        },
        '&:active': {
          color: '#fff',
          background: 'rgba(9, 235, 246, 0.5)',
        },
        '&:disabled': {
          color: '#007D83',
          borderColor: '#007D83',
        },
        ...sx,
      }}
      onClick={buyButtonHandler}>
      {text || 'Buy'}
    </Button>
  );
};

export default BuyButton;
