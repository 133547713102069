import { Grid } from '@mui/material';
import Slider from 'react-slick';
import MainTab from './MainTab';
import { MainTabBoardContainerStyled } from './styles';

const tabSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoPlay: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const MainTabBoard = ({ tabs, activeTab, setActiveTab }) => (
  <MainTabBoardContainerStyled>
    <div className="main_board_tab__wrapper">
      <Slider {...tabSliderSettings} className="tab__slider">
        {tabs.map((tab) => (
          <MainTab
            key={`mainTab${tab}`}
            tab={tab}
            active={tab === activeTab}
            setActiveTab={setActiveTab}
          />
        ))}
      </Slider>
    </div>
  </MainTabBoardContainerStyled>
);
export default MainTabBoard;
