import { useContracts } from '@providers';
import { useAppStore } from '@store/index';
import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';

export const useUpdateLabStore = () => {
  const { account } = useWeb3React();
  const { Laboratory } = useContracts();
  const {
    methodContract: { updateBoostPerBlockPrice },
  } = useAppStore();

  useEffect(() => {
    if (!Laboratory || !account) return () => {};

    updateBoostPerBlockPrice();

    Laboratory.on('BoostPricePerBlockChanged', updateBoostPerBlockPrice);
    return () => {
      Laboratory.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Laboratory, account]);
};
