import { Button } from '@mui/material';

const MintButton = ({ mintButtonHandler, disabled, sx }) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      sx={{
        borderRadius: '18px',
        background: '#23D453',
        width: '208px',
        height: '36px',
        textTransform: 'none',
        fontSize: '14px',
        lineHeight: '16.7px',
        fontWeight: 600,
        cursor: 'default',
        '&:hover': {
          background: '#09BD3A',
        },
        '&:active': {
          background: '#09BD3A',
        },
        '&:disabled': {
          color: 'rgba(255, 255, 255, 0.2)',
          background: 'none',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
        ...sx,
      }}
      onClick={mintButtonHandler}>
      {disabled ? 'Metacell minted' : 'Mint is available'}
    </Button>
  );
};

export default MintButton;
