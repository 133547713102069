import { useContracts } from '@providers';
import { useWeb3React } from '@web3-react/core';
import { useQuery } from 'react-query';
import { formatMetacellData } from '../../../utils';

export const MetacellUserQueryKey = 'metacells-user-query-key';

export const useMetacellUsersQuery = () => {
  const { account, library } = useWeb3React();

  const contracts = useContracts();
  const { MetaCell, handleAggregateCall } = contracts;

  const query = useQuery(
    [MetacellUserQueryKey, account],
    async () => {
      if (!MetaCell) return null;
      const usersMetacellsIndexes = await MetaCell.getUserMetaCellsIndexes(account);

      const listRequest = usersMetacellsIndexes.map((o) => {
        const callData = MetaCell.interface.encodeFunctionData('getMetaCell', [o]);
        return {
          target: MetaCell.address,
          callData,
        };
      });

      const multiCallRaw = await handleAggregateCall(listRequest);

      const derivedUsersMetacells = multiCallRaw.map(
        (raw) => MetaCell.interface.decodeFunctionResult('getMetaCell', raw)[0]
      );

      const { blockNumber, _fastQueryDate } = library;
      const userMetacells = derivedUsersMetacells
        .filter((o) => o)
        .map((metaCell) =>
          formatMetacellData(metaCell, { blockNumber, queryDate: _fastQueryDate })
        );

      return userMetacells.map((o) => ({ ...o, owner: account }));
    },
    { enabled: Boolean(MetaCell) && Boolean(account) }
  );

  return query;
};
