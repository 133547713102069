import { ethers } from 'ethers';

export default (token) => {
  // debugger;
  const id = token.tokenId.toNumber();
  // const level = token.level.toNumber();
  const { owner } = token;
  const name = `Mad Scientist ${id}`;
  const image = `${process.env.REACT_APP_SCIENTIST_IMAGES_URL}/${id}.gif`;
  const { onSale = false, mintable = false } = token;
  const price = ethers.utils.formatEther(token.price);

  return {
    id,
    // level,
    owner,
    name,
    image,
    onSale,
    price,
    mintable,
  };
};
