import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import SellButton from '@components/Buttons/SellButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import DetailsButton from '@components/Buttons/DetailsButton';
import BuyButton from '@components/Buttons/BuyButton';
import { useTicker } from '@hooks';
import { useWeb3React } from '@web3-react/core';

const ScientistCard = ({
  detailsButtonHandler,
  sellButtonHandler,
  removeButtonHandler,
  editPriceButtonHandler,
  buyButtonHandler,
  setCurrentToken,
  scientist,
  showUsers,
}) => {
  const { account } = useWeb3React();
  const isMobileTablet = useMediaQuery('(max-width:900px)');
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();
  const {
    ref: priceRef,
    params: {
      isOverflown: isPriceOverflown,
      offset: priceOffset,
      transitionTime: priceTransitionTime,
    },
  } = useTicker();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        width: isMobileTablet ? '400px !important' : '279px',
        background: '#17181C',
        border: '1px solid #202228',
        borderRadius: '14px',
        p: '19px 19px 24px',
      }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ background: '#000', borderRadius: '14px', overflow: 'hidden' }}>
        <Box sx={{ width: '100%', height: isMobileTablet ? '189px' : '129px' }}>
          <img
            src={`${process.env.REACT_APP_SCIENTIST_IMAGES_URL}/${scientist?.id}.gif`}
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            alt="scientist"
            onError={(e) => {
              e.target.src = `${process.env.REACT_APP_SCIENTIST_IMAGES_URL}/116.gif`;
            }}
          />
        </Box>
      </Grid>
      <Grid container justifyContent="space-between" wrap="nowrap" gap="5px" mt="20px">
        <Grid container alignItems="center" gap="8px">
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${scientist.id}`}
          </Typography>
        </Grid>
        <Grid container wrap="nowrap" alignItems="center" gap="7px" sx={{ width: 'auto' }}>
          {account === scientist?.owner && scientist?.onSale ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '1px solid #09EBF6',
                borderRadius: '100px',
                width: '19px',
                height: '19px',
                cursor: 'pointer',
                ':hover': { background: '#09595e' },
              }}
              onClick={() => {
                setCurrentToken(scientist);
                editPriceButtonHandler();
              }}>
              <img src="/images/Pencil.png" width="8.55px" height="8.55px" alt="edit" />
            </Grid>
          ) : null}
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            PRICE
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        gap="5px"
        alignItems="center"
        sx={{ mt: '3px', overflow: 'hidden' }}>
        <Typography
          ref={nameRef}
          variant="cardName"
          sx={{
            height: '19px',
            width: isMobileTablet ? '70%' : '50%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: isMobileTablet
                ? 'transparent'
                : 'linear-gradient(to right, transparent 80px, #17181C)',
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box
            sx={{
              '&:hover': isNameOverflown && {
                transform: `translateX(-${nameOffset}px)`,
                transition: `transform ${nameTransitionTime}s`,
              },
            }}>
            {`Mad Metascientist #${scientist.id}`}
          </Box>
        </Typography>
        <Grid
          container
          justifyContent="flex-end"
          gap="4px"
          flexWrap="nowrap"
          sx={{ width: isMobileTablet ? '30%' : '50%' }}>
          <Typography
            ref={priceRef}
            variant="cardPrice"
            sx={{
              height: '18px',
              justifyContent: 'flex-start',
              overflow: 'hidden',
              position: 'relative',
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                background: isMobileTablet
                  ? 'transparent'
                  : 'linear-gradient(to right, transparent 50px, #17181C)',
              },
              '&:hover:after': {
                width: 0,
                height: 0,
              },
            }}>
            <Box
              sx={{
                '&:hover': isPriceOverflown && {
                  transform: `translateX(-${priceOffset}px)`,
                  transition: `transform ${priceTransitionTime}s`,
                },
              }}>
              {scientist.price}
            </Box>
          </Typography>
          <Typography
            variant="cardPrice"
            sx={{
              height: '18px',
            }}>
            ETH
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          m: '20px 0 19px',
          width: '100%',
        }}
      />
      <Grid container justifyContent="space-between">
        <Grid style={{ maxWidth: '46%' }}>
          <DetailsButton
            detailsButtonHandler={() => {
              setCurrentToken(scientist);
              detailsButtonHandler(scientist);
            }}
          />
        </Grid>
        <Grid style={{ maxWidth: '46%' }}>
          {showUsers || account === scientist?.owner ? (
            scientist.onSale ? (
              <RemoveButton
                removeButtonHandler={() => {
                  setCurrentToken(scientist);
                  removeButtonHandler();
                }}
                sx={{ width: '96px' }}
              />
            ) : (
              <SellButton
                sellButtonHandler={() => {
                  setCurrentToken(scientist);
                  sellButtonHandler();
                }}
              />
            )
          ) : (
            <BuyButton
              buyButtonHandler={() => {
                setCurrentToken(scientist);
                buyButtonHandler();
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScientistCard;
