import { Button } from '@mui/material';

const ConfirmButton = ({ confirmButtonHandler, disabled, text, sx }) => {
  return (
    <div
      style={{
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '20px',
        padding: '2px',
        width: '146px',
        height: '42px',
        marginBottom: '20px',
        marginTop: '20px',
      }}>
      <Button
        variant="contained"
        disabled={disabled}
        sx={{
          background: '#00ABE2',
          borderRadius: '20px',
          width: '100%',
          height: '36px',
          fontSize: '15px',
          lineHeight: '17.89px',
          fontWeight: 600,
          '&:hover': {
            outlineColor: '#B9B9B9',
            background: '#09EBF6',
          },
          '&:active': {
            outlineColor: '#B9B9B9',
            background: '#09EBF6',
          },
          '&:disabled': {
            color: 'rgba(255, 255, 255, 0.2)',
            background: '#444547',
          },
          ...sx,
        }}
        onClick={confirmButtonHandler}>
        {text || 'Confirm'}
      </Button>
    </div>
  );
};

export default ConfirmButton;
