/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import BoostController from '@components/BoostController';
import CellDetailsModal from '@components/Modals/CellDetailsModal';
import { environment } from '@environment';
import { MaxUint256 } from '@ethersproject/constants';
import { useUpdateLabStore, useWrapDetect } from '@hooks';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';
import { getListNFTs, resetState } from '@providers/NFTProvider';
import { asyncTransactionHash as sendTransactionInfo } from '@providers/Transaction';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import { formatMetacellData, formatNanocellData } from '@utils';
import { MARKET_PLACE_TYPE_EVENT } from '@utils/constants';
import getWalletName from '@utils/getWalletName';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { get } from 'lodash';
import numeral from 'numeral';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Countdown from 'react-countdown';
import { useDispatch } from 'react-redux';
import {
  ConfirmButton,
  LabBoostTimer,
  LabError,
  LabLoading,
  LabTab,
  LoadingModal,
  PetriDish,
} from '@components';
import { useContracts } from '@providers';
import { useAppStore } from '@store';
import { LAB_DROP_CELL, LAB_DROP_ENHANCER } from './context/constant';
import { useLabSlotAction, useLabSlotData } from './context/hooks';
import LabSlot from './LabSlot';
import { ListOwnerCell } from './LeftLab/ListOwnerCell';
import { RightSideLab } from './RightLab/RightSideLab';
import { LabContainerStyled } from './styles';

const appear = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

const LabFeature = {
  EVOLVE: 'Evolve Cell',
  MUTATE: 'Mutate Cell',
  BOOST: 'Boost Cell',
};

const LabSlotText = {
  evolveCellSlot: 'evolveCellSlot',
  evolveEnhancerSlot: 'evolveEnhancerSlot',
  mergeCellSlot1: 'mergeCellSlot1',
  mergeCellSlot2: 'mergeCellSlot2',
  boostCellSlot: 'boostCellSlot',
};

// const labTabs = [LabFeature.EVOLVE, 'Mutate Cell',  LabFeature.BOOST];
const labTabs = [LabFeature.EVOLVE, LabFeature.BOOST];

const ListNftLab = memo(ListOwnerCell);

const MainComponent = () => {
  const { boostPerBlockPrice, usersBalance } = useAppStore();
  const { PolygonMarketplace, Laboratory, NanoCell, MDMA } = useContracts();
  const { activeFilter, labCells, actionSlot } = useLabSlotData();
  const { _onDragStart, _onDragEnd, _onDragReset } = useLabSlotAction();
  const { library, account, chainId } = useWeb3React();

  const dispatch = useDispatch();

  const [activeLabTab, setActiveLabTab] = useState(labTabs[0]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [boostPrice, setBoostPrice] = useState('');
  const [maxBoostPrice, setMaxBoostPrice] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [currentToken, setCurrentToken] = useState();
  const [outcome, setOutcome] = useState();
  const [split, setSplit] = useState();
  const [showSplit, setShowSplit] = useState(false);
  const [message, setMessage] = useState();
  const [isSafeNumber, setIsSafeNumber] = useState(true);
  const [mining, setMining] = useState(false);
  const [labEventName, setLabEventName] = useState('Minted');
  const [isUpdateCountdown, updateCountdown] = useState(0);
  const removeSvg = useMediaQuery('(max-width:555px)');
  const isMobile = useMediaQuery('(max-width:768px)');
  const ref = useRef(null);
  const isWraped = useWrapDetect(ref, ref, 660);
  const mainRef = useRef(null);
  const isMainWraped = useWrapDetect(mainRef, mainRef, 1320);
  const connectedWalletStatus = JSON.parse(
    localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
  );
  const walletText = getWalletName(connectedWalletStatus);
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      updateCountdown((o) => !o);
      setIsButtonDisabled(false);
      return 'READY';
    }
    return (
      <span>
        {days > 0 ? (days < 10 ? `0${days} ` : `${days} `) : '00 '}:
        {hours > 0 ? (hours < 10 ? ` 0${hours} ` : ` ${hours} `) : ' 00 '}:
        {minutes > 0 ? (minutes < 10 ? ` 0${minutes}` : ` ${minutes} `) : ' 00 '}:
        {seconds > 0 ? (seconds < 10 ? ` 0${seconds}` : ` ${seconds}`) : ' 00'}
      </span>
    );
  };
  useUpdateLabStore();
  const metacellAction = useMemo(() => get(actionSlot, [0]), [actionSlot]);
  const enhancerAction = useMemo(() => get(actionSlot, [1]), [actionSlot]);

  const cantEvolve = useMemo(() => {
    if (!metacellAction) return false;

    return [metacellAction?.evolutionTimestamp - Date.now()].filter((o) => o < 0).length === 0 ? (
      <>
        {metacellAction?.isMaxEvolution
          ? "Can't evolve! Max level reached!"
          : "Can't evolve! There is some time left!"}
        <br />
      </>
    ) : (
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metacellAction, isUpdateCountdown]);

  const cantMerge = "Can't merge! Must have a cell and a external NFT!";

  useEffect(() => {
    document.title = 'Mad Metaverse - 🔬 The Lab';
  }, []);

  const cantBoost = useMemo(
    () =>
      metacellAction?.evolutionTimestamp - Date.now() <= 0
        ? "Can't boost! Metacell can evolve now!"
        : false,
    [metacellAction]
  );

  const boostTime = boostPrice
    ? ethers.utils
        .parseEther(boostPrice)
        .div(boostPerBlockPrice)
        .mul(environment.AVERAGE_BLOCK_TIME)
        .toString()
    : 0;

  useEffect(() => {
    dispatch(getListNFTs({ page: 1, isUsed: false, sort: 'createdAt' }));
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const listLabCells = labCells;

  const labButtonHandler = async () => {
    setSplit(null);
    setShowSplit(false);
    setErrorMessage(`Please confirm transaction in your ${walletText}`);
    setShowConfirmModal(true);
    try {
      let tx;
      const cell = metacellAction;
      const enhancer = enhancerAction;

      if (activeLabTab === LabFeature.EVOLVE) {
        const gas = await Laboratory.estimateGas.evolve(cell?.id, enhancer?.id || 0, 0);
        console.log({
          gas,
          enhancer,
        });

        tx = await Laboratory.evolve(cell?.id, enhancer?.id || 0, 0, {
          gasLimit: Math.round(1.5 * gas.toNumber()),
        });
      }

      if (activeLabTab === 'Mutate Cell') {
        tx = await Laboratory.mutate(cell.id, cell.id);
      }

      if (activeLabTab === LabFeature.BOOST) {
        const allowance = await MDMA.allowance(account, environment.LABORATORY_ADDRESS);
        const boostPriceUpdated = isSafeNumber
          ? ethers.utils.parseUnits(boostPrice)
          : ethers.utils.parseEther(boostPrice);
        if (!allowance.gt(boostPriceUpdated)) {
          setErrorMessage(`Please approve token use in your ${walletText}`);
          const atx = await MDMA.approve(environment.LABORATORY_ADDRESS, MaxUint256);
          setMining(true);
          setErrorMessage('Waiting for transaction to be mined');
          await atx.wait();
        }
        setMining(false);
        setErrorMessage(`Please confirm transaction in your ${walletText}`);
        tx = await Laboratory.boostCell(cell.id, boostPriceUpdated);
      }
      setShowLoadingModal(true);
      setShowConfirmModal(false);
      await tx.wait(1);
      if (activeLabTab === LabFeature.EVOLVE) {
        if (tx?.hash) {
          dispatch(
            sendTransactionInfo({
              transactionHash: tx?.hash,
              event: MARKET_PLACE_TYPE_EVENT.lab.evolve,
              contract: MARKET_PLACE_TYPE_EVENT.lab.contract,
              chainId: chainId.toString(),
            })
          );
        }
      }
    } catch (error) {
      if (error.code !== 4001) {
        // eslint-disable-next-line no-console
        console.log('error', error.error);
        if (error?.error?.code === 3) {
          setErrorMessage(error.error.message);
        } else if (error.code === -32603) {
          setErrorMessage('User rejected the transaction');
        } else {
          setErrorMessage(
            error?.data?.message
              ? error?.data?.message.match(/reason string '(.*?)'$/)[1]
              : error.message
          );
        }
      } else {
        setErrorMessage(error.message);
      }
      setShowErrorModal(true);
      setShowLoadingModal(false);
    } finally {
      setShowConfirmModal(false);
      setShowLoadingModal(false);
    }
  };

  const dragClickHandler = (token, index, filter) => {
    const droppableId = LAB_DROP_CELL;

    _onDragEnd({
      destination: {
        droppableId,
        index: 0,
      },
      source: {
        index,
        droppableId: filter,
      },
      draggableId: `${token.type}-${token.nanoId}`,
    });
  };

  const cellClickHandler = (droppableId, slot, filter) => {
    _onDragEnd({
      destination: {
        droppableId: filter,
        index: 0,
      },
      source: {
        index: 0,
        droppableId,
      },
      draggableId: `${slot[0]?.type}-${slot[0]?.nanoId}`,
    });
  };

  const detailsButtonHandler = (token) => {
    setCurrentToken({
      ...token,
      image: `${process.env.REACT_APP_GIF_URL}/MetaCell_${numeral(Number(token?.stage) + 1).format(
        '000'
      )}.gif`,
    });
    setShowDetails(true);
  };

  useEffect(() => {
    if (labEventName === 'Evolve' && split) {
      setShowSplit(true);
      setMessage('Splitted!');
    }
  }, [labEventName, split]);

  const bracketRight = useMemo(() => {
    if (!removeSvg) {
      return (
        <img
          className="dnd-dropping__bracket dnd-dropping__bracket-right"
          src="/icons/lab-bracket-right.svg"
          alt="lab-stick"
        />
      );
    }
    return null;
  }, [removeSvg]);

  const bracketLeft = useMemo(() => {
    if (!removeSvg) {
      return (
        <img
          className="dnd-dropping__bracket dnd-dropping__bracket-left"
          src="\icons\lab-bracket-left.svg"
          alt="lab-stick"
        />
      );
    }
    return null;
  }, [removeSvg]);

  const renderWarningText = useMemo(() => {
    if (activeLabTab === LabFeature.EVOLVE) {
      return (
        <>
          {cantEvolve}
          <Countdown date={metacellAction?.evolutionTimestamp} renderer={renderer} />
        </>
      );
    }
    if (activeLabTab === 'Mutate Cell') {
      return cantMerge;
    }
    return cantBoost;
  }, [activeLabTab, cantBoost, cantEvolve, metacellAction?.evolutionTimestamp]);

  const updateActiveTab = (e) => {
    setActiveLabTab(e);
    _onDragReset();
  };

  return (
    <LabContainerStyled>
      <Typography className="the-lab__title" variant="pageTitle" component="h1">
        The Lab
      </Typography>
      <Grid container justifyContent="center" alignItems="center" className="the-lab__tabs">
        {labTabs.map((tab, index) => (
          <LabTab
            key={`labTab[${tab}]`}
            tabName={tab}
            active={tab === activeLabTab}
            setActiveLabTab={updateActiveTab}
          />
        ))}
      </Grid>
      <DragDropContext onDragStart={_onDragStart} onDragEnd={_onDragEnd}>
        <Grid className="the-lab__dnd-container" ref={mainRef} container gap="20px">
          <Grid
            container
            direction="column"
            alignItems="center"
            className={`the-lab__dnd-tabs ${isWraped && !isMainWraped && 'wraped'}`}>
            <ListNftLab
              {...{
                listsIds: listLabCells,
                detailsButtonHandler,
                dragClickHandler,
              }}
            />
          </Grid>
          <Grid ref={ref} container justifyContent="center" className="the-lab__dnd-main">
            <Grid
              container
              justifyContent="center"
              className={`dnd-dropping__container ${isWraped && 'warped'}`}>
              {bracketLeft}
              <Grid
                container
                direction="column"
                alignItems="center"
                className="dnd-dropping__first-slot">
                {(activeLabTab === LabFeature.EVOLVE && cantEvolve) ||
                (activeLabTab === LabFeature.BOOST && cantBoost) ? (
                  <Typography
                    variant="rx18"
                    className="dnd-dropping__warning"
                    sx={{
                      animation: `${appear} 0.3s`,
                      wordBreak: 'break-word',
                      display: 'block',
                      whiteSpace: 'none',
                    }}>
                    {renderWarningText}
                  </Typography>
                ) : null}
                <LabSlot
                  droppableId={LAB_DROP_CELL}
                  isDropDisabled={false}
                  slot={[metacellAction]}
                  detailsButtonHandler={detailsButtonHandler}
                  cellClickHandler={cellClickHandler}
                />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  gap="0.75rem"
                  sx={{
                    order: activeLabTab !== LabFeature.BOOST ? '0' : '-1',
                    width: 'max-content',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}>
                  {activeLabTab === LabFeature.BOOST ? (
                    <LabBoostTimer
                      isSlotFilled={Boolean(metacellAction)}
                      cantBoost={cantBoost}
                      timestamp={metacellAction?.evolutionTimestamp}
                      evolutionText="Current Next Evolution"
                    />
                  ) : null}
                  <PetriDish
                    slot1={metacellAction}
                    slot2={enhancerAction}
                    setIsButtonDisabled={setIsButtonDisabled}
                    cantEvolve={cantEvolve}
                    cantMerge={cantMerge}
                    cantBoost={cantBoost}
                    activeLabTab={activeLabTab}
                    boostPrice={boostPrice}
                    usersBalance={usersBalance}
                    isMax={
                      boostPrice && maxBoostPrice
                        ? isSafeNumber
                          ? ethers.utils
                              .parseUnits(boostPrice)
                              .gte(ethers.utils.parseUnits(maxBoostPrice.toString(), 'wei'))
                          : boostPrice >= maxBoostPrice
                        : false
                    }
                  />
                  {activeLabTab === LabFeature.BOOST ? (
                    <LabBoostTimer
                      isSlotFilled={metacellAction}
                      cantBoost={cantBoost}
                      timestamp={get(metacellAction, ['evolutionTimestamp'])}
                      boostTime={boostTime}
                      evolutionText="New Next Evolution"
                    />
                  ) : null}
                </Grid>
                {activeLabTab !== LabFeature.BOOST ? (
                  <LabSlot
                    droppableId={LAB_DROP_ENHANCER}
                    slot={[enhancerAction]}
                    isDropDisabled={isButtonDisabled}
                    detailsButtonHandler={detailsButtonHandler}
                    cellClickHandler={cellClickHandler}
                  />
                ) : (
                  <BoostController
                    boostPrice={boostPrice}
                    setBoostPrice={setBoostPrice}
                    maxBoostPrice={maxBoostPrice}
                    setMaxBoostPrice={setMaxBoostPrice}
                    boostPerBlockPrice={boostPerBlockPrice}
                    usersBalance={usersBalance}
                    disabled={!metacellAction || Boolean(cantBoost)}
                    timestamp={get(metacellAction, ['evolutionTimestamp'])}
                    isSafeNumber={isSafeNumber}
                    setIsSafeNumber={setIsSafeNumber}
                  />
                )}
                <ConfirmButton
                  confirmButtonHandler={labButtonHandler}
                  text={activeLabTab.slice(0, activeLabTab?.length - 5)}
                  disabled={isButtonDisabled}
                  sx={{
                    width: '140px',
                    height: '36px',
                    mb: isWraped ? '20px' : '30px',
                  }}
                />
              </Grid>
              {bracketRight}
            </Grid>
            <RightSideLab
              {...{
                message,
                outcome,
                detailsButtonHandler,
                setOutcome,
                showSplit,
                split,
                setShowSplit,
                setSplit,
              }}
            />
          </Grid>
        </Grid>
      </DragDropContext>
      <LoadingModal open={showConfirmModal} text={errorMessage} mining={mining} />
      <LabLoading
        open={showLoadingModal}
        setShowLoadingModal={() => {}}
        activeLabTab={activeLabTab}
      />
      <LabError
        open={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        message={errorMessage}
      />

      <CellDetailsModal
        open={showDetails}
        detailsButtonHandler={detailsButtonHandler}
        handleClose={() => setShowDetails(false)}
        cell={currentToken}
      />
    </LabContainerStyled>
  );
};

export default memo(MainComponent);
