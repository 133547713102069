import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useAppStore } from '@store/index';
import numeral from 'numeral';
import React, { useEffect, useMemo, useState } from 'react';
import { MetacellWrapper } from './styled';

export const CreateMetacellInfo = ({ metacellCount }) => {
  const { usersMetacells } = useAppStore();

  const [open, setOpen] = useState(false);

  const metacellMinted = useMemo(
    () =>
      open
        ? [...usersMetacells].filter((_, idx) => idx >= usersMetacells.length - metacellCount)
        : [],
    [metacellCount, open, usersMetacells]
  );

  useEffect(() => {
    if (metacellCount) {
      setOpen(true);
    }

    return () => {
      setOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metacellCount]);

  return (
    <MetacellWrapper
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}>
      <Grid class="modal__wrapper">
        <Box class="close_box">
          <img
            className="close_icon"
            src="/icons/modal-close-icon.svg"
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </Box>
        <Typography variant="modalId">
          {`Congratulations! You have minted ${metacellCount} Metacell${
            metacellCount === 1 ? '' : 's'
          } `}
        </Typography>
        <Grid container justifyContent="space-around" gap="10px" mt={3}>
          {metacellMinted.map((cell) => (
            <Grid
              key={cell?.id}
              container
              direction="column"
              alignItems="center"
              sx={{
                width: '279px',
                background: '#17181C',
                border: `1px solid ${'#202228'}`,
                boxShadow: 'none',
                borderRadius: '14px',
                p: '19px 19px 24px',
              }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ background: '#000', borderRadius: '14px', overflow: 'hidden' }}>
                <Box sx={{ width: '100%', height: '129px' }}>
                  <img
                    src={`${process.env.REACT_APP_GIF_URL}/MetaCell_${numeral(1).format(
                      '000'
                    )}.gif`}
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                    alt="cell"
                  />
                </Box>
              </Grid>
              <Grid container justifyContent="space-between" wrap="nowrap" gap="5px" mt="20px">
                <Grid container alignItems="center" gap="8px">
                  <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
                    Token ID
                  </Typography>
                  <Typography
                    variant="tokenId"
                    sx={{
                      background: 'rgba(255, 255, 255, 0.08)',
                      borderRadius: '4px',
                      height: '19px',
                      p: '0 4px',
                    }}>
                    {`# ${cell.id}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                wrap="nowrap"
                gap="5px"
                alignItems="center"
                sx={{ mt: '3px', overflow: 'hidden' }}>
                <Typography>
                  <Box>{`Metacell #${cell?.id}`}</Box>
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Grid container className="button">
            <Button onClick={() => setOpen(false)} variant="contained">
              OK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </MetacellWrapper>
  );
};
