import BuyButton from '@components/Buttons/BuyButton';
import MintButton from '@components/Buttons/MintButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import SellButton from '@components/Buttons/SellButton';
import { useTicker, useWrapDetect } from '@hooks';
import { Box, CircularProgress, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import { useContracts } from '@providers';
import formatLongPrice from '@utils/formatLongPrice';
import { useWeb3React } from '@web3-react/core';
import _get from 'lodash/get';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import FieldCellDetailWithoutPrice from '../CellDetailsModal/FieldCellDetailWithoutPrice';
import { ScientistModalContentWrapper } from './styles';

const formatScientistSkill = (token) => {
  const points = _get(token, 'point');
  return {
    biology: Number(points?.biology),
    chemistry: Number(points?.chemistry),
    mathematics: Number(points?.mathematics),
    physics: Number(points?.physics),
    sociology: Number(points?.sociology),
  };
};

const ScientistModal = ({
  open,
  detailsButtonHandler,
  scientist,
  handleClose,
  buyButtonHandler,
  sellButtonHandler,
  removeButtonHandler,
  editPriceButtonHandler,
  mintButtonHandler,
}) => {
  const isTablet = useMediaQuery('(max-width:931px)');
  const { account } = useWeb3React();
  const contracts = useContracts();
  const { Laboratory, Scientist } = contracts;
  const ref = useRef(null);
  const [scientistToken, setScientistToken] = useState(null);
  const [mintable, setMintable] = useState(null);
  const [loading, setLoading] = useState({
    mintableLoading: false,
    attributesLoading: false,
  });
  const isWrapped = useWrapDetect(open, ref, 465);
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();

  const { mintableLoading, attributesLoading } = loading;

  const getScientistMintStatus = async () => {
    let mintable = false;
    if (scientist?.id) {
      setLoading((loading) => ({ ...loading, mintableLoading: true }));
      mintable = await Laboratory.isCanCreateMetaCell(scientist?.id);
    }
    setMintable(mintable);
    setLoading((loading) => ({ ...loading, mintableLoading: false }));
  };

  useEffect(() => {
    if (scientist && !Object.keys(scientist).includes('mintable')) {
      getScientistMintStatus();
    } else {
      setMintable(scientist?.mintable);
    }
    setScientistToken(scientist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scientist]);

  const getScientist = async (id) => {
    if (!id) return;
    setLoading((loading) => ({ ...loading, attributesLoading: true }));
    const result = await Scientist.getScientist(id);
    const attributes = formatScientistSkill(result);
    setScientistToken({ ...scientist, attributes });
    setLoading((loading) => ({ ...loading, attributesLoading: false }));
  };

  useEffect(() => {
    if (scientist && !Object.keys(scientist).includes('skills')) {
      if (scientist?.id) {
        getScientist(scientist?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scientist]);

  const buttonHandler = useMemo(() => {
    if (
      account?.toLowerCase() === scientistToken?.sellerAddress?.toLowerCase() ||
      account?.toLowerCase() === scientistToken?.buyerAddress?.toLowerCase()
    ) {
      if (scientistToken?.onSale) {
        return (
          <Grid container justifyContent="space-between" alignItems="center" sx={{ width: 'auto' }}>
            <RemoveButton removeButtonHandler={removeButtonHandler} sx={{ width: '190px' }} />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '1px solid #09EBF6',
                borderRadius: '100px',
                width: '32px',
                height: '32px',
                cursor: 'pointer',
                marginLeft: '10px',
                ':hover': { background: '#09595e' },
              }}
              onClick={() => {
                editPriceButtonHandler();
              }}>
              <img src="/images/Pencil.png" width="12px" height="12px" alt="edit" />
            </Grid>
          </Grid>
        );
      }
      return sellButtonHandler ? (
        <SellButton sellButtonHandler={sellButtonHandler} sx={{ width: '190px' }} />
      ) : null;
    }
    return buyButtonHandler ? (
      <BuyButton buyButtonHandler={buyButtonHandler} sx={{ width: '190px' }} />
    ) : null;
  }, [account, scientistToken]);
  const slicedAccount = (account) => {
    let slicedAcc = '';
    if (account) {
      const arr = account.split('');
      slicedAcc = arr.slice(0, 5).concat('....', arr.slice(-4)).join('');
    }
    return slicedAcc;
  };
  const walletAddress = useMemo(() => {
    let addrs;
    if (scientistToken?.buyerAddress) {
      addrs = scientistToken?.buyerAddress;
    } else if (scientistToken?.sellerAddress) {
      addrs = scientistToken?.sellerAddress;
    } else {
      addrs = scientistToken?.owner;
    }

    if (`${addrs}`.toLowerCase() === account.toLowerCase()) {
      return 'you';
    }
    return slicedAccount(addrs);
  }, [account, scientistToken?.buyerAddress, scientistToken?.owner, scientistToken?.sellerAddress]);
  return (
    <Modal
      disablePortal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        '@media only screen and (max-width: 931px)': { margin: '20px' },
      }}
      onClose={handleClose || detailsButtonHandler}>
      <ScientistModalContentWrapper loading={mintableLoading || attributesLoading ? 'loading' : ''}>
        <Grid
          ref={ref}
          container
          direction="column"
          sx={{
            maxWidth: '918px',
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(20px)',
            borderRadius: '14px',
            p: '26px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          {mintableLoading || attributesLoading ? (
            <Grid item className="scientist_loading" lg={12}>
              <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
            </Grid>
          ) : null}
          <img
            src="/icons/modal-close-icon.svg"
            alt="close-icon"
            onClick={handleClose || detailsButtonHandler}
            className="close_icon"
          />
          <Grid
            container
            justifyContent="center"
            gap="33px"
            sx={{
              p: '7px 19px 18px 18px',
              '@media screen and (max-height: 760px)': {
                maxHeight: '560px',
                overflowY: 'scroll',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': { display: 'none' },
                p: '7px 0px 18px 0px',
              },
            }}>
            <Box
              sx={{
                width: isTablet ? '100%' : '316px',
                height: '316px',
                '@media only screen and (max-width: 600px)': { height: '158px' },
              }}>
              <React.Fragment
                key={`${process.env.REACT_APP_RESOURCE}/scientist/gif/${scientistToken?.id}.gif`}>
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={`${process.env.REACT_APP_RESOURCE}/scientist/gif/${scientistToken?.id}.gif`}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    borderRadius: '14px',
                    boxShadow: 'inset 0px 0px 50px 15px rgba(0, 0, 0, 0.3)',
                  }}>
                  <source
                    src={`${process.env.REACT_APP_RESOURCE}/scientist/mp4/${scientistToken?.id}.mp4`}
                    type="video/mp4"
                  />
                </video>
              </React.Fragment>
            </Box>
            <Grid
              container
              direction="column"
              alignItems={isWrapped ? 'center' : 'flex-start'}
              sx={{ width: 'auto', flexGrow: 1 }}>
              <Grid container alignItems="center" gap="8px">
                <Typography variant="modalId" sx={{ opacity: 0.4, height: '21px' }}>
                  Token ID
                </Typography>
                <Typography
                  variant="modalId"
                  sx={{
                    background: 'rgba(255, 255, 255, 0.08)',
                    borderRadius: '4px',
                    height: '21px',
                    p: '0 4px',
                  }}>
                  {`# ${scientistToken?.id}`}
                </Typography>
              </Grid>
              <Typography
                ref={nameRef}
                variant="modalName"
                sx={{
                  height: '42px',
                  mt: '3px',
                  width: '100%',
                  justifyContent: 'flex-start',
                  overflow: 'hidden',
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  },
                  '&:hover:after': {
                    width: 0,
                    height: 0,
                  },
                  '@media screen and (max-width: 576px)': {
                    fontSize: '24px',
                  },
                }}>
                <Box
                  sx={{
                    '&:hover': isNameOverflown && {
                      transform: `translateX(-${nameOffset}px)`,
                      transition: `transform ${nameTransitionTime}s`,
                    },
                  }}>
                  {`Mad Metascientist #${scientistToken?.id}`}
                </Box>
              </Typography>
              <Grid container alignItems="center" gap="8px" sx={{ width: '100%', mt: '25px' }}>
                <Typography variant="modalId" sx={{ opacity: 0.4, height: '21px' }}>
                  Owned by
                </Typography>
                <Typography
                  ref={nameRef}
                  variant="modalId"
                  sx={{
                    height: '21px',
                    justifyContent: 'flex-start',
                    maxWidth: isWrapped ? '300px' : '480px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    '&:after': {
                      content: '""',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                    },
                    '&:hover:after': {
                      width: 0,
                      height: 0,
                    },
                  }}>
                  <Box
                    sx={{
                      '&:hover': isNameOverflown && {
                        transform: `translateX(-${nameOffset}px)`,
                        transition: `transform ${nameTransitionTime}s`,
                      },
                    }}>
                    {walletAddress}
                  </Box>
                </Typography>
              </Grid>
              <Grid container alignItems="center" gap="8px" sx={{ width: '100%' }}>
                <FieldCellDetailWithoutPrice onSale={scientistToken?.onSale} />
              </Grid>
              {/* {account === scientistToken?.sellerAddress || account === scientistToken?.owner ? (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mt: '30px',
                  }}>
                  <Typography
                    variant="modalId"
                    sx={{
                      height: '21px',
                      color: scientistToken?.onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.2)',
                      fontWeight: 400,
                      fontSize: '18px',
                    }}>
                    {scientistToken?.onSale ? 'On sale' : 'Not on sale'}
                  </Typography>
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      background: scientistToken?.onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.1)',
                      boxShadow: scientistToken?.onSale
                        ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)'
                        : 'none',
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mt: '30px',
                  }}>
                  <Typography variant="priceWrapper">
                    <Typography
                      variant="priceText"
                      sx={{ opacity: 0.4, fontSize: '18px', fontWeight: 400 }}>
                      PRICE
                    </Typography>
                    <Typography
                      variant="price"
                      sx={{ opacity: 1, fontSize: '18px', fontWeight: 700, ml: '10px' }}>
                      {`${formatLongPrice(scientistToken?.price)} `}
                      ETH
                    </Typography>
                  </Typography>
                  <BuyButton sx={{ width: '190px' }} buyButtonHandler={buyButtonHandler} />
                </Grid>
              )} */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  mt: '30px',
                }}>
                <Typography variant="priceWrapper" mb="5px">
                  <Typography
                    variant="priceText"
                    sx={{ opacity: 0.4, fontSize: '18px', fontWeight: 400 }}>
                    PRICE
                  </Typography>
                  <Typography
                    variant="price"
                    sx={{ opacity: 1, fontSize: '18px', fontWeight: 700, ml: '10px' }}>
                    {`${formatLongPrice(scientistToken?.price)} `}
                    ETH
                  </Typography>
                </Typography>
                {buttonHandler}
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                gap="9px"
                sx={{
                  mt: '25px',
                  pb: '30px',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
                }}>
                <Grid
                  container
                  direction="column"
                  gap="9px"
                  sx={{ flexBasis: 'calc(50% - 4.5px)' }}>
                  <Grid container justifyContent="space-between" className="attribute">
                    <Typography variant="modalAttribute" className="label">
                      Physics:
                    </Typography>
                    <Typography variant="modalAttribute">
                      {scientistToken?.attributes?.physics}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between" className="attribute">
                    <Typography variant="modalAttribute" className="label">
                      Chemistry:
                    </Typography>
                    <Typography variant="modalAttribute">
                      {scientistToken?.attributes?.chemistry}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between" className="attribute">
                    <Typography variant="modalAttribute" className="label">
                      Biology:
                    </Typography>
                    <Typography variant="modalAttribute">
                      {scientistToken?.attributes?.biology}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  gap="9px"
                  sx={{ flexBasis: 'calc(50% - 4.5px)' }}>
                  <Grid container justifyContent="space-between" className="attribute">
                    <Typography variant="modalAttribute" className="label">
                      Sociology:
                    </Typography>
                    <Typography variant="modalAttribute">
                      {scientistToken?.attributes?.sociology}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="space-between" className="attribute">
                    <Typography variant="modalAttribute" className="label">
                      Mathematics:
                    </Typography>
                    <Typography variant="modalAttribute">
                      {scientistToken?.attributes?.mathematics}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <MintButton
                disabled={!mintable}
                mintButtonHandler={mintButtonHandler}
                sx={{
                  mt: '33px',
                  alignSelf: 'center',
                  opacity: '1',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </ScientistModalContentWrapper>
    </Modal>
  );
};

export default ScientistModal;
