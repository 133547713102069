import { styled } from '@mui/material/styles';

export const ModuleBoxCardWrapper = styled('div')`
  .wrapper {
    width: 248px;
    background-color: #17181c;
    border: 1px solid #202228;
    border-radius: 14px;
    padding: 20px;
    &--large {
      width: 100%;
    }
    .card__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &_name {
        max-width: 50%;
        width: 50%;
        .module_name {
          width: 100%;
          position: relative;
          text-overflow: clip;
          justify-content: left;
          &:after {
            content: '';
            width: 100%;
            height: 24px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(to right, transparent 80px, #17181c);
          }
          &:hover {
            &:after {
              display: none;
            }
          }
        }
      }
      &_price {
        max-width: 50%;
        width: 50%;
        .module_id_text {
          justify-content: right;
          height: 23px;
          .module_id_label {
            height: 23px;
            display: flex;
            align-items: center;
            .edit_price__icon {
              margin-right: 7px;
              cursor: pointer;
            }
          }
        }
        .module_name {
          font-weight: 700;
          width: 100%;
          justify-content: right;
        }
      }
    }
  }
  .cover_img__wrapper {
    width: 100%;
    height: 122px;
    background: #000;
    border-radius: 14px;
  }
  .cover_img {
    width: 85px;
    height: 76px;
  }
  .module_id_text {
    margin-top: 20px;
    font-size: 13px;
    justify-content: left;
    width: 100%;
  }
  .module_name {
    width: 100%;
    justify-content: left;
  }
  .module_id_label {
    color: #ffffff66;
  }
  .module_id {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    padding: 4px 6px;
    margin-left: 8px;
    font-size: 13px;
    color: #fff;
  }
  .title {
    margin-top: 4px;
    justify-content: left;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
  }
  .description {
    justify-content: left;
    width: 100%;
    margin-top: 15px;
    color: #b9b9b9;
    white-space: normal;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    font-weight: 500;
    font-size: 12px;
  }
`;
