/* eslint-disable react/jsx-one-expression-per-line */
import { ButtonBanner } from '@components/Forms/Feedbacks/styles';
import { useWeb3React } from '@web3-react/core';
import { environment } from '../../environment';
import { BannerContent, WrapperBanner } from './style';

export default function Banner() {
  const onHandleClick = () => {
    window.open(environment.GG_FORM_URL);
  };

  return (
    <WrapperBanner>
      <BannerContent>
        This is the alpha environment, if you have any feedback, please click{' '}
        <>
          <ButtonBanner variant="outlined" onClick={onHandleClick}>
            Here
          </ButtonBanner>{' '}
          to send your feedback. Thank you
        </>
      </BannerContent>
    </WrapperBanner>
  );
}
