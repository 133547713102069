import { styled, Modal, Typography } from '@mui/material';

export const MetacellWrapper = styled(Modal)`
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 931px) {
    margin: 20px;
  }
  .close_box {
    display: flex;
    justify-content: flex-end;
  }
  .close_icon {
    cursor: pointer;
    align-self: flex-end;
  }
  .MuiTypography-root {
    white-space: unset;
    text-align: center;
    margin-top: 5px;
  }
  .modal__wrapper {
    max-width: 918px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 14px;
    padding: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
      outline: none;
    }
  }
  .loading__wrapper {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    width: 100%;
    height: 100%;
    transform: translate(-26px, -26px);
    .loading__content {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .cover_image_wrapper {
    width: 180px;
    height: 180px;
    @media (max-width: 600px) {
      height: 158px;
    }
  }
  .cover__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    box-shadow: inset 0px 0px 50px 15px rgba(0, 0, 0, 0.3);
  }
  .id_wrapper {
    opacity: 0.4;
    height: 15px;
  }
  .id_text {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    height: 19px;
    padding: 0 4px;
  }
  .name {
    height: 19px;
    margin-top: 3px;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:hover:after {
      width: 0;
      height: 0;
    }
  }
  .name__wrapper {
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    margin-top: 3px;
    overflow: hidden;
    white-space: nowrap;
  }
  .button {
    gap: 8;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .abilities {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
    justify-content: left;
    width: 100%;
    display: inline-block;
    .abilities_rarity {
      text-transform: capitalize;
      color: #7ebaff;
    }
  }
  .sale_status {
    margin-top: 19px;
  }
  .describe {
    color: #b9b9b9;
    white-space: normal;
    text-align: left;
    margin-top: 18px;
    width: 100%;
    max-height: 60px;
    font-size: 14px;
    font-weight: 500;
    align-items: stretch;
    justify-content: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
