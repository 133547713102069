import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { useTimer } from '@hooks';
import Countdown from 'react-countdown';
import CountdownEvolution from './CountdownEvolution';

const maxDaysToEvolution = Math.floor(3562000 / (60 * 60 * 24));
const EvolutionClock = ({ id, timestamp, setReadyForEvolution, sx }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const time = days || hours || minutes || seconds;
    return (
      <CountdownEvolution
        id={id}
        setReadyForEvolution={setReadyForEvolution}
        time={time}
        sx={sx}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        completed={completed}
      />
    );
  };

  return <Countdown date={timestamp} renderer={renderer} />;
};

export default EvolutionClock;
