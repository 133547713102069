import { useContracts } from '@providers/ContractsProvider';
import { formatMetacellData } from '@utils';
import { useWeb3React } from '@web3-react/core';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useMetacellUsersQuery } from '../../../hooks/contracts/metacell/useMetacellQuery';
import { useLabSlotAction } from './hooks';

export const useListenEvolutionEvent = () => {
  const { _updateCellOutcome = () => {}, _onDragReset = () => {} } = useLabSlotAction();
  const [preEvolved, updatePreEvolved] = useState();
  const [preBoosted, updatePreBoosted] = useState();
  const { Laboratory } = useContracts();
  const { account, library } = useWeb3React();
  const { refetch } = useMetacellUsersQuery();

  useEffect(() => {
    const handleEvent = (e, { bag, pre, updatePre }) => {
      const userAddress = `${get(e, ['1', '1'], '')}`.toLowerCase();
      const tokenId = get(e, ['1', '0']).toString();
      const event = get(e, 0);
      if (tokenId === pre) return;
      updatePre(tokenId);
      if (userAddress !== account.toLowerCase()) return;
      _onDragReset();
      const cell = formatMetacellData(get(e, 1, null), {
        blockNumber: library.blockNumber,
        queryDate: library._fastQueryDate,
      });
      refetch();
      _updateCellOutcome({
        cell,
        event,
        bag,
      });
    };

    if (Laboratory && library && _updateCellOutcome) {
      console.log('New Evolution Completed');
      Laboratory.on('NewEvolutionCompleted', (...e) =>
        handleEvent(e, { bag: 'Evolved!', pre: preEvolved, updatePre: updatePreEvolved })
      );

      Laboratory.on('EvolutionTimeReduced', (...e) =>
        handleEvent(e, { bag: 'Boosted!', pre: preBoosted, updatePre: updatePreBoosted })
      );
    }
    return () => {
      if (Laboratory) Laboratory.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Laboratory, _updateCellOutcome, account, library]);
};
