import { Box, Modal } from '@mui/material';
import { useState } from 'react';

import TopMetacellTable from './TopMetacellTable';
import { useMobileScreen } from './useMobileScreen';

const leaderboardBodySx = (width, isMb = false) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: isMb ? 'start' : 'center',
  borderRadius: 8,
  width,
  border: 'none',
  color: 'inherit',
  fontFamily: 'Montserrat,monospace',
  outline: 'none',
  '.MuiTypography-root': {
    whiteSpace: 'normal',
  },
});

const backgroundImgSx = {
  zIndex: -1,
  position: 'absolute',
  maxWidth: '90vw',
  maxHeight: '90vh',
  height: 'auto',
  width: 'auto',
};

const backgroundImgMbSx = {
  zIndex: -1,
  position: 'absolute',
  paddingBottom: '85vh',
};

export const LeanderBoardModal = ({ open, handleClose }) => {
  const [bgState, setBgState] = useState({ width: 1296, height: 734 });
  const isMobile = useMobileScreen();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        background: 'transparent',
        width: bgState?.width,
        '&:focus-visible': {
          outline: 'none',
        },
      }}
      aria-labelledby="m-title-leaderboard"
      aria-describedby="m-description-leaderboard">
      <Box sx={leaderboardBodySx(bgState?.width, isMobile)}>
        <img
          onLoad={(e) => {
            setBgState({
              width: e.target?.clientWidth,
              height: e.target?.clientHeight,
            });
          }}
          onClick={() => {
            if (isMobile) {
              handleClose();
            }
          }}
          src={
            isMobile
              ? '/images/ranking/leaderboard.png'
              : '/images/ranking/background-leaderboard.png'
          }
          alt="leaderboard"
          style={isMobile ? backgroundImgMbSx : backgroundImgSx}
        />

        <Box
          sx={{
            width: bgState?.width,
            height: bgState?.height - 140,
            p: isMobile ? 0 : 4,
            mt: isMobile ? '9%' : '4%',
            mx: '1%',
            background:
              'linear-gradient(111.03deg, rgba(3, 3, 3, 0.32) 2.82%, rgba(3, 3, 3, 0.16) 90.94%)',
            backdropFilter: 'blur(20px)',
          }}>
          <TopMetacellTable bgHeigh={bgState?.height - 240} />
        </Box>
      </Box>
    </Modal>
  );
};
