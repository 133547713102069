import { useRef, useEffect, useState } from 'react';
import { Modal, Grid, Box, Typography, CircularProgress } from '@mui/material';
import { useWrapDetect, useTicker } from '@hooks';
import _includes from 'lodash/includes';
import BuyButton from '@components/Buttons/BuyButton';
import SellButton from '@components/Buttons/SellButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import formatLongPrice from '@utils/formatLongPrice';
import { CellModuleDetailsModalWrapper, HrGap, ListDetailItems, WrapperImage } from './styles';

const CellModuleDetailsModal = ({
  open,
  detailsButtonHandler,
  module,
  handleClose,
  isNewModule = false,
  loading = false,
  activeTab = '',
  handleEditPriceButtonHandler = () => {},
  setCurrentToken = () => {},
  sellButtonHandler = () => {},
  removeButtonHandler = () => {},
  buyButtonHandler = () => {},
  editPriceButtonHandler = () => {},
}) => {
  const [specKeys, setSpecKeys] = useState(null);
  const ref = useRef(null);
  const isWrapped = useWrapDetect(open, ref, 463);
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();

  useEffect(() => {
    if (module && module?.specs) {
      const keys = Object.keys(module.specs);
      setSpecKeys(keys);
    }
  }, [module]);

  return (
    <Modal
      disablePortal
      open={open}
      BackdropProps={{ sx: { background: activeTab ? 'unset' : 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      onClose={handleClose || detailsButtonHandler}>
      <CellModuleDetailsModalWrapper>
        <Grid
          ref={ref}
          container
          direction="column"
          className="modal__wrapper"
          sx={{
            position: isWrapped ? 'static' : 'absolute',
            transform: isWrapped ? 'none' : 'translate(-50%, -50%)',
          }}>
          <div className="loading__wrapper">
            {isNewModule && loading ? (
              <div className="loading__content">
                <Grid
                  item
                  lg={12}
                  sx={{
                    p: 0,
                    alignSelf: 'center',
                    width: '100%',
                    height: '100%',
                    mt: 'calc(25% - 52px)',
                    textAlign: 'center',
                  }}>
                  <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
                </Grid>
              </div>
            ) : null}
          </div>

          <img
            src="/icons/modal-close-icon.svg"
            alt="close-icon"
            onClick={handleClose || detailsButtonHandler}
            className="close_icon"
          />
          <Grid container justifyContent="center" gap="33px" sx={{ p: '7px 19px 18px 18px' }}>
            <WrapperImage>
              {isNewModule ? (
                <Typography variant="modalNewModuleText" className="new_module_text">
                  Received new module!
                </Typography>
              ) : null}
              <Box
                sx={{ width: '316px', height: '316px', borderRadius: '14px', overflow: 'hidden' }}>
                {module?.image ? (
                  <img
                    src={module?.image}
                    className="cover__image"
                    alt="module"
                    width="316px"
                    height="316px"
                  />
                ) : (
                  <img src="/images/gunModule.svg" width="316px" height="316px" alt="module" />
                )}
              </Box>
            </WrapperImage>

            <Grid
              container
              direction="column"
              alignItems={isWrapped ? 'center' : 'flex-start'}
              sx={{ width: 'calc(100% - 349px)', flexGrow: 1 }}>
              <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
                <Typography variant="modalId" className="id_text">
                  Token ID
                </Typography>
                <Typography variant="modalId" className="module_id">
                  {`# ${module?.id}`}
                </Typography>
              </Grid>
              <Typography
                ref={nameRef}
                variant="modalName"
                className="name__wrapper"
                sx={{
                  maxWidth: isWrapped ? '300px' : '480px',
                }}>
                <Box
                  sx={{
                    '&:hover': isNameOverflown && {
                      transform: `translateX(-${nameOffset}px)`,
                      transition: `transform ${nameTransitionTime}s`,
                    },
                  }}
                  className="name">
                  {module?.name}
                </Box>
              </Typography>
              <Typography variant="cellModuleAbility" className="abilities">
                <span className="abilities_rarity">{`(${module?.abilities?.rarity}) `}</span>
                <span className="abilities_type">{module?.abilities?.type}</span>
              </Typography>
              {activeTab ? (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mt: '30px',
                  }}>
                  <Typography variant="priceWrapper">
                    <Typography
                      variant="priceText"
                      sx={{ opacity: 0.4, fontSize: '18px', fontWeight: 400 }}>
                      PRICE
                    </Typography>
                    <Typography
                      variant="price"
                      sx={{ opacity: 1, fontSize: '18px', fontWeight: 700, ml: '10px' }}>
                      {`${formatLongPrice(module?.price)} `}
                      META
                    </Typography>
                    {activeTab !== 'marketplace' && module?.onSale ? (
                      <Typography sx={{ cursor: 'pointer', marginLeft: '10px' }}>
                        <img
                          src="/icons/edit-pencil-icon.svg"
                          alt="edit_price"
                          onClick={() => {
                            setCurrentToken(module);
                            editPriceButtonHandler(module);
                          }}
                        />
                      </Typography>
                    ) : null}
                  </Typography>
                  {activeTab === 'marketplace' ? (
                    <BuyButton
                      sx={{ width: '125px' }}
                      buyButtonHandler={() => {
                        setCurrentToken(module);
                        buyButtonHandler();
                      }}
                    />
                  ) : module?.onSale ? (
                    <RemoveButton
                      sx={{ width: '190px' }}
                      removeButtonHandler={() => {
                        setCurrentToken(module);
                        removeButtonHandler();
                      }}
                    />
                  ) : (
                    <SellButton
                      sx={{ width: '190px' }}
                      sellButtonHandler={() => {
                        setCurrentToken(module);
                        sellButtonHandler();
                      }}
                    />
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className="sale_status">
                  <Typography
                    variant="modalId"
                    sx={{
                      height: '21px',
                      color: module?.onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.2)',
                      fontWeight: 400,
                      fontSize: '18px',
                    }}>
                    {module?.onSale ? 'On sale' : 'Not on sale'}
                  </Typography>
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      background: module?.onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.1)',
                      boxShadow: module?.onSale
                        ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)'
                        : 'none',
                    }}
                  />
                </Grid>
              )}

              <Typography variant="cellModuleDescribe" className="describe">
                {module?.describe}
              </Typography>
              <HrGap />
              {module?.specs && specKeys ? (
                <ListDetailItems>
                  {specKeys?.map((spec) => (
                    <SpecItem spec={spec} points={module.specs[spec]} key={spec} />
                  ))}
                </ListDetailItems>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </CellModuleDetailsModalWrapper>
    </Modal>
  );
};

export default CellModuleDetailsModal;

const SpecItem = ({ spec, points }) => {
  const renderIcon = (spec) => {
    switch (spec) {
      case 'moduleHp':
        return '/icons/CellModule/distance.svg';
      case 'distance':
        return '/icons/CellModule/distance.svg';
      case 'damage':
        return '/icons/CellModule/distance.svg';
      case 'ec':
        return '/icons/CellModule/distance.svg';
      case 'range':
        return '/icons/CellModule/distance.svg';
      case 'angle':
        return '/icons/CellModule/distance.svg';
      case 'cooldown':
        return '/icons/CellModule/cooldown.svg';
      case 'battery':
        return '/icons/CellModule/battery.svg';
      case 'speed':
        return '/icons/CellModule/speed.svg';
      case 'volley':
        return '/icons/CellModule/volley.svg';
      case 'holder':
        return '/icons/CellModule/holder.svg';
      default:
        return '/icons/CellModule/holder.svg';
    }
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        border: '0.5px solid rgba(255, 255, 255, 0.4) ',
        borderRadius: '7px',
        padding: '6px 9px',
      }}>
      <div style={{ display: 'flex', gap: '5px' }}>
        <img src={renderIcon(spec)} alt="admin" width="20px" height="20px" />
        <Typography
          variant="modalAttribute"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: '#e6e6e6',
            textTransform: 'capitalize',
          }}>
          {`${spec} :`}
        </Typography>
      </div>
      <Typography
        variant="modalAttribute"
        sx={{ color: '#ffffff', fontSize: '14px', fontWeight: 500 }}>
        {points}
      </Typography>
    </Grid>
  );
};
