import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import maxBy from 'lodash/maxBy';
import numeral from 'numeral';
import React from 'react';

import { useMetacellUsersQuery } from '@hooks/contracts/metacell/useMetacellQuery';
import { formatShortAddress } from '../../../utils/formatShortAddress';
import { useMetacellLeaderboardQuery } from './useMetacellLeaderboardQuery';
import { useMobileScreen } from './useMobileScreen';

const showTextForUserMetacell = (inRank, distance) => {
  if (inRank) return `Congratulation! you ranked ${numeral(inRank).format('0o')}. `;
  return `Only Evolve ${distance} levels left and you'll be in the top 30. `;
};

const leaderboardStyled = {
  th: { height: '52px', fontWeight: 700, fontSize: '1rem' },
  'td,th': {
    borderBottom: 'none',
    background: '#4b1c887e0',
    backdropFilter: 'blur(20px)',
  },
};

const tableContainerStyled = (height) => ({
  maxHeight: height,
  border: 'none',
  borderRadius: '10px',
  msOverflowStyle: 'none',
  scrollbarWidth: '1px',
  '&::-webkit-scrollbar': { width: 2 },
});

const loadingBoxStyle = {
  height: '100%',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
};

const inLeaderboardStyle = { display: 'flex', justifyContent: 'center', border: 0 };

const leaderboardCellStyle = {
  color: '#09EBF6',
  fontWeight: '700',
  textShadow: '0px 0px 10px 4px rgb(9 235 246 / 25%)',
  border: 'none',
};

function MetacellRow({
  rank,
  metacell: { owner, tokenId, stage },
  isInTable,
  isUser,
  isHighlight,
}) {
  const isMobile = useMobileScreen();

  return (
    <TableRow
      component={Box}
      sx={getRowLeaderboardStyle(isInTable, isUser, isHighlight, rank, isMobile)}>
      {!isInTable ? (
        <>
          <TableCell sx={{ ...leaderboardCellStyle, padding: 0 }}>
            {`Your Ultimate Metacell: Metacell #${tokenId} (Lvl. ${stage})`}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>
            <Box
              textAlign="center"
              sx={
                isMobile && {
                  background:
                    'linear-gradient(180deg, rgba(0, 170, 254, 0.502) 0%, rgba(141, 66, 233, 0.502) 100%)',
                  p: '4px',
                  textAlign: 'center',
                  borderRadius: '4px',
                }
              }>
              {rank || '30+'}
            </Box>
          </TableCell>
          <TableCell component="th" scope="row">
            {` ${formatShortAddress(owner)} ${isUser ? '(You)' : ''}`}
            <br />

            {`${isMobile ? `Metacell #${tokenId} (Lvl. ${stage})` : ''}`}
          </TableCell>
          {!isMobile && (
            <>
              <TableCell>{`Metacell #${tokenId}`}</TableCell>
              <TableCell>{`Lvl. ${stage}`}</TableCell>
            </>
          )}
        </>
      )}
    </TableRow>
  );
}

function LeaderBoardField() {
  const isMobile = useMobileScreen();

  return (
    <TableHead>
      <TableRow
        sx={{
          ...leaderboardStyled,
          ...(isMobile && {
            'td,th': {
              background:
                'linear-gradient(180deg, rgba(0, 170, 254, 1) 0%, rgba(141, 66, 233, 1) 100%)',
            },
          }),
        }}>
        <TableCell align="center">#</TableCell>
        <TableCell>User Address</TableCell>
        {!isMobile && (
          <>
            <TableCell>ID</TableCell>
            <TableCell>Level</TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

function getRowLeaderboardStyle(isInTable, isUser, isHighlight, rank, isMobile) {
  return !isInTable
    ? inLeaderboardStyle
    : {
        th: { height: '52px' },
        'td,th': {
          border: 'none',
        },
        ...(!isMobile && {
          backgroundImage:
            rank % 2
              ? 'linear-gradient(180deg, rgba(0, 170, 254, 0.502) 0%, rgba(141, 66, 233, 0.502) 100%)'
              : 'transparent',
        }),
      };
}

export default function TopMetacellTable({ bgHeigh }) {
  const { data, isLoading } = useMetacellLeaderboardQuery();
  const { data: userMetacell } = useMetacellUsersQuery();
  const isMobile = useMobileScreen();

  const ownMetacell = React.useMemo(() => maxBy(userMetacell, 'stage'), [userMetacell]);

  const ownerIdx = React.useMemo(() => data?.findIndex((d) => d.owner === ownMetacell?.owner) + 1, [
    data,
    ownMetacell,
  ]);

  const levelDistance = React.useMemo(
    () => (isLoading ? 0 : data[data?.length - 1]?.stage - ownMetacell?.stage + 1),
    [data, isLoading, ownMetacell?.stage]
  );

  return isLoading ? (
    <Box sx={loadingBoxStyle}>
      <CircularProgress sx={{ color: 'white' }} />
    </Box>
  ) : (
    <Box>
      <TableContainer sx={tableContainerStyled(bgHeigh)}>
        <Table sx={{ minWidth: isMobile ? '90vw' : 650 }} stickyHeader size="small">
          <LeaderBoardField />
          <TableBody>
            {data.map((cell, i) => (
              <MetacellRow
                metacell={cell}
                rank={i + 1}
                isInTable
                isUser={cell.owner === ownMetacell?.owner}
                isHighlight={Number(cell.tokenId) === Number(ownMetacell?.tokenId)}
                key={cell.pKey}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {ownMetacell && (
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'center',
            fontSize: '1.5rem',
            ...(isMobile && {
              background: 'url("/images/ranking/bg-bottom-text.png") no-repeat',
              height: '100px',
              backgroundSize: 'contain',
              alignItems: 'flex-start',
              '*': {
                fontSize: '.72rem !important',
                textAlign: 'center !important',
              },
            }),
          }}>
          <Box sx={{ width: '55%', paddingTop: '4px' }}>
            <Typography sx={{ textTransform: 'capitalize' }}>
              {showTextForUserMetacell(ownerIdx, levelDistance)}
            </Typography>
            <MetacellRow metacell={ownMetacell} rank={ownerIdx} isUser />
          </Box>
        </Box>
      )}
    </Box>
  );
}
