import { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import { useContracts } from '@providers/index';
import ConfirmButton from '@components/Buttons/ConfirmButton';
import BuyButton from '@components/Buttons/BuyButton';
import SellButton from '@components/Buttons/SellButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import { ModuleBoxCardWrapper } from './styles';

const ModuleBoxCard = ({
  box,
  openButtonHandler = {},
  activeTab = '',
  editPriceButtonHandler = () => {},
  setCurrentToken = () => {},
  buyButtonHandler = () => {},
}) => {
  const { PolygonMarketplace } = useContracts();
  const { library } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [unboxStatus, setUnboxStatus] = useState(true);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const onOpenButtonHandler = useCallback(() => {
    openButtonHandler(box);
  }, [box, openButtonHandler]);

  const handleEditPriceButtonHandler = useCallback(() => {
    setCurrentToken(box);
    editPriceButtonHandler();
  }, [editPriceButtonHandler, box, setCurrentToken]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  const buttonText = useMemo(() => {
    let text = '';
    if (loading) {
      text = 'Loading...';
    } else if (minutes === 0 && seconds === 0) {
      text = 'Open';
    } else {
      text = `${minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`;
    }
    return text;
  }, [loading, minutes, seconds]);

  return (
    <ModuleBoxCardWrapper>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={`wrapper ${activeTab ? 'wrapper--large' : ''}`}>
        <Grid container justifyContent="center" alignItems="center" className="cover_img__wrapper">
          <img src="/images/moduleBox.svg" alt="module-box" className="cover_img" loading="lazy" />
        </Grid>
        {activeTab ? (
          <div className="card__header">
            <div className="card__header_name">
              <Typography variant="cellModuleId" className="module_id_text">
                <span className="module_id_label">Token ID</span>
                <Typography variant="tokenId" className="module_id">
                  {`# ${box?.id}`}
                </Typography>
              </Typography>
              <Typography variant="cellModuleTitle" className="module_name">
                Mad Box
              </Typography>
            </div>
            <div className="card__header_price">
              <Typography variant="cellModuleId" className="module_id_text">
                <span className="module_id_label">
                  {activeTab === 'myField' && box?.onSale ? (
                    <img
                      src="/icons/edit-pencil-icon.svg"
                      alt="edit_price"
                      className="edit_price__icon"
                      onClick={handleEditPriceButtonHandler}
                    />
                  ) : null}
                  PRICE
                </span>
              </Typography>
              <Typography variant="cellModuleTitle" className="module_name">
                {`${box?.price || '0'} META`}
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <Typography variant="cellModuleId" className="module_id_text">
              <span className="module_id_label">Token ID</span>
              <Typography variant="tokenId" className="module_id">
                {`# ${box?.id}`}
              </Typography>
            </Typography>
            <Typography variant="cellModuleTitle" className="module_name">
              Mad Box
            </Typography>
          </>
        )}

        <Typography variant="madBoxCardText" className="description">
          Contains one random module
        </Typography>
        <Typography
          variant="madBoxCardButton"
          sx={{
            mt: '20px',
          }}>
          {!activeTab ? (
            <ConfirmButton
              variant="contained"
              disabled={loading || !unboxStatus}
              text={buttonText}
              confirmButtonHandler={onOpenButtonHandler}
              sx={{
                width: '100%',
                outline: 'none',
                borderRadius: '18px',
                fontSize: '14px',
                fontWeight: 600,
              }}
            />
          ) : activeTab === 'marketplace' ? (
            <BuyButton
              buyButtonHandler={() => {
                setCurrentToken(box);
                buyButtonHandler();
              }}
            />
          ) : activeTab === 'myField' && box?.onSale ? (
            <RemoveButton
              removeButtonHandler={() => {
                console.log('remove module');
              }}
              sx={{ width: '96px' }}
            />
          ) : (
            <SellButton
              sellButtonHandler={() => {
                console.log('sell module');
              }}
            />
          )}
        </Typography>
      </Grid>
    </ModuleBoxCardWrapper>
  );
};

export default ModuleBoxCard;
