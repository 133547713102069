import React from 'react';

const Bitkeep = ({ width }) => {
  return (
    <img
      alt="sim"
      src="https://cdn.bitkeep.vip/u_b_e6cf4100-ebab-11ec-9db1-7f301d902f4e.png"
      width={width || '48'}
      height={width || '48'}
    />
  );
};

export const BitkeepIcon = ({ width }) => {
  return (
    <svg
      width={width || 52}
      height={width || 52}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000"
      xmlSpace="preserve">
      <image
        width="1000"
        height="1000"
        xlinkHref="https://cdn.bitkeep.vip/u_b_e6cf4100-ebab-11ec-9db1-7f301d902f4e.png"
      />
    </svg>
  );
};

export default Bitkeep;
