import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { environment } from '@environment/';
import request from '@utils/request';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { getScientistIdProofSuccess, getScientistIdProofFailure, ScientistSlice } from '.';

export const getScientistIdProofAction = createAction('scientist/getScientistIdProof');

export const getWertOrdersAction = createAction('scientist/getWertOrders');

function* getProof(action) {
  try {
    const address = _get(action, 'payload.address');
    const chainId = _get(action, 'payload.chainId');
    const data = yield call(
      request,
      `${environment.BE_SERVER_URL}/api/scientists/proofs/${address}?chainId=${chainId}`,
      {},
      { method: 'GET' }
    );

    yield put(getScientistIdProofSuccess(data));
  } catch (error) {
    yield put(getScientistIdProofFailure(error));
  }
}

function* getWertOrders(action) {
  try {
    const address = _get(action, 'payload.address');
    const chainId = _get(action, 'payload.chainId');
    const wertOrders = yield call(
      request,
      `${environment.BE_SERVER_URL}/api/payment-event/address/${address}?chainId=${chainId}`,
      {},
      { method: 'GET' }
    );
    yield put(ScientistSlice.actions.getWertOrderSuccess(wertOrders));
  } catch (error) {
    yield put(ScientistSlice.actions.getWertOrderFailure(error));
  }
}

export function* ScientistWatcher() {
  yield takeLatest(getScientistIdProofAction, getProof);
  yield takeLatest(getWertOrdersAction, getWertOrders);
}
