import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { CustomSelectWithStyle, CustomMenuWithStyle } from './styles';

const CustomSelect = ({ options = [], handleOnChange, value, ...props }) => {
  return (
    <CustomSelectWithStyle
      className="custom-select-container"
      value={value}
      onChange={handleOnChange}
      displayEmpty
      IconComponent={({ className }) => {
        return <img className={className} src="/icons/select-arrow.svg" alt="arrow" />;
      }}
      MenuProps={{
        sx: {
          '.MuiPaper-root': {
            borderRadius: '20px',
            background: 'none',
          },
        },
        MenuListProps: {
          sx: {
            border: '1px solid rgba(255, 255, 255, 0.08)',
            borderRadius: '20px',
            background: '#54626F',
            color: '#B9B9B9',
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            padding: '0px',
            zIndex: 1,
            width: '100%',
          },
        },
      }}
      inputProps={{ 'aria-label': 'Without label' }}>
      {options.map((option) => {
        return (
          <MenuItem key={`${option.value}.${option.label}`} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </CustomSelectWithStyle>
  );
};

export default CustomSelect;
