export const CssPriceTypography = {
  height: '18px',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    background: 'linear-gradient(to right, transparent 50px, #17181C)',
  },
  '&:hover:after': {
    width: 0,
    height: 0,
  },
};
