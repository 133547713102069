import { ModuleBoxCard } from '@components';
import { LoadingCircle } from './LoadingCircle';

export function BoxDashboardCard({ boxesLoading, usersBoxes, openBoxButtonHandler }) {
  return boxesLoading
    ? [<LoadingCircle key="loading" />]
    : usersBoxes.map((box) => (
        <ModuleBoxCard box={box} key={box.id} openButtonHandler={openBoxButtonHandler} />
      ));
}
