const { useEffect } = require('react');

export const useInterval = (callback, time = 10 * 60 * 1000) =>
  useEffect(() => {
    const interval = setInterval(() => {
      // eslint-disable-next-line no-console
      console.log('refresh data', callback?.name);
      callback();
    }, time);
    return () => clearInterval(interval);
  }, [callback, time]);
