import { useWeb3React } from '@web3-react/core';
import { delay, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useEnhancerUserQuery } from '../../../hooks/contracts/enhancers/userEnhancerUserQuery';
import { useMetacellUsersQuery } from '../../../hooks/contracts/metacell/useMetacellQuery';
import { LAB_DROP_CELL, LAB_DROP_ENHANCER, nftListFilters } from './constant';

export const useLabCell = () => {
  const { data: usersMetacells } = useMetacellUsersQuery();
  const { data: usersEnhancers } = useEnhancerUserQuery();

  const { account } = useWeb3React();
  const [actionSlot, _updateActionSlot] = useState();
  const [enhancerSlot, _updateEnhancerSlot] = useState();
  const [cellOutcome, _updateCellOutcome] = useState();

  useEffect(() => {
    _updateActionSlot();
    _updateEnhancerSlot();
    _updateCellOutcome();
  }, [account]);

  const labCells = useMemo(() => {
    if (!usersMetacells) return;
    const metacells = [...(usersMetacells || [])]
      .map((o) => (o.id === cellOutcome?.cell?.id ? cellOutcome?.cell : o))
      .filter((o) => o.id !== cellOutcome?.cell?.id)
      .filter((o) => !actionSlot || o.name !== actionSlot.name);
    const enhancer = [...(usersEnhancers || [])].filter(
      (o) => !enhancerSlot || o.name !== enhancerSlot.name
    );

    // eslint-disable-next-line consistent-return
    return {
      [nftListFilters[0]]: metacells,
      [nftListFilters[1]]: enhancer,
      [nftListFilters[2]]: [...metacells, ...enhancer],
    };
  }, [usersMetacells, usersEnhancers, cellOutcome?.cell, actionSlot, enhancerSlot]);

  const _onDragStart = (result) => {};

  const handleOnDragEnhancer = ({ droppableId, index }) => {
    _updateEnhancerSlot(null);

    const enhancer = get(labCells, [droppableId, index]);

    if (droppableId !== LAB_DROP_ENHANCER) _updateEnhancerSlot(enhancer);
  };

  const _onDragEnd = async (result) => {
    const {
      destination,
      source: { droppableId, index },
      draggableId,
    } = result;

    if (draggableId.includes('enhancer')) {
      handleOnDragEnhancer({ droppableId, index });
      return;
    }

    _updateActionSlot(null);

    if (droppableId !== LAB_DROP_CELL) _updateActionSlot(labCells[droppableId][index]);
  };

  const _onDragReset = () => {
    _updateActionSlot();
    _updateEnhancerSlot();
  };

  return {
    labCells,
    actionSlot: [actionSlot, enhancerSlot],
    cellOutcome,
    _onDragStart,
    _onDragEnd,
    _onDragReset,
    _updateCellOutcome,
  };
};
