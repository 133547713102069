import { CircularProgress, Grid } from '@mui/material';

export const LoadingCircle = () => {
  return (
    <Grid
      item
      lg={12}
      sx={{
        p: 0,
        alignSelf: 'center',
        width: '100%',
        mt: '100px',
        textAlign: 'center',
      }}>
      <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
    </Grid>
  );
};
