import React from 'react';
import { LabSlotContextProvider } from './context/LabSlotContext';
import Main from './MainComponent';

const Lab = () => {
  return (
    <LabSlotContextProvider>
      <Main />
    </LabSlotContextProvider>
  );
};

export default Lab;
