import { useEffect, useState } from 'react';

const maxDaysToEvolution = Math.floor(3562000 / (60 * 60 * 24));

const useTimer = (timestamp, boostTime = 0) => {
  const [timeToEvolution, setTimeToEvolution] = useState();

  // let seconds = 0;
  // let minutes = 0;
  // let hours = 0;
  // let days = 0;

  // if (timeToEvolution > 0) {
  //   seconds = Math.floor((timeToEvolution - boostTime) % 60);
  //   minutes = Math.floor(((timeToEvolution - boostTime) / 60) % 60);
  //   hours = Math.floor(((timeToEvolution - boostTime) / (60 * 60)) % 24);
  //   days = Math.floor((timeToEvolution - boostTime) / (60 * 60 * 24));
  // }

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);

  useEffect(() => {
    if (timeToEvolution > 0) {
      setSeconds(Math.floor((timeToEvolution - boostTime) % 60));
      setMinutes(Math.floor(((timeToEvolution - boostTime) / 60) % 60));
      setHours(Math.floor(((timeToEvolution - boostTime) / (60 * 60)) % 24));
      setDays(Math.floor((timeToEvolution - boostTime) / (60 * 60 * 24)));
    }
  }, [boostTime, timeToEvolution]);

  useEffect(() => {
    let timer;
    const timeCountdown = (timestamp - Date.now()) / 1000;
    if (timeCountdown > 0) {
      setTimeToEvolution(timeCountdown);
      timer = setInterval(() => {
        setTimeToEvolution((t) => {
          if (t - 1 < 1) clearInterval(timer);
          return t - 1;
        });
      }, 1000);
    }
    timer = setInterval(() => {
      setTimeToEvolution((t) => {
        if (t - 1 < 1) clearInterval(timer);
        return t - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timestamp]);

  return { seconds, minutes, hours, days, maxDaysToEvolution };
};

export default useTimer;
