import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { environment } from '@environment/';
import request from '@utils/request';
import { NFT_ITEM_LIMIT } from '@utils/constants';
// import _omit from 'lodash/omit';
import _get from 'lodash/get';
import {
  getListNFTs,
  getListNFTsSuccess,
  getListNFTsFailure,
  // setPaginate,
} from '.';

export const getListAction = createAction('NFT/getListNFTs');

function* getList(action) {
  try {
    // const params = action.payload;
    // const data = yield call(
    //   request,
    //   `${environment.BE_SERVER_URL}/api/nfts`,
    //   {
    //     ...params,
    //     limit: NFT_ITEM_LIMIT,
    //   },
    //   { method: 'GET' }
    // );
    // yield put(getListNFTsSuccess(data));
  } catch (error) {
    yield put(getListNFTsFailure(error));
  }
}

export function* NFTWatcher() {
  yield takeLatest(getListAction, getList);
}
