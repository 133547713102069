import { BuyButton, RemoveButton, SellButton } from '@components/index';
import { Box, Grid, Typography } from '@mui/material';
import formatLongPrice from '@utils/formatLongPrice';
import { useWeb3React } from '@web3-react/core';
import React, { useMemo } from 'react';

export default function FieldCellDetail({
  onSale,
  showUsers,
  priceRef,
  isPriceOverflown,
  price,
  priceOffset,
  editPriceButtonHandler,
  priceTransitionTime,
  owner,
  removeButtonHandler,
  sellButtonHandler,
  buyButtonHandler,
  setCurrentToken,
  hideButton,
}) {
  const { account } = useWeb3React();
  const buttonHandler = useMemo(() => {
    if (account?.toLowerCase() === owner?.toLowerCase()) {
      if (onSale) {
        return (
          <Grid container justifyContent="space-between" alignItems="center">
            <RemoveButton removeButtonHandler={removeButtonHandler} sx={{ width: '190px' }} />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                border: '1px solid #09EBF6',
                borderRadius: '100px',
                width: '32px',
                height: '32px',
                cursor: 'pointer',
                marginLeft: '10px',
                ':hover': { background: '#09595e' },
              }}
              onClick={() => {
                editPriceButtonHandler();
              }}>
              <img src="/images/Pencil.png" width="12px" height="12px" alt="edit" />
            </Grid>
          </Grid>
        );
      }
      return <SellButton sellButtonHandler={sellButtonHandler} sx={{ width: '190px' }} />;
    }
    return <BuyButton buyButtonHandler={buyButtonHandler} sx={{ width: '190px' }} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, owner, onSale]);
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="priceWrapper">
          <Typography
            variant="modalId"
            sx={{
              height: '21px',
              opacity: 0.4,
            }}>
            PRICE
          </Typography>
          <Typography
            ref={priceRef}
            variant="modalPrice"
            sx={{
              height: '22px',
              justifyContent: 'flex-start',
              overflow: 'hidden',
              position: 'relative',
              '&:after': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
              },
              '&:hover:after': {
                width: 0,
                height: 0,
              },
            }}>
            <Box
              sx={{
                ml: '10px',
                '&:hover': isPriceOverflown && {
                  transform: `translateX(-${priceOffset}px)`,
                  transition: `transform ${priceTransitionTime}s`,
                },
              }}>
              {`${formatLongPrice(price)}  ETH`}
            </Box>
          </Typography>
        </Typography>
        {!hideButton && (
          <Grid
            container
            gap="4px"
            flexWrap="nowrap"
            sx={{ width: 'auto', '@media screen and (max-width: 469px)': { mt: '5px' } }}>
            {buttonHandler}
          </Grid>
        )}
      </Grid>
    </>
  );
}
