/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import { AltTabBoard, LabCard } from '@components';
import { Box, Grid, Typography } from '@mui/material';
import { useMemo, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useAppStore } from '@store';
import { LoadingCircle } from '@pages/Dashboard/LoadingCircle';
import { nftListFilters } from '../context/constant';
import { useLabSlotAction, useLabSlotData } from '../context/hooks';

const EmptyCell = ({ title }) => (
  <Typography
    variant="cardName"
    sx={{
      height: '19px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      justifyContent: 'center',
      alightItem: 'center',
      color: 'rgba(255, 255, 255, 0.2)',
    }}>
    {`There are no ${title} here yet!`}
  </Typography>
);

export const ListOwnerCell = ({ detailsButtonHandler, dragClickHandler }) => {
  const { activeFilter, labCells } = useLabSlotData();
  const { _updateActiveFilter } = useLabSlotAction();
  const { evolutionMetacell, setEvolutionMetacell } = useAppStore();

  useEffect(() => {
    if (!activeFilter || !labCells || !evolutionMetacell) return;
    if (activeFilter !== 'Metacells') return;
    const metacell = labCells[activeFilter].find(
      (cell) => Number(cell.id) === Number(evolutionMetacell)
    );
    if (!metacell) return;
    const index = labCells[activeFilter].indexOf(metacell);
    dragClickHandler(metacell, index, activeFilter);
    setTimeout(function () {
      setEvolutionMetacell(null);
    }, 500);
  }, [evolutionMetacell, labCells, activeFilter, dragClickHandler]);
  const CellListCard = useMemo(
    () =>
      !labCells ? (
        [<LoadingCircle key="loading" />]
      ) : labCells?.[activeFilter]?.length > 0 ? (
        <Droppable droppableId={activeFilter} isDropDisabled={false}>
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="the-lab__dnd-card-list">
              {labCells[activeFilter].map((token, index) => (
                <LabCard
                  key={`${activeFilter}-cell-${token.nanoId}`}
                  index={index}
                  token={token}
                  detailsButtonHandler={detailsButtonHandler}
                  dragClickHandler={() => {
                    dragClickHandler(token, index, activeFilter);
                  }}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      ) : (
        <EmptyCell title={activeFilter} />
      ),
    [activeFilter, detailsButtonHandler, dragClickHandler, labCells]
  );

  return (
    <>
      <AltTabBoard
        tabs={nftListFilters}
        activeTab={activeFilter}
        setActiveTab={_updateActiveFilter}
        sx={{ m: '25px 20px 0', zIndex: 1 }}
      />
      {labCells?.[activeFilter]?.length && (
        <Grid
          sx={{
            zIndex: 50,
            margin: '25px 20px 0',
            width: 'auto',
          }}>
          <Grid
            sx={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
              height: '40px',
              marginLeft: 'auto',
            }}>
            {labCells?.[activeFilter]?.length} items
          </Grid>
        </Grid>
      )}
      <Box className="the-lab__dnd-wrapper">{CellListCard}</Box>
    </>
  );
};
