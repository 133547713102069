import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const spinGradient = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const UnboxLoading = () => {
  return (
    <Box
      sx={{
        width: '260px',
        height: '260px',
        maxWidth: '260px',
        maxHeight: '260px',
        border: '2px solid #57F7FF',
        borderRadius: '50%',
        boxShadow: '0px 0px 4px 4px rgba(9, 235, 246, 0.25)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
      <img
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        src="images/PetriDishModal.png"
        alt="PetriDish"
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '134px',
          height: '134px',
          borderRadius: '50%',
          boxShadow: '6px 6px 12px rgba(118, 19, 255, 0.4)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          animation: `${spinGradient} 3s infinite linear`,
        }}>
        <img
          src="/images/moduleBox.svg"
          alt="module-box"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '134px',
            height: '134px',
          }}
        />
      </Box>
    </Box>
  );
};

export default UnboxLoading;
