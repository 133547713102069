/* eslint-disable no-negated-condition */
import { environment } from '../environment';

export default async (provider, chainId) => {
  const targetNetwork =
    chainId !== environment.ETH_NETWORK_ID
      ? environment.ETH_NETWORK_ID
      : environment.CURRENT_NETWORK_ID;

  const networkParams = {
    [environment.ETH_NETWORK_ID]: {
      chainId: `0x${Number(environment.ETH_NETWORK_ID).toString(16)}`,
      chainName: environment.ETH_NETWORK_NAME,
      rpcUrls: [environment.ETH_NETWORK_RPC_URL],
      blockExplorerUrls: [environment.ETH_NETWORK_BLOCK_EXPLORER],
      nativeCurrency: {
        symbol: environment.ETH_NETWORK_SYMBOL,
        decimals: Number(environment.ETH_NETWORK_DECIMALS),
      },
    },
    [environment.CURRENT_NETWORK_ID]: {
      chainId: `0x${Number(environment.CURRENT_NETWORK_ID).toString(16)}`,
      chainName: environment.CURRENT_NETWORK_NAME,
      rpcUrls: [environment.CURRENT_NETWORK_RPC_URL],
      blockExplorerUrls: [environment.CURRENT_NETWORK_BLOCK_EXPLORER],
      nativeCurrency: {
        symbol: environment.CURRENT_NETWORK_SYMBOL,
        decimals: Number(environment.CURRENT_NETWORK_DECIMALS),
      },
    },
    [environment.ETH_MAINNET_NETWORK_ID]: {
      chainId: `0x${Number(environment.ETH_MAINNET_NETWORK_ID).toString(16)}`,
      chainName: environment.ETH_MAINNET_NETWORK_NAME,
      rpcUrls: [environment.ETH_MAINNET_NETWORK_RPC_URL],
      blockExplorerUrls: [environment.ETH_MAINNET_NETWORK_BLOCK_EXPLORER],
      nativeCurrency: {
        symbol: environment.ETH_MAINNET_NETWORK_SYMBOL,
        decimals: Number(environment.ETH_MAINNET_NETWORK_DECIMALS),
      },
    },
  };

  try {
    await provider.send('wallet_switchEthereumChain', [
      {
        chainId: `0x${Number(targetNetwork).toString(16)}`,
      },
    ]);
  } catch (e) {
    if (e.code === 4902) {
      try {
        await provider.send('wallet_addEthereumChain', [networkParams[targetNetwork]]);
      } catch (e) {
        console.log(e);
      }
    }

    console.log('Cannot switch to the network');
  }
};
