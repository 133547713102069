import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

const initialState = {
  proofs: [],
  isLoading: false,
  deadline: 0,
};

export const MetaCellSlice = createSlice({
  name: 'metaCell',
  initialState,
  reducers: {
    getMetaCellProof: (state, _) => {
      state.isLoading = true;
      state.proofs = [];
      state.metaCellAccountId = null;
    },
    getMetaCellProofSuccess: (state, action) => {
      state.deadline = Date.parse(_get(action, 'payload.data.deadline', 0));
      state.proofs = _get(action, 'payload.data.data', null);
      state.metaCellAccountId = _get(action, 'payload.data.account', null);
      state.isLoading = false;
    },
    getMetaCellProofFailure: (state, action) => {
      state.proofs = [];
      state.isLoading = false;
      state.metaCellAccountId = null;
    },
    resetState: (state) => {
      state.proofs = [];
      state.metaCellAccountId = null;
    },
  },
});

export const {
  getMetaCellProof,
  getMetaCellProofSuccess,
  getMetaCellProofFailure,
  resetState,
  setPaginate,
} = MetaCellSlice.actions;

export const selectMetaCellProof = (state) => state.metaCell;

export default MetaCellSlice.reducer;
