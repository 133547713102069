import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = {
  errorMessage: 'Something went wrong!',
  errorModalStatus: false,
  loginModalStatus: false,
  loadingMessage: 'Please wait...',
  loadingModalStatus: false,
  changeWalletModalStatus: false,
  switchNetWorkStatus: false,
  feedbackModalStatus: false,
  connector: null,
  connectorName: 'MetaMask',
  nftCell: null,
};

export const ModalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    openErrorModal: (state) => {
      state.errorModalStatus = true;
    },
    closeErrorModal: (state) => {
      state.errorModalStatus = false;
      state.errorMessage = 'Something went wrong!';
    },
    openLoginModal: (state) => {
      state.loginModalStatus = true;
    },
    closeLoginModal: (state) => {
      state.loginModalStatus = false;
    },
    openFeedbackModal: (state) => {
      state.feedbackModalStatus = true;
    },
    closeFeedbackModal: (state) => {
      state.feedbackModalStatus = false;
    },
    openChangeWalletModal: (state) => {
      state.changeWalletModalStatus = true;
    },
    closeChangeWalletModal: (state) => {
      state.changeWalletModalStatus = false;
    },
    openSwitchNetworkModal: (state, action) => {
      state.switchNetWorkStatus = true;
      state.connector = action.payload.connector;
      state.connectorName = action.payload.connectorName;
    },
    closeSwitchNetworkModal: (state) => {
      state.switchNetWorkStatus = false;
    },
    openLoadingModal: (state, action) => {
      state.loadingModalStatus = true;
      state.loadingMessage = action.payload?.message || 'Please wait...';
    },
    closeLoadingModal: (state) => {
      state.loadingModalStatus = false;
      state.loadingMessage = 'Please wait...';
    },
    selectNftCell: (state, { payload }) => {
      state.nftCell = get(payload, 'cell', null);
    },
    resetState: (state) => {
      state.loginModalStatus = false;
      state.changeWalletModalStatus = false;
      state.errorMessage = 'Something went wrong';
      state.errorModalStatus = false;
      state.switchNetWorkStatus = false;
      state.loadingMessage = 'Please wait...';
      state.loadingModalStatus = false;
    },
  },
});

export const {
  setErrorMessage,
  openErrorModal,
  closeErrorModal,
  openLoginModal,
  closeLoginModal,
  openFeedbackModal,
  closeFeedbackModal,
  openChangeWalletModal,
  closeChangeWalletModal,
  openSwitchNetworkModal,
  closeSwitchNetworkModal,
  openLoadingModal,
  closeLoadingModal,
  resetState,
} = ModalsSlice.actions;

export const selectModalStatus = (state) => state.modals;

export default ModalsSlice.reducer;
