import { Button } from '@mui/material';

const SellButton = ({ sellButtonHandler, disabled, sx }) => {
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      sx={{
        color: '#fff',
        border: '1px solid #BD0CFF',
        borderRadius: '18px',
        width: '74px',
        height: '36px',
        maxWidth: '100%',
        fontSize: '14px',
        lineHeight: '16.7px',
        fontWeight: 600,
        '&:hover': {
          border: '1px solid #BD0CFF',
          background: 'rgba(189, 12, 255, 0.5)',
        },
        '&:active': {
          border: '1px solid #BD0CFF',
          background: 'rgba(189, 12, 255, 0.5)',
        },
        '&:disabled': {
          border: '1px solid #490064',
          color: 'rgba(255, 255, 255, 0.2)',
        },
        ...sx,
      }}
      onClick={sellButtonHandler}>
      Sell
    </Button>
  );
};

export default SellButton;
