import { createSlice } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

const initialState = {
  isSendingTransaction: false,
};

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    asyncTransactionHash: (state, action) => {
      state.isSendingTransaction = true;
    },
    asyncTransactionHashSuccess: (state, action) => {
      state.isSendingTransaction = false;
    },
    asyncTransactionHashFailed: (state, action) => {
      state.isSendingTransaction = false;
    },
    resetState: () => initialState,
  },
});

export const {
  resetState,
  asyncTransactionHash,
  asyncTransactionHashSuccess,
  asyncTransactionHashFailed,
} = transactionSlice.actions;

export const selectTransaction = (state) => state[transactionSlice.name];

export default transactionSlice.reducer;
