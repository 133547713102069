import { useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import DetailsButton from '@components/Buttons/DetailsButton';
import BuyButton from '@components/Buttons/BuyButton';
import SellButton from '@components/Buttons/SellButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import { DashboardModuleCardWrapper } from './styles';

const DashboardModuleCard = ({
  module,
  detailsButtonHandler = () => {},
  activeTab = '',
  editPriceButtonHandler = () => {},
  setCurrentToken = () => {},
  sellButtonHandler = () => {},
  removeButtonHandler = () => {},
  buyButtonHandler = () => {},
}) => {
  // const handleEditPriceButtonHandler = useCallback(() => {
  //   setCurrentToken(module);
  //   editPriceButtonHandler();
  // }, [editPriceButtonHandler, module, setCurrentToken]);
  return (
    <DashboardModuleCardWrapper large={activeTab ? 'true' : ''}>
      <Grid container direction="column" alignItems="center" className="card__wrapper">
        <Grid container justifyContent="center" className="cover__img">
          <img src="/images/gunModule.svg" alt="module" loading="lazy" />
        </Grid>

        {activeTab ? (
          <div className="card__header">
            <div className="card__header_name">
              <Typography variant="cellModuleId" className="module_id_text">
                <span className="module_id_label">Token ID</span>
                <Typography variant="tokenId" className="module_id">
                  {`# ${module?.id}`}
                </Typography>
              </Typography>
              <Typography variant="cellModuleTitle" className="module_name">
                {module?.name}
              </Typography>
            </div>
            <div className="card__header_price">
              <Typography variant="cellModuleId" className="module_id_text">
                <span className="module_id_label">
                  {activeTab === 'myField' && module?.onSale ? (
                    <img
                      src="/icons/edit-pencil-icon.svg"
                      alt="edit_price"
                      className="edit_price__icon"
                      onClick={() => {
                        setCurrentToken(module);
                        editPriceButtonHandler();
                      }}
                    />
                  ) : null}
                  PRICE
                </span>
              </Typography>
              <Typography variant="cellModuleTitle" className="module_name">
                {`${module?.price || '0'} META`}
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <Typography variant="cellModuleId" className="module_id_text">
              <span className="module_id_label">Token ID</span>
              <Typography variant="tokenId" className="module_id">
                {`# ${module?.id}`}
              </Typography>
            </Typography>
            <Typography variant="cellModuleTitle" className="module_name">
              {module?.name}
            </Typography>
          </>
        )}

        <Typography variant="cellModuleAbility" className="abilities">
          <span className="abilities_rarity">{`(${module?.abilities?.rarity}) `}</span>
          <span className="abilities_type">{module?.abilities?.type}</span>
        </Typography>
        <Typography variant="cellModuleDescribe" className="describe">
          {module?.describe}
        </Typography>
        <div className="line" />
        <div className={`group__buttons ${!activeTab && 'group__buttons_single_item'}`}>
          <DetailsButton
            variant="outlined"
            text="Detail"
            className="confirm__button"
            sx={{
              width: '100px',
              outline: 'none',
              borderRadius: '18px',
              fontSize: '14px',
              fontWeight: 400,
            }}
            detailsButtonHandler={() => {
              detailsButtonHandler(module);
            }}
          />
          {!activeTab ? null : activeTab === 'myField' ? (
            module?.onSale ? (
              <RemoveButton
                removeButtonHandler={() => {
                  setCurrentToken(module);
                  removeButtonHandler();
                }}
                sx={{ width: '96px' }}
              />
            ) : (
              <SellButton
                sellButtonHandler={() => {
                  setCurrentToken(module);
                  sellButtonHandler();
                }}
              />
            )
          ) : (
            <BuyButton
              buyButtonHandler={() => {
                setCurrentToken(module);
                buyButtonHandler();
              }}
            />
          )}
        </div>
      </Grid>
    </DashboardModuleCardWrapper>
  );
};

export default DashboardModuleCard;
