import { Grid, Typography } from '@mui/material';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import { connectorsByName, ConnectorNames } from '@utils/connectors';
import useConnectors from '@hooks/wallets/useConnectors';
import { useMemo, useState } from 'react';
import { Bitkeep, Coinbase, Metamask } from '../../Icons';
import { DotPulse } from './styles';

export const ConnectWalletIcon = ({ walletName }) => {
  switch (walletName) {
    case ConnectorNames.Injected:
      return <Metamask />;
    case ConnectorNames.WalletConnect:
      return (
        <img
          style={{ width: '52px', height: '52px' }}
          src="/images/wallet-connect-logo.png"
          alt="wallet connect icon"
        />
      );
    case ConnectorNames.Bitkeep:
      return <Bitkeep />;
    default:
      return <Coinbase />;
  }
};

export const WalletButton = (props) => {
  const { mobile, walletName, setErrorMessage, walletActive, setWalletActive } = props;
  const { onConnectWallet } = useConnectors(setErrorMessage);

  const handleClick = async () => {
    try {
      if (!walletActive) {
        setWalletActive(walletName);
        localStorage.setItem(ITEM_NAME_STORAGE.isLoadingConnect, walletName);
        await onConnectWallet(connectorsByName[walletName], walletName);
        setWalletActive(null);
      }
    } catch (err) {
      console.log(err);
      setWalletActive(null);
    }
  };

  const { title, description, shortTitle } = useMemo(() => {
    switch (walletName) {
      case ConnectorNames.WalletConnect:
        return {
          title: 'Connect with WalletConnect',
          shortTitle: 'WalletConnect',
          description: 'Scan QR to connect',
        };
      case ConnectorNames.WalletLink:
        return {
          title: 'Connect with Coinbase',
          shortTitle: 'Coinbase',
          description: 'Connect by Browser Wallet or QR',
        };
      case ConnectorNames.Bitkeep:
        return {
          title: 'Connect with BitKeep',
          shortTitle: 'BitKeep',
          description: 'Connect using browser wallet',
        };

      default:
        return {
          title: 'Connect with MetaMask',
          shortTitle: 'MetaMask',
          description: 'Connect using browser wallet',
        };
    }
  }, [walletName]);

  const PendingDotWrap = ({ children }) =>
    walletActive === walletName ? (
      <Typography
        variant="modalPrice"
        sx={{
          ml: mobile ? '10px' : '30px',
          height: '25px',
          justifyContent: 'flex-start',
          fontSize: mobile ? '14px' : '18px',
        }}>
        Connecting
        <DotPulse />
      </Typography>
    ) : (
      children
    );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      gap={mobile ? '0' : '38px'}
      sx={{
        mt: '10px',
        p: mobile ? '12px' : '16px 30px',
        background: 'rgba(0, 0, 0, 0.35)',
        borderRadius: '10px',
        cursor: 'pointer',
      }}
      onClick={handleClick}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ConnectWalletIcon walletName={walletName} />
        <Grid container direction="column" sx={{ width: 'auto' }}>
          <PendingDotWrap>
            <Typography
              variant="modalPrice"
              sx={{
                ml: mobile ? '10px' : '30px',
                height: '24px',
                justifyContent: 'flex-start',
                fontSize: mobile ? '14px' : '18px',
              }}>
              {mobile ? shortTitle : title}
            </Typography>
            {!mobile && (
              <Typography
                variant="loginModalText"
                sx={{
                  ml: mobile ? '10px' : '30px',
                  height: '22px',
                  justifyContent: 'flex-start',
                  fontSize: mobile ? '12px' : '16px',
                }}>
                {description}
              </Typography>
            )}
          </PendingDotWrap>
        </Grid>
      </div>
      {mobile ? null : <img src="/icons/arrow-next-icon.svg" alt="connect icon" />}
    </Grid>
  );
};
