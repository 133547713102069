import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Banner from '@components/Banner';
import { useWeb3React } from '@web3-react/core';
import ListMenu from './ListMenu';
import {
  Logo,
  LogoWrapper,
  LogoContain,
  HeaderMobileStyled,
  IconClose,
  HeaderBar,
  HeaderMobileWrapper,
} from './styles';

const HeaderMobile = ({ options, path }) => {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const isTablet = useMediaQuery('(max-width:1100px)');
  const [showHeader, setShowHeader] = useState(true);
  const handlerShowHeader = () => {
    setShowHeader(!showHeader);
  };

  const onClickLogo = () => {
    navigate('/');
  };

  return (
    <>
      <Banner />
      <HeaderMobileWrapper>
        <HeaderBar>
          <img
            src="/images/menu.png"
            alt="menu"
            onClick={handlerShowHeader}
            className="open-menu"
          />
        </HeaderBar>
        <HeaderMobileStyled show={showHeader}>
          <LogoWrapper onClick={onClickLogo}>
            {/* <Logo src="/images/logo.svg" /> */}
            <LogoContain>
              <img src="/images/logo.png" alt="logo" />
            </LogoContain>
          </LogoWrapper>
          <ListMenu isTablet={isTablet} title={options} path={path} />
          <IconClose src="/images/close.png" onClick={handlerShowHeader} />
        </HeaderMobileStyled>
      </HeaderMobileWrapper>
    </>
  );
};
export default HeaderMobile;
