/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';
import React, { useEffect } from 'react';
import { useAppStore } from '../../../store';

export const useFetchDashboardSMC = (activeTab) => {
  const {
    methodContract: { _updateUsersScientists, _updateUsersEnhancers },
    usersMetacells,
    usersScientists,
    usersEnhancers,
    setUsersScientists,
  } = useAppStore();
  const { account } = useWeb3React();
  const [isChanged, setIsChangeAccount] = React.useState(true);

  useEffect(() => {
    setIsChangeAccount((p) => !p);
    setUsersScientists(null);
  }, [account]);

  useEffect(() => {
    const defaultFnc = () => {};
    if (usersMetacells && usersScientists && usersEnhancers) return defaultFnc();

    if (activeTab === 'Enhancers' && !usersEnhancers) {
      _updateUsersEnhancers();
    }

    if (activeTab === 'Nanocells') return defaultFnc();

    if (activeTab === 'Scientists' && !usersScientists) {
      _updateUsersScientists();
    }

    if (activeTab === 'Modules') return defaultFnc();

    if (activeTab === 'Mad Boxes') return defaultFnc();

    setIsChangeAccount(false);
    return defaultFnc();
  }, [activeTab, isChanged]);
};
