import { useContracts } from '@providers';
import { useWeb3React } from '@web3-react/core';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { formatEnhancerData } from '../../../utils';

export const enhancerUserQueryKey = 'enhancer-user-key';

export const useEnhancerUserQuery = () => {
  const { account } = useWeb3React();
  const contract = useContracts();
  const Laboratory = get(contract, 'Laboratory');
  const handleAggregateCall = get(contract, 'handleAggregateCall');

  const query = useQuery(
    [enhancerUserQueryKey, account],
    async () => {
      const derivedUsersEnhancersIds = await Laboratory.getUserEnhancers(account);
      const tupleEnhancersInfo = derivedUsersEnhancersIds.map((id) => {
        const callData = Laboratory.interface.encodeFunctionData('getEnhancerInfo', [id]);
        return {
          target: Laboratory.address,
          callData,
        };
      });
      const tupleEnhancersAmount = derivedUsersEnhancersIds.map((id) => {
        const callData = Laboratory.interface.encodeFunctionData('getEnhancersAmount', [
          account,
          id,
        ]);
        return {
          target: Laboratory.address,
          callData,
        };
      });
      const [returnEnhancerInfo, returnEnhancerAmounts] = await Promise.all([
        handleAggregateCall(tupleEnhancersInfo),
        handleAggregateCall(tupleEnhancersAmount),
      ]);
      const derivedUsersEnhancersInfo = returnEnhancerInfo.map((result) => {
        return Laboratory.interface.decodeFunctionResult('getEnhancerInfo', result)[0];
      });
      const derivedUsersEnhancersAmount = returnEnhancerAmounts.map((result) => {
        return Laboratory.interface.decodeFunctionResult('getEnhancersAmount', result)[0];
      });

      const getEnhancersAmount = (id) => {
        const isEnhancer = (_id) => Number(_id) === Number(id);
        const index = derivedUsersEnhancersIds.findIndex(isEnhancer);
        if (index < 0) return 0;
        return derivedUsersEnhancersAmount[index];
      };

      const userEnhancers = await Promise.all(
        derivedUsersEnhancersInfo.map((enhancer) =>
          formatEnhancerData(enhancer, getEnhancersAmount, account)
        )
      );

      return userEnhancers.filter((enhancer) => Number(enhancer.amount) > 0);
    },
    { enabled: Boolean(Laboratory) && Boolean(account) }
  );

  return query;
};
