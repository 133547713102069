import { styled } from '@mui/material/styles';

export const ScientistModalContentWrapper = styled('div')`
  pointer-events: ${(props) => (props.loading ? 'unset' : 'auto')};

  .scientist_loading {
    background-color: ${(props) => (props.loading ? 'rgba(0, 0, 0, 0.6)' : 'unset')};
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    span {
      display: inline-block;
      margin-top: 20%;
      margin-left: 50%;
    }
  }
  .close_icon {
    cursor: pointer;
    align-self: flex-end;
  }
  .buy_scientist__wrapper {
    margin-top: 25px;
  }
  .attribute {
    height: 31px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 7px;
    padding: 4px 9px;
  }
  .label {
    opacity: 0.4;
    font-size: 14px;
    font-weight: 400;
  }
`;
