import { Typography } from '@mui/material';

const AltTab = ({ tab, active, setActiveTab }) => {
  return (
    <Typography
      variant="altTab"
      sx={{
        border: `1px solid ${active ? '#fff' : 'rgba(255, 255, 255, 0.1)'}`,
        borderRadius: '26px',
        height: '40px',
        color: active ? '#fff' : '#B9B9B9',
        p: '0 20px',
        cursor: 'pointer',
        minWidth: '70px',
        '&:hover': active
          ? null
          : {
              color: '#fff',
              borderColor: 'rgba(185, 185, 185, 0.5)',
            },
      }}
      onClick={() => {
        setActiveTab(tab);
      }}>
      {tab}
    </Typography>
  );
};

export default AltTab;
