import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, monospace',
    pageTitle: {
      fontSize: '36px',
      lineHeight: '44.1px',
      fontWeight: 800,
    },
    headerMenu: {
      fontSize: '14px',
      lineHeight: '16.7px',
      fontWeight: 600,
    },
    mainTab: {
      fontSize: '24px',
      lineHeight: '29.02px',
      fontWeight: 700,
    },
    feedbackForms: {
      fontSize: '1rem',
      lineHeight: '120%',
      fontWeight: 400,
      fontFamily: 'ShareTech Mono',
    },
    modalTitle: {
      fontSize: '1.2rem',
      lineHeight: '28.63px',
      fontWeight: 600,
    },
    altPrice: {
      fontSize: '14px',
      lineHeight: '16.24px',
      color: '#B9B9B9',
    },
    tokenId: {
      fontSize: '13px',
      lineHeight: '15.08px',
    },
    cardName: {
      fontSize: '16px',
      lineHeight: '18.83px',
      fontWeight: 500,
    },
    cardAttribute: {
      fontSize: '12px',
      lineHeight: '14.12px',
      fontWeight: 500,
    },
    cardInfo: {
      fontSize: '14px',
      lineHeight: '16.93px',
      fontWeight: 700,
    },
    cardPrice: {
      fontSize: '15px',
      lineHeight: '18.13px',
      fontWeight: 700,
    },
    altTab: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    externalCardName: {
      fontSize: '12px',
      lineHeight: '13.92px',
    },
    externalId: {
      fontSize: '20px',
      lineHeight: '23.2px',
    },
    timerNumbers: {
      fontSize: '36px',
      lineHeight: '42.95px',
      fontWeight: 600,
    },
    timerDimension: {
      fontSize: '10px',
      lineHeight: '11.6px',
    },
    modalId: {
      fontSize: '18px',
      lineHeight: '20.88px',
    },
    modalName: {
      fontSize: '36px',
      lineHeight: '42.95px',
      fontWeight: 500,
    },
    modalAttribute: {
      fontSize: '18px',
      lineHeight: '21.19px',
      fontWeight: 500,
    },
    modalText: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    modalAltText: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    modalLevel: {
      fontSize: '14px',
      lineHeight: '16.48px',
      fontWeight: 500,
    },
    modalTimer: {
      fontSize: '30px',
      lineHeight: '35.79px',
      fontWeight: 600,
    },
    modalPrice: {
      fontSize: '18px',
      lineHeight: '21.76px',
      fontWeight: 700,
    },
    emptyMessage: {
      fontSize: '17px',
      lineHeight: '19.72px',
    },
    loginModalText: {
      fontSize: '16px',
      lineHieght: '22.4px',
      fontWeight: 300,
    },
    popoverAddress: {
      fontSize: '14px',
      lineHeight: '16.8px',
    },
    labSlot: {
      fontSize: '15px',
      lineHeight: '17.66px',
      fontWeight: 500,
      color: 'rgba(255, 255, 255, 0.4)',
    },
    outcomeTimer: {
      fontSize: '22px',
      lineHeight: '26.25px',
      fontWeight: 600,
      color: '#09EBF6',
    },
    userName: {
      fontSize: '22px',
      lineHeight: '25.89px',
      fontWeight: 500,
    },
    ERC20forLab: {
      fontSize: '18px',
      lineHeight: '21.47px',
      fontWeight: 600,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
      },
    },
  },
  palette: {
    text: {
      primary: '#fff',
    },
    background: {
      default: '#0B000D',
      modal: '#2B106F',
      nftCollectionModal: 'rgba(43,16,111,0.91)',
      loginAction: '#170052',
      customProgressBar: '#130A2B',
      disabled: '#502AB1',
      pb: '#242424',
    },
    common: {
      white: '#FFFFFF',
      black: '#0A000C',
      azure: '#09EBF6',
      scorpionGrey: '#575757',
      weakWhite: '#E4E3E3',
    },
    purple: {
      100: '#211148',
      200: '#692DFF',
      border: '#7613FF',
      disabled: '#8562B6',
    },
    blue: {
      100: '#09EBF6',
      drop: '#10DEF6',
    },
    red: {
      100: '#F63737',
    },
  },
});

export default theme;
