import { Box } from '@mui/material';

const EmptyGridMessage = ({ node }) => (
  <Box
    sx={{
      color: '#fff',
      fontSize: '16px',
      lineHeight: '19px',
      fontWeight: '400',
      textAlign: 'center',
      alignSelf: 'center',
    }}>
    {`There are no ${node} here yet`}
  </Box>
);

export default EmptyGridMessage;
