import { useWeb3React } from '@web3-react/core';
import { nanoid } from 'nanoid';
import React from 'react';
import { useQuery } from 'react-query';
import request from '../../../utils/request';

const MetacellLeaderBoardQuery = 'metacell-leaderboard-query-key';

const mappingToBoardData = ({ tokenId, stage, ...rest }) => ({
  rest,
  tokenId,
  stage,
  owner: rest?.buyerAddress || rest?.sellerAddress,
  pKey: nanoid(8),
});

export const useMetacellLeaderboardQuery = () => {
  const { chainId } = useWeb3React();

  const query = useQuery(MetacellLeaderBoardQuery, async () => {
    const response = await request(
      '/api/meta-cells/leaderboard',
      {
        chainId,
        limit: 30,
      },
      { method: 'GET' }
    );
    const result = [...response.data].map((p) => mappingToBoardData(p));

    return result;
  });

  return query;
};
