import { environment } from '@environment/index';
import request from '@utils/request';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { MAD_METACELL_MAINNET, MAD_SCIENTIST_MAINNET, MULTI_CALL_MAINNET } from '@utils/constants';
import SCIENTIST_ABI from '@abis/Ethereum/Scientist.json';
import METACELL_ABI from '@abis/Polygon/MetaCell.json';
import MULTICALL_ABI from '@abis/MultiCall.json';

const provider = new ethers.providers.StaticJsonRpcProvider(
  process.env.REACT_APP_MAIN_NETWORK_RPC_URL
);
const [metacell, scientist, multiCall] = [
  new ethers.Contract(MAD_METACELL_MAINNET, METACELL_ABI),
  new ethers.Contract(MAD_SCIENTIST_MAINNET, SCIENTIST_ABI),
  new ethers.Contract(MULTI_CALL_MAINNET, MULTICALL_ABI, provider),
];

const handleAggregateCall = async (data = []) => {
  const tuple = [];
  data.forEach((call) => {
    const item = [call.target, call.callData];
    tuple.push(item);
  });
  const { returnData } = await multiCall.callStatic.aggregate(tuple);
  return returnData;
};

export async function checkAccountWhitelisted(account) {
  const tubles = [metacell, scientist].map((contract) => {
    const callData = contract.interface.encodeFunctionData('balanceOf', [account]);
    return {
      target: contract.address,
      callData,
    };
  });
  const [[metacellBalance, scientistBalance], result] = await Promise.all([
    [0, 0],
    request(
      `${environment.BE_SERVER_URL}/api/alpha-testing/access/${account}`,
      {},
      {
        method: 'GET',
      }
    ),
  ]);
  if (!result?.data && Number(metacellBalance) + Number(scientistBalance) === 0) {
    return false;
  }
  return true;
}

export default function useAuth() {
  const { account } = useWeb3React();
  const [isWhitelist, setIsWhitelist] = useState();

  useEffect(() => {
    const checkWL = async (account) => {
      try {
        setIsWhitelist(undefined);
        const isWL = await checkAccountWhitelisted(account);
        if (!isWL) {
          setIsWhitelist(false);
        } else {
          setIsWhitelist(true);
        }
      } catch (error) {
        setIsWhitelist(false);
      }
    };
    if (account) {
      checkWL(account);
    }
  }, [account]);
  return isWhitelist;
}
