import { keyframes } from '@mui/system';

export const slideIn = keyframes`
    from {
      transform: translateX(-50%);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

export const slideInAndFade = keyframes`
  50% {
    transform: translate(-50%, -70px);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -70px);
    opacity: 0;
  }
`;
