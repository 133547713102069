import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentList } from '../../providers/MarketPlaceProvider';

export const useMarketplaceSlice = () => {
  const { list: data, body } = useSelector((s) => s.marketPlace);
  const dispatch = useDispatch();

  const updateCurrentMarketItems = useCallback(() => {
    setTimeout(() => {
      dispatch(updateCurrentList());
    }, 3000);
  }, [dispatch]);

  return {
    updateCurrentMarketItems,
    data,
  };
};
