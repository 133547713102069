import { styled } from '@mui/material/styles';

export const MainTabBoardContainerStyled = styled('div')`
  display: block;
  .main_board_tab__wrapper {
    padding: 0px 5px 4px;
    /* overflow: auto; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    margin-bottom: 50px;
    &::-webkit-scrollbar {
      display: none;
    }
    .tab__slider {
      .slick-arrow {
        z-index: 1;
        transform: translateY(-16px);
        &.slick-prev {
          left: 0;
        }
        &.slick-next {
          right: 0;
        }
      }
    }
  }
`;
