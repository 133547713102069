import { Grid, Typography } from '@mui/material';
import Countdown from 'react-countdown';

const LabBoostTimer = ({ isSlotFilled, cantBoost, timestamp, evolutionText, boostTime = 0 }) => {
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <Typography
        variant="outcomeTimer"
        sx={{
          color: boostTime ? '#09EBF6' : '#fff',
          letterSpacing: '2px',
          marginTop: '0.5rem',
          fontSize: '24px',
        }}>
        {`${days > 0 ? (days < 10 ? `0${days}` : `${days}`) : '00'}:${
          hours > 0 ? (hours < 10 ? `0${hours}` : `${hours}`) : '00'
        }:${minutes > 0 ? (minutes < 10 ? `0${minutes}` : `${minutes}`) : '00'}:${
          seconds > 0 ? (seconds < 10 ? `0${seconds}` : `${seconds}`) : '00'
        }`}
      </Typography>
    );
  };
  const timer = (
    <Grid container direction="column" alignItems="center" sx={{ width: '182px' }}>
      <Typography
        variant="labSlot"
        sx={{
          color: '#D35CFF',
          fontSize: '16px',
          fontWeight: '700',
        }}>
        {evolutionText}
      </Typography>
      {isSlotFilled && !cantBoost ? (
        <>
          <Countdown date={timestamp - boostTime * 1000} renderer={renderer} />
        </>
      ) : (
        <Typography
          variant="outcomeTimer"
          sx={{
            color: 'rgba(255, 255, 255, 0.4)',
            fontSize: '24px',
            marginTop: '0.5rem',
          }}>
          00:00:00:00
        </Typography>
      )}
    </Grid>
  );

  return timer;
};

export default LabBoostTimer;
