import { useState, useCallback } from 'react';

const useTicker = () => {
  const [params, setParams] = useState({ isOverflown: false, offset: 0, transitionTime: 0 });
  const ref = useCallback((node) => {
    if (node !== null) {
      const isOverflown = node.scrollWidth > node.clientWidth;
      const offset = node.scrollWidth - node.clientWidth;
      const transitionTime = offset / 50;

      setParams({
        isOverflown,
        offset,
        transitionTime,
      });
    }
    return node;
  }, []);

  return {
    ref,
    params,
  };
};

export default useTicker;
