import _toString from 'lodash/toString';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { BigNumber, ethers } from 'ethers';
import { numberToString } from '.';

export default (token = {}) => {
  if (!_isEmpty(token)) {
    const tokenId = token?.tokenId ? Number(token?.tokenId) : token?.id;
    let priceBigNumber;
    if (token?.priceHex) {
      priceBigNumber = BigNumber.from(token?.priceHex);
    } else {
      priceBigNumber = token?.price
        ? ethers.utils.parseUnits(numberToString(`${token?.price}`))
        : undefined;
    }
    const transformModule = {
      ...token,
      id: tokenId,
      price: _toString(token?.price),
      priceBigNumber,
      name: token?.name || 'Auto Cannon',
      abilities: token?.abilities || {
        rarity: 'rare',
        type: 'Combat',
      },
      describe: token?.describe || 'Big sniper rifle that takes 2-3 module slots.',
      specs: token?.specs || {
        distance: 120,
        damage: 36,
        volley: 36,
        battery: 35,
        holder: 16,
        speed: 13,
        cooldown: 120,
        moduleHp: 50,
        range: 20,
        angle: 85,
        ec: 15,
      },
    };
    return transformModule;
  }
  return token;
};
