import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useAppStore } from '@store';

const maxDaysToEvolution = Math.floor(3562000 / (60 * 60 * 24));

export default function CountdownEvolution({
  time,
  seconds,
  minutes,
  hours,
  days,
  id,
  setReadyForEvolution,
  sx,
  completed,
}) {
  const navigate = useNavigate();
  const { setEvolutionMetacell } = useAppStore();

  const circleLength = 297.9692077636719;
  const dimension = days ? maxDaysToEvolution : hours ? 24 : 60;
  const fillToCurrentTime = keyframes`
to {
  stroke-dashoffset: ${circleLength * (time / dimension)};
}
`;

  const fillFromCurrentTime = keyframes`
from {
  stroke-dashoffset: ${circleLength * (time / dimension)};
}
to {
  stroke-dashoffset: 0;
}
`;

  const fillFull = keyframes`
from {
  stroke-dashoffset: ${circleLength};
}
to {
  stroke-dashoffset: 0;
}
`;

  const animation = useRef(null);

  const [animationParams, setAnimationParams] = useState(() => {
    const params = {
      animation: fillFull,
      duration: '1s',
      iteration: 1,
      shiftSeconds: 0,
      dimension: 's',
      wasInit: false,
      color: '#09EBF6',
      shadow: false,
    };

    if (days) {
      params.animation = fillToCurrentTime;
      params.shiftSeconds = 86400;
      params.dimension = 'd';
      params.color = '#6912E1';
    } else if (hours) {
      params.animation = fillToCurrentTime;
      params.shiftSeconds = 3600;
      params.dimension = 'h';
      params.color = '#9040FF';
    } else if (minutes) {
      params.animation = fillToCurrentTime;
      params.shiftSeconds = 60;
      params.dimension = 'm';
      params.color = '#3700C1';
    } else if (seconds) {
      params.iteration = seconds;
      params.color = '#00B8C1';
    }

    return params;
  });

  useEffect(() => {
    if (animation && animation.current) {
      const animate = animation.current;

      const changeParams = () => {
        setAnimationParams((oldParams) => {
          const params = {};

          if (oldParams.animation === fillToCurrentTime) {
            params.animation = fillFromCurrentTime;
            params.duration = `${
              days * 24 * 60 * 60 +
              hours * 60 * 60 +
              minutes * 60 +
              seconds -
              oldParams.shiftSeconds
            }s`;
            params.wasInit = true;
          }

          if (oldParams.wasInit || oldParams.animation === fillFull) {
            params.animation = fillFull;
            params.duration =
              oldParams.dimension === 'd' ? '86400s' : oldParams.dimension === 'h' ? '3600s' : '1s';
            params.iteration = oldParams.dimension === 'm' ? 59 : 1;
            params.dimension =
              oldParams.dimension === 'd' ? 'h' : oldParams.dimension === 'h' ? 'm' : 's';
            params.color =
              oldParams.dimension === 'd'
                ? '#9040FF'
                : oldParams.dimension === 'h'
                ? '#3700C1'
                : oldParams.dimension === 'm'
                ? '#00B8C1'
                : '#09EBF6';
            params.shadow = oldParams.dimension === 's' ? true : oldParams.shadow;
          }

          return { ...oldParams, ...params };
        });

        if (!minutes && setReadyForEvolution) setReadyForEvolution(true);
      };

      animate.addEventListener('animationend', changeParams);

      return () => {
        animate.removeEventListener('animationend', changeParams);
      };
    }
  }, [animationParams]);

  const innertCircleLength = 279.1500549316406;

  const innerTime = days ? hours : hours ? minutes : seconds;
  const innerDimension = days ? 24 : 60;

  const innerFillToCurrentTime = keyframes`
to {
  stroke-dashoffset: ${innertCircleLength * (innerTime / innerDimension)};
}
`;

  const innerFillFromCurrentTime = keyframes`
from {
  stroke-dashoffset: ${innertCircleLength * (innerTime / innerDimension)};
}
to {
  stroke-dashoffset: 0;
}
`;

  const innerFillFull = keyframes`
from {
  stroke-dashoffset: ${innertCircleLength};
}
to {
  stroke-dashoffset: 0;
}
`;

  const innerAnimation = useRef(null);

  const [innerAnimationParams, setInnerAnimationParams] = useState(() => {
    const innerParams = {
      innerAnimation: innerFillFull,
      innerDimension: 's',
      innerDuration: '1s',
      innerIteration: 1,
      wasInit: false,
    };

    if (days) {
      innerParams.innerAnimation = innerFillToCurrentTime;
      innerParams.innerDimension = 'd';
    } else if (hours) {
      innerParams.innerAnimation = innerFillToCurrentTime;
      innerParams.innerDimension = 'h';
    } else if (minutes) {
      innerParams.innerAnimation = innerFillToCurrentTime;
      innerParams.innerDimension = 'm';
    } else {
      innerParams.innerIteration = 0;
    }

    return innerParams;
  });

  useEffect(() => {
    if (innerAnimation && innerAnimation.current) {
      const animate = innerAnimation.current;

      const changeInnerParams = () => {
        setInnerAnimationParams((oldInnerParams) => {
          const innerParams = {};

          if (oldInnerParams.innerAnimation === innerFillToCurrentTime) {
            innerParams.innerAnimation = innerFillFromCurrentTime;
            innerParams.innerDuration =
              oldInnerParams.innerDimension === 'd'
                ? `${hours * 60 * 60 + minutes * 60 + seconds}s`
                : oldInnerParams.innerDimension === 'h'
                ? `${minutes * 60 + seconds}s`
                : `${seconds}s`;
            innerParams.wasInit = true;
          }

          if (oldInnerParams.wasInit) {
            innerParams.innerAnimation = innerFillFull;
            innerParams.innerDuration =
              oldInnerParams.innerDimension === 'd' && days - 1
                ? '86400s'
                : oldInnerParams.innerDimension === 'd' ||
                  oldInnerParams.innerDimension === 'dl' ||
                  (oldInnerParams.innerDimension === 'h' && hours - 1)
                ? '3600s'
                : '60s';
            innerParams.innerIteration =
              oldInnerParams.innerDimension === 'd' && days - 1
                ? days - 1
                : oldInnerParams.innerDimension === 'd' || oldInnerParams.innerDimension === 'dl'
                ? 23
                : oldInnerParams.innerDimension === 'h' && hours - 1
                ? hours - 1
                : oldInnerParams.innerDimension === 'h' || oldInnerParams.innerDimension === 'hl'
                ? 59
                : oldInnerParams.innerDimension === 'm'
                ? minutes - 1
                : 0;
            innerParams.innerDimension =
              oldInnerParams.innerDimension === 'd' && days - 1
                ? 'dl'
                : oldInnerParams.innerDimension === 'd' || oldInnerParams.innerDimension === 'dl'
                ? 'h'
                : oldInnerParams.innerDimension === 'h' && hours - 1
                ? 'hl'
                : oldInnerParams.innerDimension === 'h' || oldInnerParams.innerDimension === 'hl'
                ? 'm'
                : 's';
          }

          return { ...oldInnerParams, ...innerParams };
        });
      };

      animate.addEventListener('animationend', changeInnerParams);

      return () => {
        animate.removeEventListener('animationend', changeInnerParams);
      };
    }
  }, [innerAnimation]);

  // useEffect(() => {
  //   if (!time && setReadyForEvolution) setReadyForEvolution(true);
  // }, [time]);

  return (
    <Box
      sx={{
        ...sx,
        position: 'relative',
        height: '111px',
        [`#first-${id}`]: {
          animation: `${animationParams.animation} ${animationParams.duration} ${animationParams.iteration} forwards linear`,
        },
        [`#second-${id}`]: {
          animation: `${innerAnimationParams.innerAnimation} ${innerAnimationParams.innerDuration} ${innerAnimationParams.innerIteration} forwards linear`,
        },
      }}>
      <svg
        width="111"
        height="111"
        viewBox="0 0 111 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="55.5" cy="55.5" r="47.5" fill="none" stroke="#111" strokeWidth="2" />
        <g filter={animationParams.shadow ? 'url(#filter0_d_6062_4601)' : 'none'}>
          <circle
            ref={animation}
            id={`first-${id}`}
            cx="55.5"
            cy="55.5"
            r="47.5"
            fill="none"
            stroke={animationParams.color}
            strokeWidth="2"
            strokeDasharray={circleLength}
            strokeDashoffset={circleLength}
            transform="rotate(-90, 55.5, 55.5)"
          />
        </g>
        <circle
          ref={innerAnimation}
          id={`second-${id}`}
          cx="55.5"
          cy="55.5"
          r="44.5"
          fill="none"
          stroke="#808080"
          strokeDasharray={innertCircleLength}
          strokeDashoffset={innertCircleLength}
          transform="rotate(-90, 55.5, 55.5)"
        />
        <defs>
          <filter
            id="filter0_d_6062_4601"
            x="0"
            y="0"
            width="111"
            height="111"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="4"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_6062_4601"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0352941 0 0 0 0 0.921569 0 0 0 0 0.964706 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6062_4601" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_6062_4601"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      {completed ? (
        <Box
          sx={{
            color: '#fff',
            width: '81px',
            fontSize: '12px',
            lineHeight: '15px',
            fontWeight: '700',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setEvolutionMetacell(id);
            navigate('/lab');
          }}>
          Ready for Evolution!
        </Box>
      ) : (
        <>
          <Typography
            variant="timerNumbers"
            sx={{
              width: '40px',
              position: 'absolute',
              top: '24px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            {time.toString().length > 1 ? time : `0${time}`}
          </Typography>
          <Typography
            variant="timerDimension"
            sx={{
              color: '#A39F9F',
              width: '61px',
              position: 'absolute',
              top: '64px',
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'wrap',
              textAlign: 'center',
            }}>
            {`${
              days > 1
                ? 'Days'
                : days
                ? 'Day'
                : hours > 1
                ? 'Hours'
                : hours
                ? 'Hour'
                : minutes > 1
                ? 'Minutes'
                : minutes
                ? 'Minute'
                : seconds > 1
                ? 'Seconds'
                : 'Second'
            } to Evolution`}
          </Typography>
        </>
      )}
    </Box>
  );
}
