/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@mui/material';
import MetacellNameData from '@shared/data/MetacellNameData.json';
import { findLast } from 'lodash';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import { TwitterIcon, TwitterShareButton } from 'react-share';
import { environment } from '../../environment';
import { useMintMetacellContext } from './MintMetacellWrapper';
import { MetacellNameWrapper, MintedShareBtnWrapper } from './styles';

const listVideo = [`${process.env.REACT_APP_RESOURCE}/videos/metacell/MetaCell_001_1.mp4`];

export const MintedMetacellSession = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    metacell: { listAccountMetacell },
  } = useMintMetacellContext();

  return (
    <>
      <ReactPlayer
        playing
        loop
        playsinline
        className="bg_player"
        url={`${listVideo[0]}`}
        width="100vw"
        height="100vh"
        fallback={<Box />}
      />

      <MetacellNameWrapper isMintMetacell={location.pathname?.includes('metacell-mint')}>
        <Typography
          variant="cardName"
          sx={{
            width: '90%',
            justifyContent: 'center',
            overflow: 'hidden',
            position: 'relative',
            fontSize: 36,
            color: '#00ccff',
            lineHeight: '150%',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box className="slide-left">
            &nbsp;&nbsp;&nbsp;&nbsp;
            {MetacellNameData?.[findLast(listAccountMetacell)]}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Box>
        </Typography>
      </MetacellNameWrapper>

      <MintedShareBtnWrapper isMintMetacell={location.pathname?.includes('metacell-mint')}>
        <div className="minted_share">
          <TwitterShareButton
            className="share"
            url={`${environment.WHITELIST_URL}`}
            title={`I've just minted my #Metacell!\nThe worlds first evolving NFT from @madmetaverse\nJoin the Mad whitelist to get yours`}>
            <TwitterIcon size={32} round />
            <span>Tweet</span>
          </TwitterShareButton>
        </div>
      </MintedShareBtnWrapper>
    </>
  );
};
