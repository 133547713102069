/* eslint-disable react/jsx-one-expression-per-line */
import { DashboardScientistCard, EmptyGridMessage } from '@components';
import { withMemoComponent } from '@HOCs/withMemoComponent';
import { Box, Grid, useMediaQuery } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import { useScientistCollection } from './hooks/useScientistCollection';
import { LoadingCircle } from './LoadingCircle';

const Component = ({
  detailsButtonHandler,
  setShowMintConfirmModal,
  setCurrentToken,
  activeTab,
}) => {
  const { data, isLoading } = useScientistCollection();
  const mobile = useMediaQuery('(max-width:459px)');
  return (
    <>
      {data?.length > 0 && (
        <Grid
          sx={{
            zIndex: 1000,
            margin: '25px 35px 0',
            width: 'auto',
            position: 'absolute',
            right: 0,
            top: '70px',
          }}>
          <Grid
            sx={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
              height: '40px',
              marginLeft: 'auto',
            }}>
            {data?.length} items
          </Grid>
        </Grid>
      )}

      {isLoading ? (
        [<LoadingCircle key="loading" />]
      ) : _isEmpty(data) ? (
        <EmptyGridMessage node="Scientists" />
      ) : (
        <Box
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'start',
            gap: '15px',
            gridTemplateColumns: `repeat(auto-fit, ${
              activeTab.includes('cells') && !mobile ? '383px' : '248px'
            })`,
          }}>
          {data.map((scientist) => (
            <DashboardScientistCard
              key={scientist.id}
              token={scientist}
              detailsButtonHandler={detailsButtonHandler}
              setShowMintConfirmModal={setShowMintConfirmModal}
              setCurrentToken={setCurrentToken}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export const ScientistDashboardCard = withMemoComponent(Component);
