/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import { useContracts } from '@providers/index';
import { LoadingFullScreenCompound } from '@shared/components/LoadingFullScreenCompound';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import getWalletName from '@utils/getWalletName';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { useMintMetacellContext } from './MintMetacellWrapper';

// const VIDEO_TIMES = [6875, 34200];  // 15880
const VIDEO_TIMES = [8300, 7605]; // time of video

const accessLabSecond = 17.45;
const executeMintSecond = 24.8;

const mintMetaCellProcessUrl = `${process.env.REACT_APP_RESOURCE}/videos/mad_metacell_mint_trailer.mp4`;
// `${process.env.REACT_APP_RESOURCE}/videos/metacell-mint/metacell-mint-full.mov`;

const ProgressTx = {
  init: 'Init',
  loading: 'Loading',
  pending: 'Pending',
  success: 'Success',
  failure: 'Failure',
  updated: 'Updated',
  completed: 'Completed',
};

const backgroundMusic = new Audio(
  `${process.env.REACT_APP_RESOURCE}/sounds/METACELLS_EVOLUTION.mp3`
);

export const MintLabSession = () => {
  const { MetaCellAirDrop } = useContracts();
  const [isBgVideoPlay, setIsBgVideoPlay] = useState(false);
  const [isAnimationEnd, setAnimationEnd] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [loadedVideo, setLoadedVideo] = useState(0);

  const [mintProgress, setMintProgress] = useState(ProgressTx.init);
  const bgPlayerRef = useRef();
  const videoTimeProcess = useRef(0);

  const { proofs = [] } = useSelector((state) => state.metaCell);
  const connectedWalletStatus = JSON.parse(
    localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
  );
  const walletText = getWalletName(connectedWalletStatus);

  const {
    metacell: {
      getOwnerMetacell,
      listAccountMetacell,
      setListAccountMetacell,
      claimProofIdx,
      remainingAmount,
    },
    modal: { setMessageNotify, setOpenMintedModal, openCountdownModal },
    wallet: { account },
  } = useMintMetacellContext();

  const proofSelector = useMemo(
    () => !isEmpty(proofs) && Boolean(claimProofIdx) && proofs[claimProofIdx - 1],
    [claimProofIdx, proofs]
  );

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  // #region SpringAnimation

  const stylesVideoBg = useSpring({
    to: {
      filter: isBgVideoPlay ? 'brightness(1)' : 'brightness(0.3)',
    },
    from: {
      filter: isBgVideoPlay ? 'brightness(0.3)' : 'brightness(1)',
    },
    config: { duration: 600 },
  });

  // #endregion

  const mintMetaCell = useCallback(async () => {
    const pollingIntervalMetacell = () => {
      const timeId = setInterval(async () => {
        const newList = await getOwnerMetacell();
        if (newList) {
          const isUpdated = isEqual([...listAccountMetacell].sort(), [...newList].sort());
          if (!isUpdated) {
            setMessageNotify((o) => ({
              ...o,
              show: false,
            }));
            setOpenMintedModal(true);
            // setIsBgVideoPlay(false);
            backgroundMusic.play();
            setMintProgress(ProgressTx.completed);
            setListAccountMetacell(newList);
            clearInterval(timeId);
          }
        }
      }, 2000);
    };

    setMessageNotify((o) => ({
      show: true,
      message: `Please confirm transaction in your ${walletText}`,
      type: 'confirm',
    }));
    setMintProgress(ProgressTx.loading);

    try {
      const tx = await MetaCellAirDrop.mintForGift(account, proofSelector, claimProofIdx - 1);

      // setMintProgress(ProgressTx.pending);
      setMessageNotify((o) => ({
        show: false,
        message: 'Minting MetaCell...',
        type: 'confirm',
      }));
      setIsBgVideoPlay(true);
      backgroundMusic.pause();
      setCurrentVideo((i) => {
        return i + 1;
      });
      setTimeout(() => {
        videoTimeProcess.current += 1;
      }, 300);
      await tx.wait(1);
      // TX is successful

      // polling data list owner metacell
      pollingIntervalMetacell();
    } catch (error) {
      setMintProgress(ProgressTx.failure);
      let message = '';
      if (error?.code === -32603 || error?.code === 'INVALID_ARGUMENT') {
        message = 'User denied transaction signature';
      } else if (error.code !== 4001) {
        message = error?.data?.message
          ? error?.data?.message.match(/reason string '(.*?)'$/)[1]
          : error.message;
      } else {
        message = error?.message;
      }
      setMessageNotify((o) => ({
        message,
        show: true,
        type: 'error',
      }));
      backgroundMusic.play();
      bgPlayerRef.current.seekTo(0, 'seconds');
      videoTimeProcess.current = 0;
      setCurrentVideo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAccountMetacell, account, proofSelector, walletText]);

  const onStartProcess = async () => {
    if (videoTimeProcess.current <= 1) {
      setIsBgVideoPlay(true);
      backgroundMusic.pause();
    } else if (remainingAmount > 0) {
      mintMetaCell();
    } else {
      setMessageNotify((o) => ({
        message: 'MetaCell in this tranche is minted all',
        show: true,
        type: 'error',
      }));
    }
  };

  useEffect(() => {
    backgroundMusic.load();
    backgroundMusic.volume = 1;
    backgroundMusic.loop = true;

    setCurrentVideo(0);

    setTimeout(() => {
      setLoadedVideo((o) => {
        if (!o) {
          bgPlayerRef.current.seekTo(0, 'seconds');
          backgroundMusic.play();
        }
        return true;
      });
    }, 5000);

    return () => {
      backgroundMusic.pause();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAnimationEnd && mintProgress === ProgressTx.loading) {
      backgroundMusic.play();
      setMessageNotify((o) => ({
        ...o,
        show: true,
        type: 'confirm',
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintProgress, isAnimationEnd]);

  // useEffect(() => {
  //   if (!proofs) {
  //     setOpenCountdownModal(true);
  //   }
  // }, [proofs]);

  return (
    <>
      <animated.div
        className="bg_player__wrapper"
        style={{
          ...stylesVideoBg,
        }}>
        <ReactPlayer
          playing={isBgVideoPlay}
          onEnded={() => {
            setAnimationEnd(true);
          }}
          onProgress={(state) => {
            if (state.loadedSeconds > 3 && !loadedVideo) {
              setLoadedVideo(true);
              bgPlayerRef.current.seekTo(0, 'seconds');
              backgroundMusic.play();
            }
            if (videoTimeProcess.current === 0 && state.playedSeconds > accessLabSecond) {
              videoTimeProcess.current = 1;
              bgPlayerRef.current.seekTo(accessLabSecond + 0.05, 'seconds');
              backgroundMusic.play();
              setIsBgVideoPlay(false);
              return;
            }
            if (videoTimeProcess.current === 1 && state.playedSeconds > executeMintSecond) {
              videoTimeProcess.current = 2;
              bgPlayerRef.current.seekTo(executeMintSecond + 0.05, 'seconds');
              backgroundMusic.play();
              setIsBgVideoPlay(false);
            }
          }}
          volume={0.4}
          progressInterval={100}
          playsinline
          ref={bgPlayerRef}
          className="bg_player"
          url={`${mintMetaCellProcessUrl}`}
          width="100vw"
          height="100vh"
          fallback={<img src="/videos/step_1.jpeg" alt="fallback" />}
        />
      </animated.div>

      {!openCountdownModal && (
        <LoadingFullScreenCompound isLoading={proofSelector === [] || !loadedVideo}>
          {!proofSelector ? (
            <button
              type="button"
              onClick={() => {
                openInNewTab('');
              }}
              className="start__button not_whitelist">
              <img src="/images/mint-metacell-bg.svg" alt="mint button" />
              <span className="start__button__text">
                Join the MAD Discord to find out how to mint a MAD Metacell
              </span>
            </button>
          ) : (
            (!videoTimeProcess.current || videoTimeProcess.current) <= 2 && (
              <button
                type="button"
                onClick={onStartProcess}
                className={`start__button in_whitelist ${
                  isBgVideoPlay ? 'start__button--hide' : ''
                }`}>
                <img src="/images/mint-metacell-bg.svg" alt="mint button" />
                <span className="start__button__text ">
                  {!videoTimeProcess.current
                    ? 'Initiate Mad Protocol'
                    : videoTimeProcess.current > 1
                    ? 'EXECUTE NOW'
                    : 'ACCESS LAB'}
                </span>
              </button>
            )
          )}
        </LoadingFullScreenCompound>
      )}
    </>
  );
};
