import { useContracts } from '@providers/index';
import { formatEther } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';

const useGetInfoScientist = (account = '', ...deps) => {
  const { Scientist } = useContracts();
  const [infoScientist, setInfoScientist] = useState({
    price: 0,
    isClaimed: false,
    remainingAmount: 0,
  });

  useEffect(() => {
    const getScientistInfo = async () => {
      try {
        setInfoScientist({ price: null, isClaimed: null, remainingAmount: null });

        const [price, isClaimed, remainingAmount] = await Promise.all([
          Scientist.price(),
          false,
          Scientist.remainingAmount(),
        ]);

        setInfoScientist(() => ({
          price: formatEther(price),
          isClaimed: Boolean(isClaimed),
          remainingAmount: Number(remainingAmount),
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Error', err);
      }
    };
    getScientistInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, ...deps]);

  return infoScientist;
};

export default useGetInfoScientist;
