/* eslint-disable prettier/prettier */
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MarketPlaceContainer = styled(Box)`
  /* background-image: url('/images/theFieldBg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  margin: 36px 4% 0 4%;
  max-width: 100%;
  @media (max-width: 767px) {
    margin: 36px 4% 0 4%;
  }
  .the-field__title {
    justify-content: flex-start;
    @media (max-width: 1024px) {
      justify-content: center;
    }
  }
  .market-place__container {
    max-width: 100%;
    padding: 38px 45px;
    margin: 22px 0;
    min-height: calc(100vh - 70px - 98px - 88px);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    @media (max-width: 767px) {
      padding: 38px 16px;
    }
  }
  .market-place__sort-select {
    margin: 46px 0 30px auto;
    display: flex;
    justify-content: flex-end;
    .custom-select-container {
      margin-left: auto;
    }
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      .custom-select-container {
        margin: 0 auto 0 0;
      }
      .MuiButton-root {
        margin: 20px 0 0;
      }
    }
  }
  .market-place__shop {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 54px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    .market-place__shop-filter {
      margin-right: 16px;
      min-width: 291px;
      width: 291px;
      margin-top: -24px;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    .market-place__shop-items {
      padding: 0 0 0 16px;
      width: 100%;
      border-left: solid 1px #b9b9b9;
      margin-left: unset;

      @media (max-width: 1000px) {
        border-left: none;
        border-top: solid 1px #b9b9b9;
        margin-top: 20px;
        padding: 20px 0 0;
      }

      .market-place__shop-loading {
        padding: 0;
        align-self: center;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }
      .market-place__shop-item {
        width: 100%;
        display: flex;
        justify-content: center;

        &:nth-of-type(1),
        :nth-of-type(2),
        :nth-of-type(3),
        :nth-of-type(4) {
          margin-top: -24px;
        }
        & > .MuiGrid-container {
          width: 100%;
        }
        @media (max-width: 1534px) {
          &:nth-of-type(4) {
            margin-top: 0px;
          }
        }
        @media (max-width: 1199px) {
          &:nth-of-type(3),
          :nth-of-type(4) {
            margin-top: 0px;
          }
        }
        @media (max-width: 899px) {
          &:nth-of-type(n) {
            margin-top: 0;
          }
          padding-top: 20px;
          &:nth-of-type(1) {
            padding-top: 0;
          }
        }
      }
      .market-place__shop-empty {
        width: 100%;
        opacity: 0.4;
        @media (max-width: 1000px) {
          margin-top: 20px;
        }
      }
      .market-place__shop-pagination {
        .MuiPagination-ul {
          justify-content: center;
        }
        @media (max-width: 600px) {
          margin-top: 24px;
        }
      }
    }
  }
`;

export const MarketPlaceItemTypeButton = styled(Button)`
  padding: 12px 20px;
  height: 40px;
  border-radius: 20px;
  background: none;
  color: #fff;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-transform: none;
  margin-right: 20px;
  &.active {
    border: solid 1px #fff;
  }
  @media (max-width: 576px) {
    margin-right: 10px !important;
  }
`;
