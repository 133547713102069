import { useRef } from 'react';
import { Modal, Box, Grid, Typography, LinearProgress } from '@mui/material';
import { useWrapDetect, useTicker } from '@hooks';
import { formatPrice } from '@utils';
import SellButton from '@components/Buttons/SellButton';
import RemoveButton from '@components/Buttons/RemoveButton';
import BuyButton from '@components/Buttons/BuyButton';
import { useWeb3React } from '@web3-react/core';

const NanocellDetailsModal = ({
  detailsButtonHandler,
  buyButtonHandler,
  open,
  sellButtonHandler,
  removeButtonHandler,
  editPriceButtonHandler,
  cell,
  showUsers,
  field,
}) => {
  const { account } = useWeb3React();
  const ref = useRef(null);
  const isWraped = useWrapDetect(open, ref, 405);
  const buttonRef = useRef(null);
  const isButtonWraped = useWrapDetect(open, buttonRef, 36);
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();
  const {
    ref: priceRef,
    params: {
      isOverflown: isPriceOverflown,
      offset: priceOffset,
      transitionTime: priceTransitionTime,
    },
  } = useTicker();

  return (
    <Modal
      disablePortal
      open={open}
      BackdropProps={{ sx: { background: 'rgba(0,0,0,0.7)' } }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      onClose={detailsButtonHandler}>
      <Grid
        ref={ref}
        container
        direction="column"
        sx={{
          maxWidth: '918px',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(20px)',
          borderRadius: '14px',
          p: '26px',
          position: isWraped ? 'static' : 'absolute',
          top: '50%',
          left: '50%',
          transform: isWraped ? 'none' : 'translate(-50%, -50%)',
        }}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
          onClick={detailsButtonHandler}>
          <path
            opacity="0.4"
            d="M0.260185 11.7415C0.374895 11.8563 0.510462 11.9319 0.666886 11.9685C0.82331 12.0102 0.977126 12.0102 1.12834 11.9685C1.28476 11.9319 1.42033 11.8589 1.53504 11.7493L6.00093 7.2727L10.4746 11.7493C10.6415 11.9163 10.8501 11.9998 11.1003 11.9998C11.3506 12.005 11.5644 11.9189 11.7417 11.7415C11.9137 11.5641 11.9998 11.3502 11.9998 11.0998C11.9998 10.8493 11.9164 10.6406 11.7495 10.4737L7.27578 5.99702L11.7495 1.52821C11.9164 1.36125 11.9998 1.15255 11.9998 0.902109C12.005 0.646451 11.919 0.432532 11.7417 0.260354C11.5644 0.0881761 11.3506 0.00208701 11.1003 0.00208701C10.8501 0.00208701 10.6415 0.0855673 10.4746 0.252528L6.00093 4.72916L1.53504 0.252528C1.42033 0.14296 1.28476 0.0699148 1.12834 0.0333921C0.977126 -0.00834803 0.82331 -0.0109568 0.666886 0.0255659C0.510462 0.0620885 0.374895 0.140351 0.260185 0.260354C0.150688 0.37514 0.0750833 0.510795 0.0333704 0.667321C-0.00312847 0.823847 -0.00312847 0.980372 0.0333704 1.1369C0.0750833 1.28821 0.150688 1.41864 0.260185 1.52821L4.72608 5.99702L0.260185 10.4737C0.150688 10.5832 0.0750833 10.7163 0.0333704 10.8728C-0.00834259 11.0241 -0.0109497 11.178 0.0255492 11.3345C0.0672622 11.4963 0.145474 11.6319 0.260185 11.7415Z"
            fill="white"
          />
        </svg>
        <Grid container justifyContent="center" gap="33px" sx={{ p: '7px 19px 18px 18px' }}>
          <Box sx={{ width: '316px', height: '316px' }}>
            <img
              src={cell?.image || `${process.env.REACT_APP_RESOURCE}/metacell/1.png`}
              style={{
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                borderRadius: '14px',
                boxShadow: 'inset 0px 0px 50px 15px rgba(0, 0, 0, 0.3)',
              }}
              onError={(e) => {
                e.target.src = `${process.env.REACT_APP_RESOURCE}/metacell/1.png`;
              }}
              alt="cell"
            />
          </Box>
          {showUsers || !field ? (
            <Box
              sx={{
                width: '125px',
                height: '316px',
                ml: isWraped ? 0 : '-23px',
                background: '#000',
                borderRadius: '16px',
              }}
            />
          ) : null}
          <Grid container direction="column" sx={{ width: 'auto', flexGrow: 1 }}>
            <Grid
              container
              alignItems="center"
              justifyContent={isWraped ? 'center' : 'flex-start'}
              gap="8px"
              sx={{ width: 'auto' }}>
              <Typography variant="modalId" sx={{ opacity: 0.4, height: '21px' }}>
                Token ID
              </Typography>
              <Typography
                variant="modalId"
                sx={{
                  background: 'rgba(255, 255, 255, 0.08)',
                  borderRadius: '4px',
                  height: '21px',
                  p: '0 4px',
                }}>
                {`# ${cell?.id}`}
              </Typography>
            </Grid>
            <Typography
              ref={nameRef}
              variant="modalName"
              sx={{
                height: '42px',
                mt: '3px',
                justifyContent: 'flex-start',
                maxWidth: isWraped ? '300px' : '345px',
                overflow: 'hidden',
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                },
                '&:hover:after': {
                  width: 0,
                  height: 0,
                },
              }}>
              <Box
                sx={{
                  '&:hover': isNameOverflown && {
                    transform: `translateX(-${nameOffset}px)`,
                    transition: `transform ${nameTransitionTime}s`,
                  },
                }}>
                {cell?.name}
              </Box>
            </Typography>
            <Grid
              ref={buttonRef}
              container
              justifyContent={isButtonWraped ? 'center' : 'space-between'}
              alignItems="center"
              gap="10px"
              sx={{ mt: field ? '17px' : '25px' }}>
              {field ? (
                <>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    gap="10px"
                    sx={{ width: 'auto' }}>
                    <Typography
                      variant="modalId"
                      sx={{
                        height: '21px',
                        opacity: 0.4,
                      }}>
                      PRICE
                    </Typography>
                    <Grid
                      container
                      gap="4px"
                      flexWrap="nowrap"
                      sx={{ width: 'auto', maxWidth: '180px' }}>
                      <Typography
                        ref={priceRef}
                        variant="modalPrice"
                        sx={{
                          height: '22px',
                          justifyContent: 'flex-start',
                          overflow: 'hidden',
                          position: 'relative',
                          '&:after': {
                            content: '""',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                          },
                          '&:hover:after': {
                            width: 0,
                            height: 0,
                          },
                        }}>
                        <Box
                          sx={{
                            '&:hover': isPriceOverflown && {
                              transform: `translateX(-${priceOffset}px)`,
                              transition: `transform ${priceTransitionTime}s`,
                            },
                          }}>
                          {formatPrice(cell?.price, 4)}
                        </Box>
                      </Typography>
                      <Typography
                        variant="modalPrice"
                        sx={{
                          height: '22px',
                        }}>
                        BIOMETA
                      </Typography>
                    </Grid>
                    {showUsers && cell?.onSale ? (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          border: '1px solid #09EBF6',
                          borderRadius: '100px',
                          width: '19px',
                          height: '19px',
                          cursor: 'pointer',
                          ':hover': { background: '#09595e' },
                        }}
                        onClick={() => {
                          editPriceButtonHandler();
                        }}>
                        <img src="/images/Pencil.png" width="8.55px" height="8.55px" alt="pencil" />
                      </Grid>
                    ) : null}
                  </Grid>
                  {account === cell?.owner ? (
                    cell?.onSale ? (
                      <RemoveButton
                        removeButtonHandler={removeButtonHandler}
                        sx={{ width: '125px' }}
                      />
                    ) : (
                      <SellButton sellButtonHandler={sellButtonHandler} sx={{ width: '125px' }} />
                    )
                  ) : (
                    <BuyButton buyButtonHandler={buyButtonHandler} sx={{ width: '125px' }} />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant="modalId"
                    sx={{
                      height: '21px',
                      color: cell?.onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.2)',
                    }}>
                    {cell?.onSale ? 'On sale' : 'Not on sale'}
                  </Typography>
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      background: cell?.onSale ? '#09EBF6' : 'rgba(255, 255, 255, 0.1)',
                      boxShadow: cell?.onSale ? '0px 0px 4px 4px rgba(9, 235, 246, 0.25)' : 'none',
                    }}
                  />
                </>
              )}
            </Grid>
            <Box
              sx={{
                width: '100%',
                mt: field ? '19px' : '26px',
                pb: '25px',
                borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
              }}>
              <Grid container gap="4px" sx={{ width: 'auto' }}>
                <Typography variant="modalLevel" sx={{ opacity: 0.4, height: '16px' }}>
                  Level:
                </Typography>
                <Typography variant="modalLevel">{65}</Typography>
              </Grid>
              <LinearProgress
                variant="determinate"
                value={65}
                sx={{
                  height: '6px',
                  borderRadius: '7px',
                  backgroundColor: '#242424',
                  mt: '4px',
                  '.MuiLinearProgress-bar': {
                    background:
                      'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
                  },
                }}
              />
            </Box>
            <Grid container justifyContent="space-between" gap="8px" sx={{ mt: '14px' }}>
              <Grid container direction="column" gap="8px" sx={{ flexBasis: '138px' }}>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    height: '31px',
                    border: '0.5px solid rgba(255, 255, 255, 0.4)',
                    borderRadius: '7px',
                    p: '0 8px',
                  }}>
                  <Typography variant="altPrice" sx={{ color: '#E6E6E6' }}>
                    Endurance:
                  </Typography>
                  <Typography variant="modalLevel">{cell?.Endurance}</Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    height: '31px',
                    border: '0.5px solid rgba(255, 255, 255, 0.4)',
                    borderRadius: '7px',
                    p: '0 8px',
                  }}>
                  <Typography variant="altPrice" sx={{ color: '#E6E6E6' }}>
                    Speed:
                  </Typography>
                  <Typography variant="modalLevel">{cell?.Speed}</Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    height: '31px',
                    border: '0.5px solid rgba(255, 255, 255, 0.4)',
                    borderRadius: '7px',
                    p: '0 8px',
                  }}>
                  <Typography variant="altPrice" sx={{ color: '#E6E6E6' }}>
                    Attack:
                  </Typography>
                  <Typography variant="modalLevel">{cell?.Attack}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="column" gap="8px" sx={{ flexBasis: '138px' }}>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    height: '31px',
                    border: '0.5px solid rgba(255, 255, 255, 0.4)',
                    borderRadius: '7px',
                    p: '0 8px',
                  }}>
                  <Typography variant="altPrice" sx={{ color: '#E6E6E6' }}>
                    AI:
                  </Typography>
                  <Typography variant="modalLevel">{cell?.Ai}</Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    height: '31px',
                    border: '0.5px solid rgba(255, 255, 255, 0.4)',
                    borderRadius: '7px',
                    p: '0 8px',
                  }}>
                  <Typography variant="altPrice" sx={{ color: '#E6E6E6' }}>
                    Energy:
                  </Typography>
                  <Typography variant="modalLevel">{cell?.Energy}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NanocellDetailsModal;
