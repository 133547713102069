import React from 'react';

export default function getWalletName(connectedWalletStatus) {
  if (connectedWalletStatus?.WalletLink) {
    return 'Coinbase wallet';
  }
  if (connectedWalletStatus?.Injected) {
    return 'MetaMask wallet';
  }
  if (connectedWalletStatus?.WalletConnect) {
    return 'Wallet connect';
  }
  return 'BitKeep wallet';
}
