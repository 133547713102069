import React from 'react';
import { Button } from '@mui/material';

const RemoveButton = ({ removeButtonHandler, disabled, sx, text = 'Remove' }) => {
  return (
    <Button
      variant="outlined"
      disabled={disabled}
      sx={{
        color: '#fff',
        border: '1px solid #EB3793',
        borderRadius: '18px',
        width: '74px',
        maxWidth: '100%',
        height: '36px',
        fontSize: '14px',
        lineHeight: '16.7px',
        fontWeight: 600,
        textTransform: 'none',
        '&:hover': {
          border: '1px solid #EB3793',
          background: 'rgba(235, 55, 147, 0.5)',
        },
        '&:active': {
          border: '1px solid #EB3793',
          background: 'rgba(235, 55, 147, 0.5)',
        },
        '&:disabled': {
          border: '1px solid rgba(255, 255, 255, 0.1)',
          color: 'rgba(255, 255, 255, 0.2)',
        },
        ...sx,
      }}
      onClick={removeButtonHandler}>
      {text}
    </Button>
  );
};

export default RemoveButton;
