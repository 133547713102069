import { Typography } from '@mui/material';

const MainTab = ({ tab, active, setActiveTab }) => {
  return (
    <Typography
      variant="mainTab"
      component="div"
      sx={{
        color: active ? '#fff' : '#B9B9B9',
        height: '29px',
        cursor: 'pointer',
        position: 'relative',
        mb: '16px',
        '&:before': {
          display: 'block',
          position: 'absolute',
          bottom: '-15px',
          left: '-10px',
          content: '""',
          width: 'calc(100% + 20px)',
          height: '1px',
          background: '#B9B9B9',
        },
        '&:after': active
          ? {
              display: 'block',
              position: 'absolute',
              bottom: '-16px',
              left: '0px',
              content: '""',
              width: '100%',
              height: '3px',
              background: '#09EBF6',
              boxShadow: '0px 0px 10px 4px rgba(9, 235, 246, 0.25)',
              borderRadius: '8px',
              zIndex: 1,
            }
          : null,
        '&:first-of-type:before': { width: 'calc(100% + 10px)', left: 0 },
        '&:last-of-type:before': { width: 'calc(100% + 10px)' },
      }}
      onClick={() => {
        setActiveTab(tab);
      }}>
      {tab}
    </Typography>
  );
};

export default MainTab;
