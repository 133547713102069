import { LabOutcomeCard } from '@components';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { slideIn, slideInAndFade } from '../animation';
import { useLabSlotAction, useLabSlotData } from '../context/hooks';
import { useListenEvolutionEvent } from '../context/useListenEvolutionEvent';

const componentSx = {
  container: {
    flex: '0 1 373px',
    minHeight: '650px',
  },
  boxContainer: { position: 'relative', width: '279px', height: '354px', mt: '102px' },
  typographyOutcomeMessage: {
    mt: '182px',
    opacity: 0.4,
    height: '20px',
  },
  typographyMessage: {
    color: '#23D453',
    position: 'absolute',
    left: '50%',
    top: '20px',
    opacity: 0,
    transform: 'translateX(-50%)',
    animation: `${slideInAndFade} 1.5s 1.5s forwards`,
  },
  grid: {
    mt: '30px',
    p: '14px',
    background: '#17181C',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#09EBF6',
    borderRadius: '14px',
    width: '100%',
    maxWidth: '279px',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    opacity: 0,
    animation: `${slideIn} 1s forwards`,
  },
  boxImg: { width: '74px', height: '74px' },
  imgNanoCell: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '14px',
  },
  typographyTokenId: {
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: '4px',
    height: '19px',
    p: '0 4px',
  },
  linerProcess: {
    borderRadius: '7px',
    backgroundColor: '#242424',
    mt: '3px',
    '.MuiLinearProgress-bar': {
      background:
        'linear-gradient(90deg, #00ABE2 0%, #45A0EA 12%, #9493F3 26%, #CE89F9 38%, #F183FD 47%, #FF81FF 51%, #F982FE 56%, #E785FC 62%, #CA8AF9 69%, #A190F4 77%, #6D99EE 85%, #2FA3E7 94%, #00ABE2 99%)',
    },
  },
  typographyLevelUp: {
    mt: '70px',
    zIndex: 1,
    opacity: 0,
    p: '10px 15px',
    background: '#AB6EFF',
    borderRadius: '14px',
    cursor: 'pointer',
    animation: `${slideIn} 1s forwards`,
  },
};

const SixDotsSvg = () => (
  <svg
    style={{ cursor: 'pointer' }}
    id="details"
    width="15"
    height="20"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.5" cy="1.5" r="1.5" fill="#B9B9B9" />
    <circle cx="1.5" cy="6.5" r="1.5" fill="#B9B9B9" />
    <circle cx="1.5" cy="11.5" r="1.5" fill="#B9B9B9" />
    <circle cx="7.5" cy="1.5" r="1.5" fill="#B9B9B9" />
    <circle cx="7.5" cy="6.5" r="1.5" fill="#B9B9B9" />
    <circle cx="7.5" cy="11.5" r="1.5" fill="#B9B9B9" />
  </svg>
);

const RightComponent = ({
  detailsButtonHandler: _detailsButtonHandler,
  setOutcome: _setOutcome,
  showSplit,
  split,
  setShowSplit: _setShowSplit,
  setSplit: _setSplit,
}) => {
  const { _updateCellOutcome } = useLabSlotAction();
  const { cellOutcome } = useLabSlotData();
  useListenEvolutionEvent();

  const renderSplit = useMemo(() => {
    if (showSplit && split?.id) {
      return (
        <Grid
          container
          gap="13px"
          sx={componentSx.grid}
          onClick={(e) => {
            if (e.target.id.includes('details')) {
              _detailsButtonHandler(split);
            } else {
              _setShowSplit(false);
              _setSplit(null);
            }
          }}>
          <Box sx={componentSx.boxImg}>
            <img
              src={split?.image || '/images/Blue Nanocell.png'}
              style={componentSx.imgNanoCell}
              alt="split"
            />
          </Box>
          <Grid container direction="column" sx={{ width: 'auto', flexGrow: 1 }}>
            <Grid container justifyContent="space-between" sx={{ overflow: 'hidden' }}>
              <Grid container alignItems="center" gap="8px" sx={{ width: 'auto' }}>
                <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
                  Token ID
                </Typography>
                <Typography variant="tokenId" sx={componentSx.typographyTokenId}>
                  {`# ${split.id}`}
                </Typography>
              </Grid>
              <SixDotsSvg />
            </Grid>
            <Typography
              variant="cardName"
              sx={{ height: '19px', mt: '3px', justifyContent: 'flex-start' }}>
              {split.name}
            </Typography>
            <Box sx={{ width: '100%', mt: '12px' }}>
              <Grid container gap="4px" sx={{ width: 'auto' }}>
                <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '14px' }}>
                  Level:
                </Typography>
                <Typography variant="cardAttribute">1</Typography>
              </Grid>
              <LinearProgress variant="determinate" value={1} sx={componentSx.linerProgress} />
            </Box>
          </Grid>
        </Grid>
      );
    }
    if (showSplit && split) {
      return (
        <Typography
          variant="ERC20forLab"
          sx={componentSx.typographyLevelUp}
          onClick={() => {
            _setShowSplit(false);
            _setSplit(null);
          }}>
          {`+ ${split}`}
          <img
            src="/icons/mdma-token-icon.svg"
            alt="mdmaIcon"
            width={23}
            height={23}
            className="mad_token__icon"
          />
        </Typography>
      );
    }
    return null;
  }, [showSplit, split]);

  return (
    <Grid container direction="column" alignItems="center" sx={componentSx.container}>
      <Box sx={componentSx.boxContainer}>
        {cellOutcome ? (
          <>
            <Typography
              key={cellOutcome.bag}
              variant="cardPrice"
              sx={componentSx.typographyMessage}>
              {cellOutcome.bag}
            </Typography>
            <LabOutcomeCard
              key={cellOutcome.cell.nanoId}
              cell={cellOutcome.cell}
              detailsButtonHandler={_detailsButtonHandler}
              setOutcome={_updateCellOutcome}
            />
          </>
        ) : (
          <Typography variant="emptyMessage" sx={componentSx.typographyOutcomeMessage}>
            Outcome cell will appear here
          </Typography>
        )}
      </Box>
      {renderSplit}
    </Grid>
  );
};

export const RightSideLab = memo(RightComponent);
