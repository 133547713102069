import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

let levelData;
let heightData;
let colorPrimaryBackground;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: heightData,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: colorPrimaryBackground,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: levelData === 100 ? '#F63737' : '#09EBF6',
  },
}));

export default function CustomizedProgressBars({ level, height, color }) {
  colorPrimaryBackground = color;
  levelData = level;
  heightData = height;
  return <BorderLinearProgress variant="determinate" value={level} />;
}
