import styled from '@emotion/styled';

export const WrapperBanner = styled('div')`
  background: #61affe;
  height: 40px;
  padding: 0 50px;
  @media (max-width: 1100px) {
    height: 80px;
  }
`;

export const BannerContent = styled('div')`
  background: transparent;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: Montserrat, monospace;
  white-space: nowrap;
  overflow-x: auto;
  @media (max-width: 1280px) {
    justify-content: start;
  }
  @media (max-width: 1100px) {
    justify-content: center;
    height: 80px;
    white-space: normal;
    text-align: center;
    font-size: 14px;
  }
`;
