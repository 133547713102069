import useGetScreenWidth from '@hooks/common/useGetScreenWidth';
import { useWeb3React } from '@web3-react/core';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  BorderWrapper,
  CoverBackground,
  SelectFormItemButton,
  SelectFormItemWrapper,
} from '../styles';
import PickItemPopover from './PickItemPopover';
import PickRangeItemPopover from './PickRangeItemPopover';

const diffLength = 100;

const SelectFormItem = forwardRef(
  (
    {
      label,
      name,
      value,
      onSelectValue = () => {},
      onChangeRangeValue = () => {},
      selectableValues = [],
      isSelectRange = false,
      modalLeftDistance,
      isMinted,
    },
    ref
  ) => {
    const { account } = useWeb3React();
    const { wakeUpCoverRef, wrapperRef } = ref;
    const [modalLineLength, setModalLineLength] = useState(0);

    const specAnchorEl = useRef(null);
    const labelRef = useRef(null);
    const [openSelectionTable, setOpenSelectionTable] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const width = useGetScreenWidth();

    const handleClickSpecItem = () => {
      setOpenSelectionTable((openSelectionTable) => !openSelectionTable);
    };

    useEffect(() => {
      setModalLineLength((pre) => {
        return (
          width -
          (labelRef.current?.getBoundingClientRect().width +
            wakeUpCoverRef.current?.getBoundingClientRect().width +
            126 +
            diffLength)
        );
      });
    }, [wakeUpCoverRef, width]);

    useEffect(() => {
      setOpenSelectionTable(false);
    }, [account]);

    const SelectorTernary = useMemo(() => {
      if (!isSelectRange) {
        return value ? (
          <img src="/icons/checkbox-checked-icon.svg" alt="checkbox" className="checkbox__icon" />
        ) : (
          <img src="/icons/checkbox-frame-icon.svg" alt="checkbox" className="checkbox__icon" />
        );
      }
      return !Object.values(value).some((item) => item < 0) ? (
        <img src="/icons/checkbox-checked-icon.svg" alt="checkbox" className="checkbox__icon" />
      ) : (
        <img src="/icons/checkbox-frame-icon.svg" alt="checkbox" className="checkbox__icon" />
      );
    }, [isSelectRange, value]);

    return (
      <SelectFormItemWrapper>
        <SelectFormItemButton
          aria-describedby={name}
          ref={specAnchorEl}
          onClick={handleClickSpecItem}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onMouseOver={() => setIsHover(true)}
          isopenpopover={openSelectionTable ? 'true' : ''}
          linelength={modalLineLength.toString()}
          disabled={isMinted}
          isdisabled={isMinted ? 'true' : ''}>
          {SelectorTernary}

          <span ref={labelRef} className="checkbox__label">
            {label}
          </span>
          {(isHover || openSelectionTable) && (
            <>
              <CoverBackground />
              <BorderWrapper />
            </>
          )}
        </SelectFormItemButton>
        {isSelectRange ? (
          <PickRangeItemPopover
            openSelectionTable={openSelectionTable}
            setOpenSelectionTable={setOpenSelectionTable}
            selectableValues={selectableValues}
            onChangeRangeValue={onChangeRangeValue}
            name={name}
            value={value}
            ref={specAnchorEl}
            modalLeftDistance={modalLeftDistance - diffLength}
          />
        ) : (
          <PickItemPopover
            openSelectionTable={openSelectionTable}
            setOpenSelectionTable={setOpenSelectionTable}
            selectableValues={selectableValues}
            onSelectValue={onSelectValue}
            name={name}
            value={value}
            ref={specAnchorEl}
            modalLeftDistance={modalLeftDistance - diffLength}
          />
        )}
      </SelectFormItemWrapper>
    );
  }
);
SelectFormItem.displayName = 'SelectFormItem';

export default SelectFormItem;
