import { useDebounce } from '@hooks/common/useDebounce';
import { useAppStore } from '@store/index';

export const useScientistCollection = () => {
  const {
    usersScientists,
    methodContract: { _updateUsersScientists },
    loading: { usersScientists: loading },
  } = useAppStore();
  const data = useDebounce(usersScientists, 500);
  const isLoading = useDebounce(loading, 500);

  return {
    isLoading: !data || isLoading,
    data: data || [],
  };
};
