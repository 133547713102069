import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
// import buttonBackground from '/images/metacellMintButton.png';

export const ScientistMintHeaderWrapper = styled('div')`
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    height: 100px;
    width: 100vw;
    background-color: transparent;
    overflow: visible;
    @media (max-width: 1024px) {
      padding: 0 40px;
    }

    .popup {
      &__wrapper {
        position: absolute;
        top: 50;
        right: 0;
        left: 0;
        border: 1px solid #1f1f1f;
        padding: 1;
        background-color: rgba(255, 255, 255, 0.04);
        pointer-events: auto;
        color: #fff;
        * {
          font-family: ${(p) =>
            p?.isMintMetacell ? "'Elemental End', Arial, sans-serif" : "'Anybody'"};
          text-transform: ${(p) => p?.isMintMetacell && 'lowercase'};
        }
      }
      &__button {
        width: 100%;
        color: #fff;
        border-radius: 0;
        background-color: #131415;
        &:hover {
          background-color: #262626;
        }

        &.balance {
          cursor: auto;
          pointer-events: none;
        }
      }
    }
  }
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  .scroll__icon {
    transform: translateX(-10px);
    padding: 0;
    min-width: unset;
    pointer-events: auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: unset;
    }
    .text__logo {
      margin-left: 10px;
      max-width: 316px;
      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  .connected__button {
    border: 1px solid rgba(255, 255, 255, 0.08);
    height: 48px;
    pointer-events: auto;
    border-radius: 0;
    padding-right: 26px;
    background-color: #131415;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    font-family: 'Anybody';
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #1a1b1c;
    }
    &:focus {
      background-color: #1a1b1c;
    }
    .MuiButton-startIcon {
      margin-right: 40px !important;
    }
    &::before {
      content: '';
      height: 100%;
      background-color: rgba(255, 255, 255, 0.08);
      width: 3px;
      top: 0;
      left: 48px;
      position: absolute;
    }
    &::after {
      content: '';
      height: calc(100% + 2px);
      background-color: #000;
      width: 1px;
      top: -1px;
      left: 49px;
      position: absolute;
    }
    &_arrow__icon {
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  .connect__button {
    pointer-events: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Anybody';
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    color: rgb(16, 24, 50);
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.075em;
    padding: 0.3em 1em;
    margin: auto 0;
    position: relative;
    align-self: center;
    text-transform: uppercase;
    border: 3px #1b2430 solid;
    border-image: linear-gradient(45deg, #1b2430 0%, #3f4e4f 100%);
    border-image-slice: 1 1 0 0;
    z-index: 1;
    // box-shadow: -0.5em 0.5em transparentize(rgb(16, 24, 50), 1);
    // transform-origin: left bottom;
    &:before,
    &:after {
      border: 2px #000 solid;
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      background: #1b2430;
    }

    // SIDE
    &:before {
      left: -0.59em;
      top: 0.15em;
      width: 0.31em;
      height: 115%;
      transform: skewY(-45deg);
    }

    // BOTTOM
    &:after {
      bottom: -0.61em;
      right: 0.16em;
      width: 103%;
      height: 0.31em;
      transform: skewX(-45deg);
    }

    // Shadow
    &:hover {
      //border-image-slice: 1;
      background-color: white;
      background-size: 90%;
      transform: translate(0.5em, -0.5em);
      box-shadow: -1em 1em 0.15em transparentize(rgb(16, 24, 50), 0.9);
      // box-shadow: -1em 1em 1em transparentize(#1B2430,.9), -1em 1.9em 1.9em transparentize(rgb(16,24,50),.9), 0em .38em .38em transparentize(rgb(16,24,50),.9), 0em .76em .76em transparentize(rgb(16,24,50),.9), 0em 1.52em 1.52em transparentize(rgb(16,24,50),.9);

      &:before {
        @include background-image(
          linear-gradient(45deg, #1b2430 0%, adjust-hue(#1b2430, 10%) 100%)
        );
        height: calc(100% - 0.13em);
        border-image-slice: 1;
      }

      &:after {
        @include background-image(linear-gradient(45deg, #1b2430 0%, #3f4e4f 100%));
        width: calc(100% - 0.13em);
        border-image-slice: 1;
      }
    }
    background-color: #1b2430;
    @include background-image(linear-gradient(45deg, #1b2430 0%, #3f4e4f 100%));
    border-image: linear-gradient(45deg, lighten(#1b2430, 20%) 0%, lighten(#3f4e4f, 20%) 100%);
    border-image-slice: 1;
    color: white;

    &:before {
      left: -0.72em;
      top: 0em;
      border-top-width: 3px;
      width: 0.5em;
      height: 125%;
    }

    &:after {
      bottom: -0.7em;
      right: -0.04em;
      border-right-width: 3px;
      width: 105%;
      height: 0.53em;
    }

    &:hover {
      background: white;
      border-image: linear-gradient(45deg, #1b2430 0%, #3f4e4f 100%);
      border-image-slice: 1;
      color: rgb(16, 24, 50);

      &:before {
        height: 125%;
      }
      &:after {
        width: 105%;
      }
    }
  }
`;

export const ButtonConnect = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1px;
  padding: 0;
  background-image: url('/images/metacellWalletButton.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 18rem;
  border-radius: 0px;
  border: 0;
  padding: 0px;
`;

export const WrapperImage = styled('div')`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperTextButton = styled('div')`
  font-family: ${(p) => (p?.isMintMetacell ? "'Elemental End', Arial, sans-serif" : "'Anybody'")};
  text-transform: ${(p) => (p?.isMintMetacell ? 'lowercase' : 'upcase')};
  text-align: center;
  width: fit-content;
  color: #fff;
  width: 10.9rem;
  margin-left: 4px;
  margin-top: 1px;
  background-color: rgba(0, 0, 0, 0.2);
`;
