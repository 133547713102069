import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Grid, Typography, CircularProgress, useMediaQuery } from '@mui/material';
import { ITEM_NAME_STORAGE } from '@shared/itemNameLocalStorage';
import { Metamask, Coinbase, Bitkeep } from '../../Icons';

const LoadingModal = ({ open, text, mining, isWert }) => {
  const [close, onClose] = useState(false);
  const mobile = useMediaQuery('(max-width:440px)');
  const connectedWalletStatus = JSON.parse(
    localStorage.getItem(ITEM_NAME_STORAGE.connectedWalletStatus)
  );
  return (
    <Modal
      open={open}
      onClose={() => onClose(true)}
      BackdropProps={{
        sx: { background: 'rgba(0,0,0,0.7)' },
      }}
      sx={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          maxWidth: mobile ? '350px' : '544px',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(20px)',
          borderRadius: '14px',
          p: '51px 83px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
        }}>
        {isWert ? (
          <img
            style={{ width: '52px', height: '52px' }}
            src="/images/wert.jpg"
            alt="wallet connect icon"
          />
        ) : mining ? (
          <CircularProgress thickness={3} size={52} sx={{ color: '#09EBF6' }} />
        ) : connectedWalletStatus?.WalletConnect ? (
          <img
            style={{ width: '52px', height: '52px' }}
            src="/images/wallet-connect-logo.png"
            alt="wallet connect icon"
          />
        ) : connectedWalletStatus?.WalletLink ? (
          <Coinbase />
        ) : connectedWalletStatus?.Injected ? (
          <Metamask />
        ) : (
          <Bitkeep />
        )}
        <Typography
          variant="modalTitle"
          sx={{
            color: '#09EBF6',
            mt: '33px',
            whiteSpace: 'normal',
            width: '100%',
            textAlign: 'center',
            breakWord: 'break-all',
            overflow: 'visible',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}>
          {text}
        </Typography>
      </Grid>
    </Modal>
  );
};

export default LoadingModal;
