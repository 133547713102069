import { Grid, Typography, Box } from '@mui/material';
import BuyButton from '@components/Buttons/BuyButton';
import { formatPrice } from '@utils';
import { useTicker } from '@hooks';

const EnhancerCard = ({ buyButtonHandler, token, setCurrentToken, showUsers, thin }) => {
  const {
    ref: nameRef,
    params: {
      isOverflown: isNameOverflown,
      offset: nameOffset,
      transitionTime: nameTransitionTime,
    },
  } = useTicker();
  const {
    ref: priceRef,
    params: {
      isOverflown: isPriceOverflown,
      offset: priceOffset,
      transitionTime: priceTransitionTime,
    },
  } = useTicker();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        width: thin ? '248px' : '279px',
        background: '#17181C',
        border: '1px solid #202228',
        borderRadius: '14px',
        p: '19px 19px 24px',
      }}>
      <Box sx={{ width: '100%', height: '122px' }}>
        <img
          src={`${process.env.REACT_APP_RESOURCE}/enhancers/${token.enhancerType}.jpg`}
          style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '14px' }}
          onError={(e) => {
            e.target.src = '/images/enhancerImageMock.png';
          }}
          alt="enhancers"
        />
      </Box>
      <Grid container justifyContent="space-between" wrap="nowrap" gap="5px" mt="20px">
        <Grid container alignItems="center" gap="8px">
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            Token ID
          </Typography>
          <Typography
            variant="tokenId"
            sx={{
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: '4px',
              height: '19px',
              p: '0 4px',
            }}>
            {`# ${token.id}`}
          </Typography>
        </Grid>
        {!showUsers ? (
          <Typography variant="tokenId" sx={{ opacity: 0.4, height: '15px' }}>
            PRICE
          </Typography>
        ) : null}
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        gap="5px"
        alignItems="center"
        sx={{ mt: '3px', overflow: 'hidden' }}>
        <Typography
          ref={nameRef}
          variant="cardName"
          sx={{
            height: '19px',
            maxWidth: '50%',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            position: 'relative',
            '&:after': {
              content: '""',
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              background: 'linear-gradient(to right, transparent 80px, #17181C)',
            },
            '&:hover:after': {
              width: 0,
              height: 0,
            },
          }}>
          <Box
            sx={{
              '&:hover': isNameOverflown && {
                transform: `translateX(-${nameOffset}px)`,
                transition: `transform ${nameTransitionTime}s`,
              },
            }}>
            {`${token.enhancerType} Enhancer`}
          </Box>
        </Typography>
        {!showUsers ? (
          <Grid
            container
            justifyContent="flex-end"
            gap="4px"
            flexWrap="nowrap"
            sx={{ maxWidth: '50%' }}>
            <Typography
              ref={priceRef}
              variant="cardPrice"
              sx={{
                height: '18px',
                justifyContent: 'flex-start',
                overflow: 'hidden',
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  background: 'linear-gradient(to right, transparent 50px, #17181C)',
                },
                '&:hover:after': {
                  width: 0,
                  height: 0,
                },
              }}>
              <Box
                sx={{
                  '&:hover': isPriceOverflown && {
                    transform: `translateX(-${priceOffset}px)`,
                    transition: `transform ${priceTransitionTime}s`,
                  },
                }}>
                {formatPrice(token.price, 4)}
              </Box>
            </Typography>
            <Typography
              variant="cardPrice"
              sx={{
                height: '18px',
              }}>
              {token.tokenAddress === '0x0000000000000000000000000000000000000000'
                ? 'ETH'
                : 'BIOMETA'}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid container justifyContent="space-between" mt="15px">
        <Typography variant="cardAttribute" sx={{ opacity: 0.4, height: '18px' }}>
          Probability:
        </Typography>
        <Typography variant="cardAttribute" sx={{ height: '18px' }}>
          {`${token.probability}%`}
        </Typography>
      </Grid>
      <Box
        sx={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          m: '20px 0 19px',
          width: '100%',
        }}
      />
      {showUsers ? (
        <Grid container justifyContent="space-between" color="#09EBF6">
          <Typography variant="cardInfo" sx={{ height: '17px' }}>
            Amount
          </Typography>
          <Typography variant="cardInfo" sx={{ height: '17px' }}>
            {token.amount}
          </Typography>
        </Grid>
      ) : (
        <BuyButton
          buyButtonHandler={() => {
            setCurrentToken(token);
            buyButtonHandler();
          }}
        />
      )}
    </Grid>
  );
};

export default EnhancerCard;
