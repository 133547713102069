import BuyButton from '@components/Buttons/BuyButton';
import { environment } from '@environment';
import { useExPrice, useWrapDetect } from '@hooks';
import { Grid, InputAdornment, Slider, Typography, useMediaQuery } from '@mui/material';
import { useAppStore } from '@store';
import { formatPrice } from '@utils';
import { BigNumber, ethers } from 'ethers';
import { Formik } from 'formik';
import { useEffect, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { TextFieldStyled } from './style';

const avgBlocksPerDay = environment.BLOCK_PER_DAY;

const BoostController = ({
  boostPrice,
  setBoostPrice,
  maxBoostPrice,
  setMaxBoostPrice,
  disabled,
  timestamp = 0,
  isSafeNumber,
  setIsSafeNumber,
}) => {
  const { boostPerBlockPrice, usersBalance, tokenPrice } = useAppStore();

  const ExPrice = useExPrice();
  const ref = useRef(null);
  const isWrapped = useWrapDetect(ref, ref, 15);
  const mobile = useMediaQuery('(max-width:480px)');

  const dayMadPrice = boostPerBlockPrice
    ? ethers.utils.formatEther(boostPerBlockPrice.mul(avgBlocksPerDay))
    : 0;
  const dayUSDPrice = dayMadPrice
    ? ethers.utils.formatUnits(
        ethers.utils.parseEther(dayMadPrice).mul(tokenPrice).mul(ExPrice),
        44
      )
    : 0;

  const _updateSafeNumber = (e) => setIsSafeNumber(e);

  const maxPrice = useMemo(() => {
    if (!BigNumber.isBigNumber(boostPerBlockPrice)) return 0;

    let maxBoostPrice;
    try {
      maxBoostPrice = boostPerBlockPrice
        .mul(Math.floor((timestamp - Date.now() + 13000) / 13000))
        .toNumber();
      _updateSafeNumber(true);
    } catch (error) {
      maxBoostPrice = Number(
        formatPrice(
          ethers.utils.formatEther(
            boostPerBlockPrice.mul(Math.floor((timestamp - Date.now() + 13000) / 13000))
          ),
          15
        )
      );
      _updateSafeNumber(false);
    }
    return maxBoostPrice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boostPerBlockPrice, timestamp]);

  useEffect(() => {
    let timer;
    if (timestamp > 0) {
      setMaxBoostPrice(maxPrice);
      timer = setInterval(() => {
        setMaxBoostPrice(maxPrice);
      }, 13000);
    }
    return () => {
      setBoostPrice('');
      setMaxBoostPrice(0);
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  const boostPriceValue = useMemo(() => {
    if (!Number(boostPrice)) return 0;
    return isSafeNumber ? ethers.utils.parseEther(boostPrice).toNumber() : Number(boostPrice);
  }, [boostPrice, isSafeNumber]);
  return (
    <Grid container direction="column" alignItems="center" sx={{ width: 'auto', mb: '25px' }}>
      <Formik
        initialValues={{
          boostAmount: '',
        }}
        validationSchema={Yup.object({
          boostAmount: Yup.string()
            .required('Required')
            .test('isEnoughBalance', 'Amount exceeds balance', (boostAmount) => {
              return usersBalance.gt(ethers.utils.parseEther(boostAmount || '0'));
            }),
        })}
        onSubmit={{}}>
        {(props) => {
          const { values, errors, setFieldValue, handleBlur, handleSubmit, isValid } = props;

          const _handleChangeTextField = (e) => {
            e.target.value = e.target.value === '.' ? '0.' : e.target.value;
            if (e.target.value.match(/^[0-9]*\.?[0-9]{0,18}$/)) {
              try {
                if (isSafeNumber) ethers.utils.parseEther(e.target.value).toNumber();
                setFieldValue('boostAmount', e.target.value.toString(), true);
                setBoostPrice(e.target.value);
              } catch (error) {
                setFieldValue(
                  'boostAmount',
                  e.target.value ? ethers.utils.formatEther(maxBoostPrice.toFixed()) : '',
                  true
                );
                setBoostPrice(
                  e.target.value ? ethers.utils.formatEther(maxBoostPrice.toFixed()) : ''
                );
              }
            }
          };

          const _handleBuy = () => {
            const max = isSafeNumber
              ? ethers.utils.formatEther(maxBoostPrice.toFixed())
              : maxBoostPrice.toString();
            setBoostPrice(max);
            setFieldValue('boostAmount', max, true);
          };

          const _onChangeSlider = (e) => {
            if (Number(e.target.value) < 0) {
              e.target.value = '';
              return;
            }
            e.target.value = isSafeNumber ? e.target.value : e.target.value.toString();

            if (isSafeNumber) {
              setBoostPrice(ethers.utils.formatEther(e.target.value.toFixed()));
              setFieldValue(
                'boostAmount',
                ethers.utils.formatEther(e.target.value.toFixed()),
                true
              );
            } else {
              setBoostPrice(e.target.value);
              setFieldValue('boostAmount', e.target.value, true);
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="center" gap="16px" sx={{ width: 'auto', mb: '17px' }}>
                <TextFieldStyled
                  variant="outlined"
                  id="boostAmount"
                  placeholder="Enter amount"
                  onChange={_handleChangeTextField}
                  autoComplete="off"
                  onBlur={handleBlur}
                  value={values.boostAmount}
                  error={Boolean(errors.boostAmount)}
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <span style={{ color: '#B9B9B9' }}>BIOMETA</span>
                      </InputAdornment>
                    ),
                    sx: {
                      color: boostPrice ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                      '&:hover': {
                        color: boostPrice ? '#fff' : 'rgba(255, 255, 255, 0.3)',
                      },
                      '&:focus-within': {
                        color: '#fff',
                      },
                      width: '100%',
                      height: '40px',
                    },
                  }}
                  sx={{
                    '& fieldset': {
                      borderRadius: '31px',
                      border: '1px solid rgba(255, 255, 255, 0.2)',
                    },
                    '& .MuiOutlinedInput-input': {
                      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                      },
                      '&:disabled': {
                        WebkitTextFillColor: '#4A4A4A',
                      },
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(185, 185, 185, 0.5)',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: !errors.boostAmount && 'rgba(185, 185, 185, 0.5)',
                    },
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                />
                <BuyButton disabled text="Max" buyButtonHandler={_handleBuy} />
              </Grid>
              <div
                style={{
                  color: '#FD2727',
                  fontSize: '12px',
                  textAlign: 'center',
                }}>
                {errors.boostAmount}
              </div>
              <Slider
                value={boostPriceValue}
                min={0}
                step={maxBoostPrice > 0 ? maxBoostPrice / 100 : 1}
                max={maxBoostPrice > 0 ? maxBoostPrice : 100}
                disabled
                onChange={_onChangeSlider}
                sx={{
                  width: '100%',
                  color: '#3F4043',
                  mb: '10px',
                  '@media screen and (max-width: 576px)': {
                    width: '90%',
                    margin: '0px 15px',
                  },
                  '&.MuiSlider-root.Mui-disabled': {
                    color: '#3F4043',
                  },
                  '& .MuiSlider-thumb': {
                    // eslint-disable-next-line no-nested-ternary
                    color: boostPrice
                      ? isSafeNumber
                        ? ethers.utils.parseEther(boostPrice).toNumber()
                        : Number(boostPrice)
                      : '#B9B9B9',
                  },
                  '& .MuiSlider-track': { color: '#B9B9B9' },
                }}
              />
            </form>
          );
        }}
      </Formik>
      <Grid
        ref={ref}
        container
        justifyContent="center"
        rowGap="5px"
        sx={{ width: 'auto', maxWidth: mobile ? '300px' : '450px' }}>
        {/* <Typography
          variant="rx12"
          sx={{
            justifyContent: isWrapped ? 'flex-start' : 'center',
            color: '#A39F9F',
            lineHeight: '15px',
            overflow: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}>
          {`1 Day = ${formatPrice(dayMadPrice, 18)} BIOMETA`}
          &nbsp;
        </Typography> */}
        <Typography
          variant="rx12"
          sx={{
            color: '#A39F9F',
            lineHeight: '15px',
          }}>
          {/* {`| ${formatPrice(dayUSDPrice, 2)} USD`} */}
        </Typography>
      </Grid>
      <Grid
        ref={ref}
        container
        justifyContent="center"
        rowGap="5px"
        sx={{ width: 'auto', maxWidth: mobile ? '320px' : '450px', marginTop: 2 }}>
        <Typography
          variant="rx12"
          sx={{
            justifyContent: isWrapped ? 'flex-start' : 'center',
            color: '#A39F9F',
            lineHeight: '15px',
            overflow: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}>
          {`YOUR BALANCE: ${formatPrice(ethers.utils.formatEther(usersBalance), 18)} BIOMETA`}
          &nbsp;
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BoostController;
